import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import Api from './Api'
import Company from './Company'
// @ts-ignore
import ModelWithFiles from './interface/ModelWithFiles'
import WebMessage from './WebMessage'

export default class Expense extends ModelWithFiles {
  protected api_settings = {
    save_mode: 'form',
    paths: {
      singular: 'expense' as string | null,
      plural: 'expenses' as string | null,
    },
  }

  public id: null | string = null

  public name: string = ''

  public client_invoice_number: string = ''

  public description: string = ''

  public category: string = 'media_cost_ssl'

  public commission_deduct: string = 'no'

  public commission_deduct_mode: string | null = null

  public commission_deduct_percentage: number = 0

  public client_id: null | string = null

  public client: null | Company = null

  public invoice_id: null | string = null

  public media_plan_ids: string[] = []

  public media_plan_item_ids: null | string = null

  public amount: number = 0

  public status: string = 'pending'

  public created_at: string = ''

  public updated_at: string = ''

  public deduct_from_commission_at: string = ''

  public conflict: boolean = false

  public get fromCommissionDeductMode(): string {
    if (!this.commission_deduct_mode) {
      return '-'
    }
    let ret = this.commission_deduct_mode.replaceAll('_', ' ')

    if (this.commission_deduct_mode.includes('percentage')) {
      ret += ` (${this.commission_deduct_percentage}%)`
    }

    return ret
  }

  public get fromCommissionDeduct(): string {
    let ret = this.commission_deduct.replaceAll('_', ' ')

    if (this.commission_deduct == 'specific_media_plans') {
      ret += ` (${this.media_plan_ids.length} Media Plans)`
    }

    return ret
  }

  public get formCreatedAt(): string {
    return this.created_at
  }

  public set formCreatedAt(value: string) {
    this.created_at = value
    if (!this.deduct_from_commission_at) {
      this.deduct_from_commission_at = value
    }
  }

  public static async createBatch(batch: Array<any>) {
    const api = new Api(false)
    return api
      .post('expenses/batch', { expenses: batch })
      .then(Expense.onSave)
      .catch(Expense.onError)
  }

  public static async overview() {
    const api = new Api(false)
    return api
      .get('expenses/overview')
      .then(response => response.data)
      .catch(Expense.onError)
  }

  public static async createJournal(batch: Array<any>, source: any) {
    const instance_id = getModule(SystemtModule)._uuid
    const api = new Api(false)
    return api
      .post('expenses/journal', { expenses: batch, instance_id, source })
      .then(Expense.onSave)
      .catch(Expense.onError)
  }

  protected static onSave(response: any) {
    return response
  }

  private static onError(error: any) {
    return error
  }
}
