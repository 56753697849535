
import { Prop, Component } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import MediaPlan from '@/models/MediaPlan'
import { LostCategories } from '@/models/interface/Common'
import FormInput from '@/components/FormInput/FormInput.vue'

@Component({
  components: {
    SelectPicker,
    FormInput,
  },
})
export default class CancelMediaPlan extends ViewModel {
  @Prop({ required: true })
  public media_plan!: MediaPlan

  @Prop({ required: true })
  public dataTable!: any

  private showModal = true

  public cancel_options = LostCategories

  public cancel_type: string = 'cancel'

  public close_reason: null = null

  public lost_category: null = null

  public confirm() {
    this.media_plan
      .cancel({ close_reason: this.close_reason, lost_category: this.lost_category })
      .then(() => {
        this.dataTable.refresh()
      })
  }
}
