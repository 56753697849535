
import Vue from 'vue'
import Pdf from 'pdfvuer'
import Api from '@/models/Api'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

@Component({
  components: {
    Pdf,
  },
})
export default class Preview extends Vue {
  @Prop()
  public file_path!: any

  @Prop()
  public to_scale!: any

  @Prop({ default: 10 })
  public cols!: number

  @Prop()
  public custom_path!: any

  public get scale() {
    return this.to_scale ? this.to_scale : 1
  }

  public set scale(data) {
    this.$emit('update:to_scale', data)
  }

  @Watch('to_scale')
  public onScaleChange(val) {
    // if (val) {
    //   setTimeout(() => {
    //     let x = 0
    //     while (x < this.pdfSettings.numPages) {
    //       let y = x + 1
    //       console.log('looping ref' + y, this.$refs[`pdf-page-${y}`][0])
    //       this.$refs[`pdf-page-${y}`][0].pdfViewer.update(val, 0)
    //       this.$refs[`pdf-page-${y}`][0].loading = true
    //       this.$refs[`pdf-page-${y}`][0].pdfViewer.draw().then(() => {
    //         this.$refs[`pdf-page-${y}`][0].loading = false
    //         console.log('looped here')
    //       })
    //       x++
    //     }
    //   }, 1000)
    // }
  }

  public file_content: any = null

  public pdfSettings = {
    page: 1,
    numPages: 0,
  }

  public mounted() {
    this.loadPdf()
  }

  public loadPdf() {
    let loadingTask: any = null
    if (this.custom_path) {
      loadingTask = new Api().getFileUrl(`model_file/download/${this.file_path}`)
    } else {
      loadingTask = new Api().getFileUrl(this.file_path)
    }
    this.file_content = Pdf.createLoadingTask(loadingTask)

    this.file_content.then((pdf: any) => {
      this.pdfSettings.numPages = pdf.numPages
    })
  }
}
