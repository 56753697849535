import ApiParameters from '@/models/interface/ApiParameters'
import moment from 'moment'
import { DateTime } from 'luxon'
import BigNumber from 'bignumber.js'
import LineItem from './LineItem'
import Model from './interface/Model'
import Company from './Company'
import MediaPlan from './MediaPlan'
import MediaPlanItem from './MediaPlanItem'
import Api from './Api'
import PaginateOptions from './interface/PaginateOptions'
import { DATE_TIME_FORMAT, toDateTime } from './interface/Common'
import WebMessage from './WebMessage'

export default class Order extends Model {
  public api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'order' as string | null,
      plural: 'orders' as string | null,
    },
  }

  public id: string | null = null

  public name: string = ''

  public billing_contract: number | null = null

  public advertiser_id: string | null = null

  public agency_id: string | null = null

  public sales_rep_id: string | null = null

  public media_plan_id: string | null = null

  public media_plan_item_id: string | null = null

  public station_id: string | null = null

  public contracted_amount: number = 0

  public mediaPlan: any = null

  public line_items: Array<LineItem> = []

  public trafficker_id: string | null = ''

  public secondary_trafficker_id: string = ''

  public labels: any = []

  public advertiser_contacts: any = []

  public secondary_sales_rep_id: any = []

  public notes: string = ''

  public archived: any = 0

  public model: any = 'cpm'

  public open_ended: boolean = false

  public adserver: string = 'cim'

  public admanager_id: string = '0'

  public buffer_rule: any = 'none'

  public buffer_percentage: number = 0

  public buffer_value: number = 0

  public line_item_count: number = 0

  public associated_creatives: any = []

  public remove_creatives: string[] = []

  public line_item_status_count: any = {
    delivering: 0,
    ready: 0,
    paused: 0,
    completed: 0,
    missing_creative: 0,
  }

  public metrics: any = {}

  public start_at: string = DateTime.now().startOf('day').toFormat(DATE_TIME_FORMAT)

  public end_at: string = DateTime.now().endOf('day').toFormat(DATE_TIME_FORMAT)

  public agency: Company = new Company()

  public advertiser: Company = new Company()

  public station: Company = new Company()

  public media_plan: MediaPlan = new MediaPlan()

  public media_plan_item: MediaPlanItem = new MediaPlanItem()

  public media_plan_conflicts: any = []

  public order_action: any = 'create' // 'existing

  public existing_orders: any = []

  public status = 'ready'

  public created_at: string = ''

  public updated_at: any = null

  public history_notes_count: number = 0

  public get status_name() {
    return this.archived ? 'Archived' : this.status.replaceAll('_', ' ')
  }

  public get status_color() {
    if (this.archived) {
      return 'secondary'
    }
    if (this.status == 'delivering') {
      return 'success'
    }
    if (this.status == 'pending_creative') {
      return 'danger'
    }
    if (this.status == 'paused') {
      return 'warning'
    }
    return 'info'
  }

  public get total() {
    return this.line_items.reduce((a, b) => a + (b.cost || 0), 0)
  }

  public get impressions(): number {
    return this.line_items.reduce((a, b) => a + (b.metrics.impressions || 0), 0)
  }

  public get booked(): number {
    if (this.contracted_amount) {
      return this.contracted_amount
    }
    return this.line_items.reduce((a, b) => a + b.booked, 0)
  }

  public get revenue(): number {
    return this.line_items.reduce((a, b) => a + (b.revenue || 0), 0)
  }

  public get daily_pacing() {
    if (!this.metrics.daily) return [0, 0, 0, 0, 0, 0, 0]
    if (this.metrics.daily === 0 || this.metrics.daily.length == 0) {
      return [0, 0, 0, 0, 0, 0, 0]
    }

    return this.metrics.daily
  }

  public get pacing() {
    return this.line_items.reduce(
      (a, b) => ({
        current: a.current + b.metrics.pacing.current,
        required: a.required + b.metrics.pacing.required,
      }),
      { current: 0, required: 0 },
    )
  }

  public get performance() {
    if (this.metrics.daily === 0) {
      return [
        {
          name: 'Impressions',
          data: [0, 0, 0, 0, 0, 0, 0],
        },
      ]
    }

    return [
      {
        name: 'Impressions',
        data:
          this.metrics?.daily?.map((impressions: number, key: number) => ({
            x: '2011',
            y: impressions,
            goals: [
              {
                name: 'Required Pacing',
                value: this.metrics.pacing.required,
                strokeHeight: 2,
                strokeDashArray: 2,
                strokeColor: '#775DD0',
              },
            ],
          })) || [],
      },
    ]
  }

  public toObject(source: any) {
    let instance = this.clone()

    instance.metrics = {
      ...instance.metrics,
      ...source.metrics,
    }
    if (source.line_items) {
      instance.line_items = LineItem.toObjectList(source.line_items)
    }
    if (source.agency) {
      instance.agency = Company.toObject(source.agency)
    }
    if (source.advertiser) {
      instance.advertiser = Company.toObject(source.advertiser)
    }
    if (source.station) {
      instance.station = Company.toObject(source.station)
    }

    if (source.media_plan) {
      instance.media_plan = MediaPlan.toObject(source.media_plan)
    }

    if (source.media_plan_item) {
      instance.media_plan_item = MediaPlanItem.toObject(source.media_plan_item)
    }

    if (source.start_at) {
      instance.start_at = toDateTime(source.start_at).toFormat(DATE_TIME_FORMAT)
    } else if (source.id) {
      instance.start_at = ''
    }

    if (source.end_at) {
      instance.end_at = toDateTime(source.end_at).toFormat(DATE_TIME_FORMAT)
    } else if (source.id) {
      instance.end_at = ''
    }

    if (source.hasOwnProperty('history_notes_count')) {
      instance.history_notes_count = source.history_notes_count
    }

    if (source.buffer_percentage > 0) {
      if (source.buffer_percentage <= 1) {
        instance.buffer_percentage = new BigNumber(source.buffer_percentage).times(100).toNumber()
        delete source.buffer_percentage
      }
    }

    // Remove dynamic data
    if (source.metrics !== undefined) delete source.metrics
    if (source.line_items !== undefined) delete source.line_items
    if (source.agency !== undefined) delete source.agency
    if (source.advertiser !== undefined) delete source.advertiser
    if (source.station !== undefined) delete source.station
    if (source.media_plan !== undefined) delete source.media_plan
    if (source.media_plan_item !== undefined) delete source.media_plan_item
    if (source.start_at !== undefined) delete source.start_at
    if (source.end_at !== undefined) delete source.end_at

    Object.assign(instance, source)

    return instance
  }

  public get apiData() {
    return {
      id: this.id,
      name: this.name,
      billing_contract: this.billing_contract,
      advertiser_id: this.advertiser_id,
      agency_id: this.agency_id,
      station_id: this.station_id,
      contracted_amount: this.contracted_amount,
      trafficker_id: this.trafficker_id,
      secondary_trafficker_id: this.secondary_trafficker_id,
      labels: this.labels,
      advertiser_contacts: this.advertiser_contacts,
      secondary_sales_rep_id: this.secondary_sales_rep_id,
      sales_rep_id: this.sales_rep_id,
      notes: this.notes,
      archived: this.archived,
      media_plan_id: this.media_plan_id,
      media_plan_item_id: this.media_plan_item_id,
      model: this.model,
      open_ended: this.open_ended,
      buffer_rule: this.buffer_rule,
      buffer_percentage: this.buffer_rule === 'percentage' ? this.buffer_percentage / 100 : 0,
      buffer_value: this.buffer_rule === 'fixed' ? this.buffer_value : 0,
      metrics: this.metrics,
      associated_creatives: this.associated_creatives,
      remove_creatives: this.remove_creatives,
      status: this.status,
      start_at: this.start_at,
      end_at: this.end_at,
      updated_at: this.updated_at,
    }
  }

  public async archiveOrder(val: boolean = true) {
    this.archived = val
    return this.save().then(res => res)
  }

  public getHistory(options: PaginateOptions) {
    const api = new Api()
    return api.get(`orders/${this.id}/history`, options)
  }

  public static checkOrderItems(ids: any) {
    const api = new Api()
    return api.get('check_order_items', { ...ids }).then(res => res.data.result)
  }

  public play() {
    this.status = 'ready'
    return this.save()
  }

  public pause() {
    this.status = 'paused'
    return this.save()
  }

  public batchUpdate(ids: string[], selected_inputs: string[]) {
    const api = new Api()

    let props = selected_inputs.map(s => s.replaceAll('order.', ''))

    let payload: any = {}

    let temp: any = this.apiData

    props.forEach((key: string) => {
      if (key === 'buffer_rule' && this.buffer_rule === 'percentage') {
        payload.buffer_percentage = this.buffer_percentage
      }
      payload[key] = temp[key]
    })

    return api.post('orders/batch-update', { ids, payload }).then(res => {
      WebMessage.success('Updated batch')
      return res.data.result
    })
  }
}
