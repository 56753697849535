import moment from 'moment'
import Company from './Company'
import Reconciliation from './Reconciliation'
import WebMessage from './WebMessage'

export default class ReconciliationClient {
  public agency_id: null | string = null

  public agency: null | Company = null

  public advertiser_id: null | string = null

  public advertiser: null | Company = null

  public client_id: string = ''

  public client: null | Company = null

  public items: Array<Reconciliation> = []

  public group_by: string = 'media_plan_item'

  public invoice_group: string = 'media_plan'

  public group_by_name: string = 'SportStreamLive'

  public type: string = 'SSL'

  public count: number = 0

  public due_at: null | string = moment().endOf('month').format('YYYY-MM-DD')

  public _showDetails: boolean = true

  public _showItems = false

  public invoice_count = {
    single: 1,
    advertiser: 1,
    media_plan: 1,
  }

  public _selected: boolean = false

  public get uuid(): string {
    return `${this.agency_id}-${this.advertiser_id}-${this.client_id}`
  }

  public get net_rate(): number {
    return this.revenue_net_total / (this.impressions / 1000)
  }

  public get gross_total() {
    return this.gross_rate * (this.impressions / 1000)
  }

  public get gross_rate(): number {
    return this.revenue_total / (this.impressions / 1000)
  }

  public get media_plans_net_total(): number {
    // @ts-ignore
    return this.items.reduce((acc, item) => acc + item.media_plan_item?.net_cost, 0)
  }

  public get media_plans_gross_total(): number {
    // @ts-ignore
    return this.items.reduce((acc, item) => acc + item.media_plan_item?.gross_cost, 0)
  }

  public get impressions(): number {
    return this.items.reduce((acc, item) => acc + item.impressions, 0)
  }

  public get revenue_total(): number {
    return this.items.reduce((acc, item) => acc + item.revenue_total, 0)
  }

  public get revenue_net_total(): number {
    return this.items.reduce((acc, item) => acc + item.revenue_net_total, 0)
  }

  public toggleSubItems(state = null) {
    // @ts-ignore
    this._showItems = state !== null ? state : !this._showItems
  }

  public static toObject(source: any): ReconciliationClient {
    let instance = new ReconciliationClient()
    Object.assign(instance, source)

    if (source.items) {
      instance.items = Reconciliation.toObjectList(source.items)
    }

    return instance
  }

  public toObject(source: any): ReconciliationClient {
    return ReconciliationClient.toObject(source)
  }

  public static toObjectList(source: any): ReconciliationClient[] {
    return source.map((item: any) => ReconciliationClient.toObject(item))
  }

  public toObjectList(source: any): ReconciliationClient[] {
    return ReconciliationClient.toObjectList(source)
  }

  protected static onSave(response: any) {
    return response
  }

  private static onError(error: any) {
    WebMessage.error('Try again')
    return error
  }
}
