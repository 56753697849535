
import {
  Component, Prop, Ref, Watch,
} from 'vue-property-decorator'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import ViewModel from '@/models/ViewModel'
import SelectOption from '@/models/interface/SelectOption'
import MediaPlan from '@/models/MediaPlan'
import IconAction from '@/components/IconAction/IconAction.vue'
import { MediaPlanTypes } from '@/models/interface/Common'
import moment from 'moment'
import { startCase } from 'lodash'
import CompanyPicker from '../CompanyPicker/CompanyPicker.vue'
import DatePicker from '../DatePicker/DatePicker.vue'
import DataTable from '../DataTable/index.vue'

@Component({
  components: {
    SelectPicker,
    IconAction,
    CompanyPicker,
    DatePicker,
    DataTable,
  },
})
export default class InsertionOrderPicker extends ViewModel {
  @Ref() public validator!: any

  @Prop({ default: true })
  public edit!: boolean

  @Prop({ default: false })
  public show_number!: boolean

  @Prop({ default: false })
  public allow_empty!: boolean

  @Prop({ default: false })
  public disabled!: boolean

  @Prop({ default: false })
  public multiple!: boolean

  @Prop({ default: '' })
  public agency_id!: string

  @Prop({ default: '' })
  public station_id!: string

  @Prop({ default: '' })
  public advertiser_id!: string

  @Prop({ default: false })
  public create_panel!: boolean

  @Prop({ default: 'Order' })
  public label!: string

  @Prop({ default: () => null })
  public value!: string | null

  @Prop()
  public isOpen!: boolean

  @Watch('local_value')
  public onChangeValue(val: any) {
    this.update = false
    if (val == null) {
      this.$emit('input', null)
    } else {
      this.$emit('input', this.local_value)
    }
  }

  @Watch('value')
  public onChangeProp(val: any) {
    if (this.update) {
      if (val == null) {
        this.local_value = null
      } else {
        let has_options = false
        if (Array.isArray(this.value)) {
          has_options = true
          this.value.forEach((v: any) => {
            if (!this.options.some((o: any) => o.value === v)) {
              has_options = false
            }
          })
        }
        if (
          (Array.isArray(this.value) && this.value.length > 0 && !has_options)
          || typeof this.value === 'string'
        ) {
          MediaPlan.searchOptions({
            value: Array.isArray(this.value) ? this.value : [this.value],
          }).then(response => {
            this.options = response
            this.busy = false
            this.local_value = this.value
            this.ready = true
          })
        } else {
          this.local_value = this.value
        }
      }
    }
    this.update = true
    this.updateLabel()
  }

  @Watch('company_id')
  public onChangeCompany() {
    this.searchOptions()
  }

  @Watch('station_id')
  public onChangeStation() {
    this.searchOptions()
  }

  @Watch('all_selected')
  public allSelectedOnChange(val: any) {
    if (val) {
      this.selected_options = this.searched_media_plans.map((item: any) => item.id)
    } else {
      this.selected_options = []
    }
  }

  public local_value: string[] | string | null = []

  public new_id: string = ''

  public show_sidebar: boolean = false

  public filters: boolean = false

  public update: boolean = true

  public uuid: string = ''

  public busy: boolean = false

  public options: SelectOption[] = []

  public label_name: string = 'N/A'

  public ready: boolean = false

  public filter_options: any = {
    type: 'all',
  }

  public is_searching: boolean = false

  public all_selected: boolean = false

  public searched_media_plans: any = []

  public selected_options: any = []

  public media_plan_types: any = [
    ...MediaPlanTypes,
    {
      name: 'All',
      value: 'all',
    },
  ]

  public searched_items_table: any = [
    {
      key: 'checkbox',
      label: '',
      sortable: false,
      class: 'text-center align-middle',
      thClass: 'align-middle text-center',
      show: true,
      type: 'checkbox',
    },
    {
      key: 'id',
      label: 'ID',
      sortable: true,
      class: 'text-center align-middle',
      show: false,
      thClass: 'align-middle text-center',
    },
    {
      key: 'number',
      label: '#',
      sortable: true,
      class: 'text-center align-middle',
      show: true,
      thClass: 'align-middle text-center',
    },
    {
      key: 'name',
      label: 'name',
      sortable: true,
      class: 'text-center align-middle',
      show: true,
      thClass: 'align-middle text-center',
    },
    {
      key: 'agency_id',
      label: 'Agency',
      sortable: true,
      class: 'text-center align-middle',
      show: true,
      thClass: 'align-middle text-center',
    },
    {
      key: 'advertiser_id',
      label: 'Advertiser',
      sortable: true,
      class: 'text-center align-middle',
      show: true,
      thClass: 'align-middle text-center',
    },
    {
      key: 'type',
      label: 'Type',
      sortable: true,
      show: false,
      type: 'capitalize',
      tdClass: 'align-middle text-center',
      formatter: (value: string | null) => (value ? startCase(value.replaceAll('_', ' ')) : 'N/A'),
    },
    {
      key: 'start_at',
      label: 'Flight Dates',
      sortable: true,
      class: 'text-center align-middle',
      show: true,
      thClass: 'align-middle text-center',
      thStyle: { width: '20rem' },
    },
  ]

  public get indeterminate(): boolean {
    return (
      this.selected_options.length > 0
      && this.selected_options.length < this.searched_media_plans.length
    )
  }

  public get show_fields() {
    return this.searched_items_table.filter(i => i.show)
  }

  public mounted() {
    this.uuid = this.randomUUID()
  }

  public created() {
    if (this.edit) {
      if (!this.value || (Array.isArray(this.value) && this.value.length == 0)) {
        this.ready = true
      } else {
        this.busy = true
        MediaPlan.searchOptions({
          value: this.value,
        }).then(response => {
          this.options = response
          this.busy = false
          this.local_value = this.value
          this.ready = true
        })
      }
    } else {
      this.updateLabel()
    }
  }

  public open() {
    if (this.options.length == 0) this.searchOptions()
  }

  public updateLabel() {
    if (!this.value) {
      this.label_name = 'N/A'
    } else if (!Array.isArray(this.value)) {
      MediaPlan.searchOptions({
        value: this.value,
      }).then(response => {
        this.label_name = response[0].name
        this.ready = true
      })
    }
  }

  public searchOptionsByFilters() {
    this.is_searching = true
    // this.searchOptions()
    MediaPlan.filterOptions({ ...this.filter_options, model: true })
      .then(response => {
        this.searched_media_plans = response.result.media_plans
        setTimeout(() => {
          this.is_searching = false
        }, 500)
      })
      .catch(error => {
        this.is_searching = false
      })
  }

  public searchOptions(search = '*') {
    this.busy = true
    if (!search) search = '*'

    MediaPlan.searchOptions({
      search: search.includes('*') ? search : `*${search}*`,
      owner_company_id: this.agency_id,
      advertiser_id: this.advertiser_id,
      agency_id: this.agency_id,
    }).then(response => {
      this.options = response
      this.busy = false
      this.ready = true
    })
  }

  public closeFilters() {
    this.selected_options = []
    this.filters = false
    this.is_searching = false
    this.$emit('update:is-open', false)
  }

  public openFilters() {
    this.$emit('update:is-open', true)
    this.filters = true
  }

  public selectedToOpiotns() {
    let options = this.searched_media_plans.filter((media_plan: any) =>
      this.selected_options.includes(media_plan.id))
    this.options = options.map(op => ({
      name: op.name,
      full_name: op.name,
      value: op.id,
    }))
    let res = [...new Set([...(this.value ?? ''), ...this.selected_options])]
    this.$emit('input', res)

    this.filters = false
  }
}
