export default [
  {
    key: 'number',
    label: '#',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'sales_rep',
    label: 'Sales Rep',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'client',
    label: 'Client',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },

  {
    key: 'name',
    label: 'Media Plan Name',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'status',
    label: 'Media Plan Status',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'billing_periods',
    label: 'Billing Periods',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'invoices',
    label: 'Invoices',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'invoices_status',
    label: 'Invoice Status',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'invoices_numbers',
    label: 'Invoice Numbers',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'paid_invoices',
    label: 'Paid Invoices',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'gross_total',
    label: 'Total Gross',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'net_total',
    label: 'Total Net',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },

  {
    key: 'invoice_total',
    label: 'Total Invoiced',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'total_paid',
    label: 'Total Paid',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
]
