/** @format */

import DatePickerDate from './DatePickerDate'

export default class Filter {
  public date: DatePickerDate = new DatePickerDate(null, null, 'month')

  public orders: string[] = []

  public devices: string[] = []

  public agencies: string[] = []

  public clients: string[] = []

  public stations: string[] = []

  public line_items: string[] = []

  public publishers: string[] = []

  public inventory: string[] = []

  public advertisers: string[] = []

  public billing_contracts: string[] = []

  public account_executives: string[] = []

  public advertiser_name: string[] = [] // Media Ocean Property

  public agency_advertiser_code: string[] = [] // Media Ocean Property

  public product_name: string[] = [] // Media Ocean Property

  public agency_product_code: string[] = [] // Media Ocean Property

  public agency_estimate_code: string[] = [] // Media Ocean Property

  public isci_code: string[] = [] // Media Ocean Property

  public sales_reps: string[] = []

  public status: string[] = []

  public view: string | null = null

  public target: string | null = null

  public order_type: string = 'All'

  public map_mode: string = 'dma'

  public dmas: string[] = []

  public states: string[] = []

  public adserver_orders: string[] = []

  public adserver_line_items: string[] = []

  public constructor(data: any = null) {
    if (data) {
      if (data.orders) this.orders = data.orders
      if (data.devices) this.devices = data.devices
      if (data.agencies) this.agencies = data.agencies
      if (data.stations) this.stations = data.stations
      if (data.line_items) this.line_items = data.line_items
      if (data.publishers) this.publishers = data.publishers
      if (data.inventory) this.inventory = data.inventory
      if (data.advertisers) this.advertisers = data.advertisers
      if (data.billing_contracts) this.billing_contracts = data.billing_contracts
      if (data.account_executives) this.account_executives = data.account_executives
      if (data.advertiser_name) this.advertiser_name = data.advertiser_name
      if (data.agency_advertiser_code) this.agency_advertiser_code = data.agency_advertiser_code
      if (data.product_name) this.product_name = data.product_name
      if (data.agency_product_code) this.agency_product_code = data.agency_product_code
      if (data.agency_estimate_code) this.agency_estimate_code = data.agency_estimate_code
      if (data.isci_code) this.isci_code = data.isci_code
      if (data.sales_reps) this.sales_reps = data.sales_reps
      if (data.order_type) this.order_type = data.order_type
      if (data.status) this.status = data.status
      if (data.target) this.target = data.target
      if (data.view) this.view = data.view
      if (data.date) this.date = DatePickerDate.toObject(data.date)
      if (data.clients) this.clients = data.clients
      if (data.dmas) this.dmas = data.dmas
      if (data.states) this.states = data.states
      if (data.adserver_orders) this.adserver_orders = data.adserver_orders
      if (data.adserver_line_items) this.adserver_line_items = data.adserver_line_items
    }
  }

  public static toObject(data: any): Filter {
    return new Filter(data)
  }

  public reset() {
    this.date = new DatePickerDate(null, null, 'month')
    this.orders = []
    this.devices = []
    this.agencies = []
    this.clients = []
    this.stations = []
    this.line_items = []
    this.publishers = []
    this.inventory = []
    this.advertisers = []
    this.billing_contracts = []
    this.account_executives = []
    this.advertiser_name = []
    this.agency_advertiser_code = []
    this.product_name = []
    this.agency_product_code = []
    this.agency_estimate_code = []
    this.status = ['confirmed', 'pending_documents']
    this.isci_code = []
    this.sales_reps = []
    this.order_type = 'All'
    this.map_mode = 'dma'
    this.dmas = []
    this.states = []
    this.adserver_line_items = []
    this.adserver_orders = []
  }
}
