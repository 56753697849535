import Filter from './Filter'
import DatePickerDate from './DatePickerDate'

export default class ReportQuerySettings {
  public date: DatePickerDate = new DatePickerDate(null, null, 'month')

  public excel: boolean = true

  public filters: Filter = new Filter()

  public date_filter: string = 'none'

  public clients: string[] = []

  public metrics: string[] = ['impressions', 'completed', 'completion_rate']

  public insights: boolean = false

  public show_items: boolean = false

  public dimensions: string[] = ['date']

  public static toObject(data: any): ReportQuerySettings {
    let settings = new ReportQuerySettings()

    settings.excel = data.excel
    settings.metrics = data.metrics
    settings.insights = data.insights
    settings.show_items = data.show_items
    settings.dimensions = data.dimensions
    settings.clients = data.clients
    settings.date = DatePickerDate.toObject(data.date)
    settings.filters = Filter.toObject(data.filters)

    return settings
  }
}
