import Model from './interface/Model'

export default class DynamicRate extends Model {
  protected api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'dynamic_rate' as string | null,
      plural: 'dynamic_rates' as string | null,
    },
  }

  public id: string = ''

  public name: string = ''

  public type: string = 'revenue' // cost or revenue

  public rate: number = 0

  public status: string = 'active'

  public allow_overwrite: boolean = false

  public apply_to_children: boolean = false

  public adunit_ids: string[] = []

  public exclude_adunit_ids: string[] = []

  public overwrites: any = [] // Inventory_ID => {rate: number}

  public created_at: string = ''

  public updated_at: string = ''
}
