
import { Prop, Component } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import MediaPlan from '@/models/MediaPlan'
import router from '@/Routes'

@Component({
  components: {},
})
export default class ConfirmMediaPlan extends ViewModel {
  @Prop({ required: true })
  public media_plan!: MediaPlan

  private showModal = true

  public order_cash: any = null

  public order_trade: any = null

  public cancel_type: string = 'cancel'

  public confirm() {
    let ref = window.location.href.includes('legacy-dashboard') ? '&ref=LegacyDashboard' : ''

    if (!this.order_cash && !this.order_trade) {
      router.push(`/app/sales/media_plan/${this.media_plan.id}?confirm${ref}`)
    } else {
      this.media_plan.model_files_binary = []
      this.media_plan.model_files_meta = []

      if (this.order_cash) {
        this.media_plan.model_files_binary.push(this.order_cash)

        this.media_plan.model_files_meta.push({
          name: 'Cash Order IO',
          source_name: this.order_cash.name,
          description: 'Cash Order IO',
          size: this.order_cash.size,
          type: this.order_cash.type,
        })
      }

      if (this.order_trade) {
        this.media_plan.model_files_binary.push(this.order_trade)

        this.media_plan.model_files_meta.push({
          name: 'Trade Order IO',
          source_name: this.order_trade.name,
          description: 'Trade Order IO',
          size: this.order_trade.size,
          type: this.order_trade.type,
        })
      }

      this.media_plan.preUploadFiles().then((response: any) => {
        let order_files = this.media_plan.model_files_meta
          .map((file: any) => {
            let ret = ''
            let type = file.name.includes('Cash') ? 'cash' : 'trade'
            for (let key in file) {
              ret += `&${type}_${key}=${encodeURIComponent(file[key])}`
            }

            if (response.data.result.files[file.source_name]) {
              ret += `&${type}_tmp_source=${encodeURIComponent(response.data.result.files[file.source_name])}`
            }

            return ret
          })
          .join('')

        router.push(`/app/sales/media_plan/${this.media_plan.id}?confirm${ref}${order_files}`)
      })
    }
  }
}
