/* eslint-disable no-continue */
import targetOps from '@/pages/ManagementDashboard/views/SalesActivities/widgets/target-ops'
import BigNumber from 'bignumber.js'
import { clone } from 'lodash'

export default class RateCard {
  DEFAULTS = {
    VCR: {
      RATE: new BigNumber(0.30),
      MIN_PERCENTAGE: new BigNumber(0.8),
      MAX_PERCENTAGE: new BigNumber(0.95),
    },
    CTV: {
      RATE: new BigNumber(0.10),
      MIN_PERCENTAGE: new BigNumber(0.45),
    },
    LIVE: {
      RATE: new BigNumber(0.10),
      MIN_RATE: new BigNumber(35),
    },
    MIN_RATE: new BigNumber(25),
  }

  public calculateRate(
    vcr: BigNumber | number,
    ctv: BigNumber | number,
    live: BigNumber | number,
    audience: boolean = false,
    foot_traffick: boolean = false,
  ): BigNumber {
    if (!(vcr instanceof BigNumber)) vcr = new BigNumber(vcr)
    if (!(ctv instanceof BigNumber)) ctv = new BigNumber(ctv)
    if (!(live instanceof BigNumber)) live = new BigNumber(live)

    let rate = new BigNumber(45)

    if (audience) {
      rate = rate.plus(1)
    }

    if (foot_traffick) {
      rate = rate.plus(1)
    }

    // VCR guarantee can be reduced 60 cents for every point reduced from 95% until 80%
    // CTV guarantee can be reduced 15 cents for every point reduction from 100% until 40%
    // Live guarantee can reduce rate 20 cents for every point reduced from 100% until 0%
    // 100% Live cannot go below $35. Reject any asks that do.

    // Process VCR Discount
    let pointer = new BigNumber(0.95)
    while (
      pointer.isGreaterThan(vcr)
      && pointer.isGreaterThanOrEqualTo(this.DEFAULTS.VCR.MIN_PERCENTAGE)
    ) {
      rate = rate.minus(this.DEFAULTS.VCR.RATE)
      pointer = pointer.minus(0.01)
    }

    // Process CTV Discount
    pointer = new BigNumber(1)
    while (
      pointer.isGreaterThan(ctv)
      && pointer.isGreaterThanOrEqualTo(this.DEFAULTS.CTV.MIN_PERCENTAGE)
    ) {
      rate = rate.minus(this.DEFAULTS.CTV.RATE)
      pointer = pointer.minus(0.01)
    }

    // Process Live Discount
    pointer = new BigNumber(1)
    while (pointer.isGreaterThan(live)) {
      rate = rate.minus(this.DEFAULTS.LIVE.RATE)
      pointer = pointer.minus(0.01)
    }

    // 100% live can not go bellow $35
    if (live.isEqualTo(1) && rate.isLessThan(this.DEFAULTS.LIVE.MIN_RATE)) {
      return this.DEFAULTS.LIVE.MIN_RATE
    }

    // Check min rate
    if (rate.isLessThan(this.DEFAULTS.MIN_RATE)) {
      return this.DEFAULTS.MIN_RATE
    }

    return rate
  }

  public minimizeDiff(
    target: number,
    vcr: BigNumber | number,
    ctv: BigNumber | number,
    live: BigNumber | number,
    audience: boolean = false,
    foot_traffick: boolean = false,
  ): any {
    if (!(vcr instanceof BigNumber)) vcr = new BigNumber(vcr)
    if (!(ctv instanceof BigNumber)) ctv = new BigNumber(ctv)
    if (!(live instanceof BigNumber)) live = new BigNumber(live)

    let rate = this.calculateRate(vcr, ctv, live, audience, foot_traffick)
    let diff = new BigNumber(target).minus(rate)

    if (diff.isGreaterThan(0)) {
      if (
        diff.modulo(this.DEFAULTS.VCR.RATE).isEqualTo(0)
        && vcr.isLessThan(this.DEFAULTS.VCR.MAX_PERCENTAGE)
      ) {
        let times = diff.dividedBy(this.DEFAULTS.VCR.RATE)
        vcr = vcr.plus(times.multipliedBy(0.01))
        diff = diff.minus(times.multipliedBy(this.DEFAULTS.VCR.RATE))
      } else if (diff.modulo(this.DEFAULTS.LIVE.RATE).isEqualTo(0) && live.isLessThan(1)) {
        let times = diff.dividedBy(this.DEFAULTS.LIVE.RATE)
        live = live.plus(times.multipliedBy(0.01))
        diff = diff.minus(times.multipliedBy(this.DEFAULTS.LIVE.RATE))
      } else if (diff.modulo(this.DEFAULTS.CTV.RATE).isEqualTo(0) && ctv.isLessThan(1)) {
        let times = diff.dividedBy(this.DEFAULTS.CTV.RATE)
        ctv = ctv.plus(times.multipliedBy(0.01))
        diff = diff.minus(times.multipliedBy(this.DEFAULTS.CTV.RATE))
      }

      while (diff.isGreaterThanOrEqualTo(this.DEFAULTS.CTV.RATE)) {
        if (
          diff.isGreaterThanOrEqualTo(this.DEFAULTS.VCR.RATE)
          && vcr.isLessThan(this.DEFAULTS.VCR.MAX_PERCENTAGE)
        ) {
          vcr = vcr.plus(0.01)
          diff = diff.minus(this.DEFAULTS.VCR.RATE)
          continue
        }

        if (diff.isGreaterThanOrEqualTo(this.DEFAULTS.LIVE.RATE) && live.isLessThan(1)) {
          live = live.plus(0.01)
          diff = diff.minus(this.DEFAULTS.LIVE.RATE)
          continue
        }

        if (diff.isGreaterThanOrEqualTo(this.DEFAULTS.CTV.RATE) && ctv.isLessThan(1)) {
          ctv = ctv.plus(0.01)
          diff = diff.minus(this.DEFAULTS.CTV.RATE)
          continue
        }

        break
      }

      // if (
      //   diff.isEqualTo(0.1)
      //   && ctv.isLessThanOrEqualTo(0.98)
      //   && live.isGreaterThanOrEqualTo(0.01)
      // ) {
      //   live = live.minus(0.01)
      //   diff = diff.plus(this.DEFAULTS.LIVE.RATE)
      //   ctv = ctv.plus(0.02)
      //   diff = diff.minus(this.DEFAULTS.CTV.RATE.times(2))
      // } else if (
      //   diff.isEqualTo(0.05)
      //   && ctv.isGreaterThanOrEqualTo(this.DEFAULTS.CTV.MIN_PERCENTAGE.plus(0.01))
      //   && live.isLessThanOrEqualTo(0.99)
      // ) {
      //   ctv = ctv.minus(0.01)
      //   diff = diff.plus(this.DEFAULTS.CTV.RATE)
      //   live = live.plus(0.01)
      //   diff = diff.minus(this.DEFAULTS.LIVE.RATE)
      // }
    }
    return { vcr, ctv, live }
  }

  public calculateBestCombination(
    target: number,
    priority: string = 'none',
    audience: boolean = false,
    foot_traffick: boolean = false,
  ): any {
    if (target < this.DEFAULTS.MIN_RATE.toNumber()) {
      target = this.DEFAULTS.MIN_RATE.toNumber()
    }

    let vcr = new BigNumber(0.95)
    let ctv = new BigNumber(1)
    let live = new BigNumber(1)

    let rate = this.calculateRate(vcr, ctv, live, audience, foot_traffick)

    let diff = new BigNumber(rate).minus(target)
    while (diff.isGreaterThan(0)) {
      let changed = false

      if (priority != 'ctv') {
        if (ctv.isGreaterThan(this.DEFAULTS.CTV.MIN_PERCENTAGE)) {
          ctv = ctv.minus(0.01)
          diff = diff.minus(this.DEFAULTS.CTV.RATE)
          changed = true
        }

        if (diff.isLessThanOrEqualTo(0)) {
          break
        }
      }

      if (priority != 'live') {
        if (live.isGreaterThan(0)) {
          live = live.minus(0.01)
          diff = diff.minus(this.DEFAULTS.LIVE.RATE)
          changed = true
        }

        if (diff.isLessThanOrEqualTo(0)) {
          break
        }
      }

      if (priority != 'vcr') {
        if (vcr.isGreaterThan(this.DEFAULTS.VCR.MIN_PERCENTAGE)) {
          vcr = vcr.minus(0.01)
          diff = diff.minus(this.DEFAULTS.VCR.RATE)
          changed = true
        }

        if (diff.isLessThanOrEqualTo(0)) {
          break
        }
      }

      if (!changed) {
        break
      }
    }

    if (priority === 'ctv') {
      while (diff.isGreaterThan(0) && ctv.isGreaterThan(this.DEFAULTS.CTV.MIN_PERCENTAGE)) {
        ctv = ctv.minus(0.01)
        diff = diff.minus(this.DEFAULTS.CTV.RATE)
      }
    } else if (priority === 'live') {
      while (diff.isGreaterThan(0) && live.isGreaterThan(0)) {
        live = live.minus(0.01)
        diff = diff.minus(this.DEFAULTS.LIVE.RATE)
      }
    } else if (priority === 'vcr') {
      while (diff.isGreaterThan(0) && vcr.isGreaterThan(this.DEFAULTS.VCR.MIN_PERCENTAGE)) {
        vcr = vcr.minus(0.01)
        diff = diff.minus(0.6)
      }
    } else {
      while (diff.isGreaterThan(0) && live.isGreaterThan(0)) {
        live = live.minus(0.01)
        diff = diff.minus(this.DEFAULTS.LIVE.RATE)
      }
    }

    rate = this.calculateRate(vcr, ctv, live, audience, foot_traffick)

    diff = new BigNumber(target).minus(rate)

    if (diff.isGreaterThan(0)) {
      const ret = this.minimizeDiff(target, vcr, ctv, live, audience, foot_traffick)

      vcr = ret.vcr
      ctv = ret.ctv
      live = ret.live

      rate = this.calculateRate(vcr, ctv, live, audience, foot_traffick)
    }

    let effective = rate

    if (target > rate.toNumber()) {
      rate = new BigNumber(target)
    }

    return {
      rate: rate.toNumber(),
      effective: effective.toNumber(),
      vcr: vcr.toNumber(),
      ctv: ctv.toNumber(),
      live: live.toNumber(),
      score: vcr.plus(ctv).plus(live).toNumber(),
    }
  }

  public getRates(
    target: number,
    audience: boolean = false,
    foot_traffick: boolean = false,
    custom: any = {
      vcr: 95,
      ctv: 100,
      live: 100,
    },
  ) {
    custom = clone(custom)
    if (custom.vcr > 1) {
      custom.vcr /= 100
    }
    if (custom.ctv > 1) {
      custom.ctv /= 100
    }
    if (custom.live > 1) {
      custom.live /= 100
    }

    return {
      vcr: this.calculateBestCombination(target, 'vcr', audience, foot_traffick),
      ctv: this.calculateBestCombination(target, 'ctv', audience, foot_traffick),
      live: this.calculateBestCombination(target, 'live', audience, foot_traffick),
      balanced: this.calculateBestCombination(target, 'none', audience, foot_traffick),
      custom: {
        rate: this.calculateRate(
          custom.vcr,
          custom.ctv,
          custom.live,
          audience,
          foot_traffick,
        ).toNumber(),
        vcr: custom.vcr,
        ctv: custom.ctv,
        live: custom.live,
      },
    }
  }
}
