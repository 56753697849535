/** @format */

import store from '@/store'
import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators'
import SelectOption from '@/models/interface/SelectOption'
import Api from '@/models/Api'
import Terms from '@/models/Terms'
import PaginateOptions from '@/models/interface/PaginateOptions'

@Module({
  dynamic: true, store, namespaced: true, name: 'terms',
})
export default class TermsModule extends VuexModule {
  public data: Terms[] = []

  /**
   * @deprecated No longer needed after Elasticsearch implementation. Use searchOptions instead.
   */
  public options: SelectOption[] = []

  public synchronized: boolean = false

  public get api() {
    return new Api(false)
  }

  @Mutation
  public setOptions(options: SelectOption[]) {
    this.options = options
  }

  @Mutation
  public setSynchronized(status: boolean) {
    this.synchronized = status
  }

  @Mutation
  public update(terms: Terms | Terms[]) {
    // Set input to array
    let data: Array<Terms> = []
    if (terms instanceof Terms) {
      data.push(terms)
    } else {
      data = terms
    }

    let result = this.data

    // Update Module Data
    data.forEach((term: Terms) => {
      let found: boolean = false
      result = result.map((obj: Terms) => {
        if (obj.id == term.id) {
          found = true
          return term
        }
        return obj
      })

      if (!found) {
        result.push(term)
      }
    })

    this.data = result

    // Update Module Options
    let { options } = this
    data.forEach((term: Terms) => {
      let found: boolean = false
      options = options.map((o: SelectOption) => {
        if (o.value == term.id) {
          found = true
          return term.toOption()
        }
        return o
      })

      if (!found) {
        options.push(term.toOption())
      }
    })

    this.options = options
  }

  @Mutation
  public delete(terms: Terms | Terms[]) {
    // Set input to array
    let data: Array<Terms> = []
    if (terms instanceof Terms) {
      data.push(terms)
    } else {
      data = terms
    }

    let result = this.data

    // Remove Object from Module Data
    data.forEach((term: Terms) => {
      result = result.filter((obj: Terms) => obj.id != term.id)
    })

    this.data = result

    // Remove Object from Module Options
    let { options } = this
    data.forEach((term: Terms) => {
      options = options.filter((obj: SelectOption) => obj.value != term.id)
    })

    this.options = options
  }

  @Action
  public async find(id: string): Promise<Terms | null> {
    return new Promise(resolve => {
      const o = this.data.find(term => term.id === id)

      if (o instanceof Terms) {
        resolve(o)
      } else {
        return this.api
          .get(`terms/${id}`)
          .then(response => {
            // Parse & cache data
            const data = Terms.toObject(response.data.result.terms)
            if (data instanceof Terms) {
              resolve(data)
            }
          })
          .catch(() => {
            resolve(null)
          })
      }
    })
  }

  @Action
  public async paginate(options: PaginateOptions) {
    return this.api
      .get('terms/paginate', options)
      .then(response => {
        // Parse & cache data
        const data = Terms.toObjectList(response.data.result.terms)

        return {
          records: response.data.result.records,
          data,
        }
      })
      .catch(() => ({
        records: 0,
        data: [],
      }))
  }

  /**
   * @deprecated No longer needed after Elasticsearch implementation. Use searchOptions instead.
   */
  @Action
  public async syncOptions() {
    this.setSynchronized(true)
    return this.api
      .get('terms/option')
      .then(response => {
        // Parse & cache data
        const data = SelectOption.toObjectList(response.data.result.options)

        this.context.commit('setOptions', data)
      })
      .catch(() => ({
        records: 0,
        data: [],
      }))
  }

  @Action
  public async searchOptions(query: any) {
    return this.api
      .get('terms/search/option', query)
      .then(response => SelectOption.toObjectList(response.data.result.options))
      .catch(() => [])
  }
}
