import DataTableField from '@/components/DataTable/DataTableField'
import Invoice from '@/models/Invoice'
import { startCase } from 'lodash'

export default [
  new DataTableField('checkbox', '', 'checkbox', true, false),
  {
    key: 'id',
    label: 'ID',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
    thClass: 'align-middle text-center',
    thStyle: { width: '10%' },
  },
  {
    key: 'number',
    label: '#',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
    thStyle: { width: '10%' },
  },
  {
    key: 'revision',
    label: 'Revision',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
    thStyle: { width: '10%' },
  },
  {
    key: 'name',
    label: 'Invoice name',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center ',
  },
  {
    key: 'type',
    label: 'Type',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: false,
    thClass: 'align-middle text-center',
    formatter: (value: string) => startCase(value.replaceAll('_', ' ')),
  },
  {
    key: 'client',
    label: 'Client',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    type: 'object',
    object: {
      key: 'client',
      property: 'name',
    },
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'sales_management',
    label: 'Sales Manager',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    type: 'object',
    object: {
      key: 'sales_management',
      property: 'name',
    },
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'sales_rep',
    label: 'Sales Rep',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    type: 'object',
    object: {
      key: 'sales_rep',
      property: 'name',
    },
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'account_manager',
    label: 'Account Manager',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    type: 'object',
    object: {
      key: 'account_manager',
      property: 'name',
    },
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'products',
    label: 'Products',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    type: 'text',
    formatter: (value: string[]) => (!value || value.length == 0 ? 'N/A' : value.join(', ')),
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'tax',
    label: 'Tax',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
    thClass: 'align-middle text-center',
    type: 'currency',
  },
  {
    key: 'total',
    label: 'Total',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
    type: 'currency',
  },
  {
    key: 'due',
    label: 'Due',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
    type: 'currency',
  },
  {
    key: 'created_at',
    label: 'Invoice Date',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
    type: 'date',
    filter_type: 'date',
  },
  {
    key: 'due_at',
    label: 'Due At',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
    thClass: 'align-middle text-center',
    type: 'date',
    filter_type: 'date',
  },
  {
    key: 'updated_at',
    label: 'Update At',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
    thClass: 'align-middle text-center',
    type: 'date',
    filter_type: 'date',
  },

  {
    key: 'review_status',
    label: 'Review Status',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
    type: 'badge',
    color: (invoice: Invoice) =>
      (invoice.review_status === 'approved'
        ? 'success'
        : invoice.review_status === 'pending'
          ? 'warning'
          : 'danger'),
  },
  {
    key: 'reviewer',
    label: 'Reviewed By',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    type: 'object',
    object: {
      key: 'reviewer',
      property: 'name',
    },
    show: false,
    thClass: 'align-middle text-center',
  },
  {
    key: 'status',
    label: 'Status',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'trade_status',
    label: 'Trade Status',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'action',
    label: 'Action',
    sortable: false,
    show: true,
    thStyle: { width: '180px' },
    thClass: 'align-middle text-center',
    class: 'text-center align-middle',
  },
]
