/** @format */

import store from '@/store'
import { getModule } from 'vuex-module-decorators'
import ReportQueryModule from '@/store/model/ReportQueryModule'
import ApiParameters from '@/models/interface/ApiParameters'
import SystemtModule from '@/store/SystemModule'
import Company from './Company'
import User from './User'
import Api from './Api'
import ReportQuerySettings from './ReportQuerySettings'
import WebMessage from './WebMessage'
import DatePickerDate from './DatePickerDate'
import PaginateOptions from './interface/PaginateOptions'
import Filter from './Filter'
import SelectOption from './interface/SelectOption'

export default class ReportQuery {
  public id: string | null = null

  public name: string = ''

  public type: string = 'delivery'

  public user_id: string | null = null

  public company_id: string | null = null

  public station_id: string | null = null

  public start_at: string | null = null

  public end_at: string | null = null

  public created_at: string | null = null

  public updated_at: string | null = null

  public expiration: DatePickerDate = new DatePickerDate()

  public period: string = 'none'

  public system_report: boolean = false

  public to: string[] = []

  public settings: ReportQuerySettings = new ReportQuerySettings()

  public message: string = ''

  public resetSettings() {
    this.settings = new ReportQuerySettings()
  }

  public updateSettings(obj: any) {
    this.settings = Object.assign(this.settings, obj)
  }

  public clearFilters() {
    this.settings.filters = new Filter()
  }

  public set formType(value: any) {
    if (value) this.type = value
  }

  public get formType() {
    return this.type
  }

  public static toObject(data: any, cache: boolean = true): ReportQuery {
    let report_query = new ReportQuery()

    report_query.id = data.id
    report_query.name = data.name
    report_query.type = data.type
    report_query.user_id = data.user_id
    report_query.company_id = data.company_id
    report_query.station_id = data.station_id
    report_query.start_at = data.start_at
    report_query.end_at = data.end_at
    report_query.period = data.period
    report_query.to = data.to
    report_query.message = data.message
    report_query.created_at = data.created_at
    report_query.updated_at = data.updated_at
    report_query.system_report = data.system_report
    report_query.expiration = new DatePickerDate(
      report_query.start_at,
      report_query.end_at,
      null,
      'YYYY-MM-DD HH:mm:ss',
    )
    report_query.settings = ReportQuerySettings.toObject(data.settings)

    if (data.company) {
      report_query.company = Company.toObject(data.company)
    }

    if (data.station) {
      report_query.station = Company.toObject(data.station)
    }

    if (data.user) report_query.user = User.toObject(data.user)

    //  Cache Object
    if (cache) ReportQuery.module.update(report_query)

    return report_query
  }

  public static toObjectList(data: any, cache: boolean = true): ReportQuery[] {
    const report_queries = new Array<ReportQuery>()

    data.forEach((value: any) => {
      const report_query = ReportQuery.toObject(value, false)
      report_queries.push(report_query)
    })

    //  Cache Object List
    if (cache) ReportQuery.module.update(report_queries)

    return report_queries
  }

  public user: User = new User()

  public company: Company = new Company()

  public station: Company = new Company()

  public get date_type_name() {
    switch (this.settings.date.shortcut) {
      case '-day':
        return 'Yesterday'
      case 'week':
        return 'This Week'
      case '-week':
        return 'Last Week'
      case '-7':
        return 'Last 7 days'
      case '-30':
        return 'Last 30 days'
      case 'month':
        return 'This Month'
      case '-month':
        return 'Last Month'
      case 'year':
        return 'This Year'
      case '-year':
        return 'Last Year'
      default:
        return 'Custom'
    }
  }

  private buildParams(output: string | null = null) {
    const instance_id = getModule(SystemtModule)._uuid
    const data: ApiParameters = {
      name: this.name,
      type: this.type,
      system_report: this.system_report,
      to: this.to,
      period: this.period,
      start_at: this.expiration.start_date_string,
      end_at: this.expiration.end_date_string,
      insights: this.settings.insights,
      show_items: this.settings.insights ? this.settings.show_items : false,
      excel: this.settings.excel,
      line_items: this.settings.filters.line_items,
      publishers: this.settings.filters.publishers,
      devices: this.settings.filters.devices,
      account_executives: this.settings.filters.account_executives,
      sales_reps: this.settings.filters.sales_reps,
      advertisers: this.settings.filters.advertisers,
      agencies: this.settings.filters.agencies,
      stations: this.settings.filters.stations,
      orders: this.settings.filters.orders,
      order_type: this.settings.filters.order_type,
      product_name: this.settings.filters.product_name,
      billing_contracts: this.settings.filters.billing_contracts,
      dmas: this.settings.filters.dmas,
      dimensions: this.settings.dimensions,
      metrics: this.settings.metrics,
      message: this.message,
      date: this.settings.date.shortcut,
      start: this.settings.date.start_date_string,
      end: this.settings.date.end_date_string,
      view: this.settings.filters.view,
      clients: this.settings.filters.clients,
      agency_estimate_code: this.settings.filters.agency_estimate_code,
      agency_advertiser_code: this.settings.filters.agency_advertiser_code,
      adserver_orders: this.settings.filters.adserver_orders,
      adserver_line_items: this.settings.filters.adserver_line_items,
      inventory: this.settings.filters.inventory,

      output,
      instance_id,
    }

    if (this.settings.date.shortcut == null) {
      delete data.date
    } else if (this.type !== 'delivery_forecast') {
      delete data.start
      delete data.end
    }

    if (!output) {
      delete data.output
    }

    return data
  }

  public query(output: string | null = null) {
    const query: any = []
    const params: any = this.buildParams(output)
    Object.keys(params).forEach((key: string) => {
      if (Array.isArray(params[key]) && params[key].length > 0) {
        params[key].forEach((i: string) => {
          query.push({
            name: key,
            value: i,
          })
        })
      } else if (params[key] && params[key] != null && params[key] != '') {
        query.push({
          name: key,
          value: params[key],
        })
      }
    })

    return query.reduce((q: any, i: any) => {
      if (q) {
        q += '&'
      }

      return `${q + i.name}=${i.value}`
    }, '')
  }

  public fetch() {
    const api = new Api(false)

    return api.get('report', this.buildParams())
  }

  public download() {
    const api = new Api()
    return api.get('report', this.buildParams('download'))
    // .then(this.onDownload)
  }

  public share() {
    const api = new Api()

    return api.get('report', this.buildParams('mail')).then(this.onSend)
  }

  public send() {
    const api = new Api()

    return api.post(`report/${this.id}/send`, {}).then(this.onSend)
  }

  public save() {
    const api = new Api()

    if (this.id) {
      return api.patch(`report/${this.id}`, this.buildParams()).then(this.onSave)
    }
    return api.post('report', this.buildParams()).then(this.onSave)
  }

  public delete() {
    const api = new Api()

    return api.delete(`report/${this.id}`, {}).then(this.onDelete)
  }

  private onDownload(response: any) {
    WebMessage.success("Generating report, do not close this window until it's done.")

    return response
  }

  private onSend(response: any) {
    let message = 'Report sent!'

    if (response.data.result.report) {
      const report = ReportQuery.toObject(response.data.result.report)
      message = `Report "${report.name}" sent!`
    }

    WebMessage.success(message)

    return response
  }

  private onSave(response: any) {
    const report_queries = ReportQuery.toObject(response.data.result.report)

    WebMessage.success(`Report "${report_queries.name}" saved!`)

    return response
  }

  private onDelete(response: any) {
    const report_queries = ReportQuery.filter(response.data.result.deleted)

    let message

    if (report_queries.length == 1) {
      message = `Report "${report_queries[0].name}" deleted!`
    } else {
      message = 'Reports deleted!'
    }

    WebMessage.success(message)

    ReportQuery.module.delete(report_queries)

    return response
  }

  /// State Management
  public static get module(): ReportQueryModule {
    if (!store.hasModule('report_query')) {
      store.registerModule('report_query', ReportQueryModule)
    }

    return getModule(ReportQueryModule)
  }

  public static find(id: string): ReportQuery | null {
    const o = ReportQuery.module.data.find(report_query => report_query.id === id)
    return o instanceof ReportQuery ? o : null
  }

  public static filter(filter: any): ReportQuery[] {
    if (Array.isArray(filter)) {
      return ReportQuery.module.data.filter(
        report_query => report_query.id && filter.includes(report_query.id),
      )
    }
    return ReportQuery.module.data.filter(filter)
  }

  public static async get(id: string): Promise<ReportQuery | null> {
    return ReportQuery.module.find(id)
  }

  public static async paginate(options: PaginateOptions) {
    return ReportQuery.module.paginate(options)
  }

  public static async searchOptions(query: any) {
    const api = new Api()
    return api
      .get('reports/options', query)
      .then(response => SelectOption.toObjectList(response.data.result.options))
      .catch(() => [])
  }
}
