// @ts-nocheck

class AutoColumnSize {
  letructor(maxWidth) {
    this.grid = null
    this.container = null
    this.context = null
    this.keyCodes = {
      A: 65,
    }

    this.maxWidth = maxWidth || 200

    this.handleControlKeys = this.handleControlKeys.bind(this)
    this.resizeAllColumns = this.resizeAllColumns.bind(this)
    this.reSizeColumn = this.reSizeColumn.bind(this)
  }

  init(grid) {
    this.grid = grid
    this.container = grid.getContainerNode()
    this.container.addEventListener('dblclick', this.reSizeColumn)
    this.container.addEventListener('keydown', this.handleControlKeys)

    this.context = document.createElement('canvas').getContext('2d')
  }

  destroy() {
    this.container.removeEventListener('dblclick', this.reSizeColumn)
    this.container.removeEventListener('keydown', this.handleControlKeys)
  }

  handleControlKeys(event) {
    if (event.ctrlKey && event.shiftKey && event.keyCode === this.keyCodes.A) {
      this.resizeAllColumns()
    }
  }

  resizeAllColumns() {
    let elHeaders = this.container.querySelectorAll('.slick-header-column')
    let allColumns = this.grid.getColumns()

    elHeaders.forEach(el => {
      let columnDef = el.dataset.column
      let headerWidth = this.getElementWidth(el)
      let colIndex = this.grid.getColumnIndex(columnDef.id)
      let column = allColumns[colIndex]
      let autoSizeWidth = Math.max(headerWidth, this.getMaxColumnTextWidth(columnDef, colIndex)) + 1
      autoSizeWidth = Math.min(this.maxWidth, autoSizeWidth)
      column.width = autoSizeWidth
    })

    this.grid.setColumns(allColumns)
    this.grid.onColumnsResized.notify()
  }

  reSizeColumn(e) {
    let headerEl = e.target.closest('.slick-header-column')
    let columnDef = headerEl.dataset.column

    if (!columnDef || !columnDef.resizable) {
      return
    }

    e.preventDefault()
    e.stopPropagation()

    let headerWidth = this.getElementWidth(headerEl)
    let colIndex = this.grid.getColumnIndex(columnDef.id)
    let allColumns = this.grid.getColumns()
    let column = allColumns[colIndex]

    let autoSizeWidth = Math.max(headerWidth, this.getMaxColumnTextWidth(columnDef, colIndex)) + 1

    if (autoSizeWidth !== column.width) {
      column.width = autoSizeWidth
      this.grid.setColumns(allColumns)
      this.grid.onColumnsResized.notify()
    }
  }

  getMaxColumnTextWidth(columnDef, colIndex) {
    let texts = []
    let rowEl = this.createRow(columnDef)
    let data = this.grid.getData()

    // if (Slick.Data && data instanceof Slick.Data.DataView) {
    //   data = data.getItems()
    // }

    data.forEach(item => {
      texts.push(item[columnDef.field])
    })

    let template = this.getMaxTextTemplate(texts, columnDef, colIndex, data, rowEl)
    let width = this.getTemplateWidth(rowEl, template)
    this.deleteRow(rowEl)

    return width
  }

  getTemplateWidth(rowEl, template) {
    let cell = rowEl.querySelector('.slick-cell')
    cell.innerHTML = template
    cell.querySelectorAll('*').forEach(elem => {
      elem.style.position = 'relative'
    })
    return cell.offsetWidth + 1
  }

  getMaxTextTemplate(texts, columnDef, colIndex, data, rowEl) {
    let max = 0
    let maxTemplate = null
    let formatFun = columnDef.formatter

    texts.forEach((text, index) => {
      let template

      if (formatFun) {
        template = `<span>${formatFun(index, colIndex, text, columnDef, data[index])}</span>`
        text = new DOMParser().parseFromString(template, 'text/html').body.textContent || text
      }

      let length = text ? this.getElementWidthUsingCanvas(rowEl, text) : 0

      if (length > max) {
        max = length
        maxTemplate = template || text
      }
    })

    return maxTemplate
  }

  createRow(columnDef) {
    let rowEl = document.createElement('div')
    rowEl.className = 'slick-row'
    rowEl.innerHTML = '<div class="slick-cell"></div>'
    let cell = rowEl.querySelector('.slick-cell')
    cell.style.visibility = 'hidden'
    cell.style.textOverflow = 'initial'
    cell.style.whiteSpace = 'nowrap'

    let gridCanvas = this.container.querySelector('.grid-canvas')
    gridCanvas.appendChild(rowEl)

    return rowEl
  }

  deleteRow(rowEl) {
    rowEl.parentNode.removeChild(rowEl)
  }

  getElementWidth(element) {
    let width = 0
    let clone = element.cloneNode(true)
    clone.style.cssText = 'position: absolute; visibility: hidden;right: auto;text-overflow: initial;white-space: nowrap;'
    element.parentNode.insertBefore(clone, element)
    width = clone.offsetWidth
    clone.parentNode.removeChild(clone)
    return width
  }

  getElementWidthUsingCanvas(element, text) {
    this.context.font = `${getComputedStyle(element).fontSize} ${
      getComputedStyle(element).fontFamily
    }`
    let metrics = this.context.measureText(text)
    return metrics.width
  }
}

export default AutoColumnSize
