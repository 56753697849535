import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import router from '@/Routes'
import Vue from 'vue'
import CancelMediaPlan from '@/pages/Sales/MediaPlan/components/CancelMediaPlan.vue'
import ConfirmMediaPlan from '@/pages/Sales/MediaPlan/components/ConfirmMediaPlan.vue'
import ExportMediaPlan from '@/pages/Sales/MediaPlan/components/ExportMediaPlan.vue'
import { startCase } from 'lodash'
import MediaPlan from '../MediaPlan'
import WebMessage from '../WebMessage'
import SelectOption from '../interface/SelectOption'
import { LostCategories } from '../interface/Common'

const { user } = getModule(SystemtModule)

export const model_name = 'media_plan'

export const metrics = [
  new SelectOption('Impressions', 'impressions'),
  new SelectOption('Count', 'count'),
]

export const groups = [
  new SelectOption('Advertiser', 'advertiser'),
  new SelectOption('Agency', 'agency'),
  new SelectOption('Station', 'station'),
]

export const tableFields: any = [
  {
    key: 'checkbox',
    label: '',
    thClass: 'align-middle text-center',
    sortable: false,
    show: true,
    type: 'checkbox',
  },
  {
    key: 'id',
    label: 'ID',
    thClass: 'align-middle text-center',
    sortable: true,
    show: false,
    tdClass: 'align-middle',
  },
  {
    key: 'number',
    label: '#',
    thClass: 'align-middle text-center',
    sortable: true,
    show: true,
    tdClass: 'align-middle text-center',
  },
  {
    key: 'name',
    label: 'Name',
    thClass: 'align-middle text-center',
    sortable: true,
    show: true,
    tdClass: 'align-middle text-center',
  },
  {
    key: 'type',
    label: 'Type',
    thClass: 'align-middle text-center',
    sortable: true,
    show: true,
    type: 'capitalize',
    tdClass: 'align-middle text-center',
    formatter: (value: string | null) => (value ? startCase(value.replaceAll('_', ' ')) : 'N/A'),
    filter_type: 'picker',
    options: [
      new SelectOption('Type', ''),
      new SelectOption('Default', 'default'),
      new SelectOption('Media Ocean', 'media_ocean'),
      new SelectOption('Strata', 'strata'),
    ],
  },
  {
    key: 'products',
    label: 'Products',
    thClass: 'align-middle text-center',
    sortable: true,
    show: true,
    type: 'uppercase',
    tdClass: 'align-middle text-center',
    formatter: (value: string[]) => value.join(', '),
    filter_type: 'picker',
    options: [
      new SelectOption('Products', ''),
      new SelectOption('SSL', 'ssl'),
      new SelectOption('CCL', 'ccl'),
      new SelectOption('SSL & CCL', 'ssl_ccl'),
    ],
  },
  {
    key: 'flight_dates',
    label: 'Flight Dates',
    thClass: 'align-middle text-center',
    sortable: false,
    show: true,
    tdClass: 'align-middle text-center',
  },
  {
    key: 'agency_id',
    label: 'Agency',
    thClass: 'align-middle text-center',
    sortable: true,
    show: true,
    type: 'object',
    object: {
      key: 'agency',
      property: 'name',
    },
    tdClass: 'align-middle text-center',
  },
  {
    key: 'station_id',
    label: 'Station',
    thClass: 'align-middle text-center',
    sortable: true,
    show: true,
    type: 'object',
    object: {
      key: 'station',
      property: 'name',
    },
    tdClass: 'align-middle text-center',
  },
  {
    key: 'advertiser_id',
    label: 'Advertiser',
    thClass: 'align-middle text-center',
    sortable: true,
    show: true,
    type: 'object',
    object: {
      key: 'advertiser',
      property: 'name',
    },
    tdClass: 'align-middle text-center',
  },
  {
    key: 'sales_management',
    label: 'SM',
    thClass: 'align-middle text-center',
    sortable: true,
    show: false,
    type: 'object',
    object: {
      key: 'sales_management',
      property: 'name',
    },
    tdClass: 'align-middle text-center',
  },
  {
    key: 'sales_rep',
    label: 'AE',
    thClass: 'align-middle text-center',
    sortable: true,
    show: false,
    type: 'object',
    object: {
      key: 'sales_rep',
      property: 'name',
    },
    tdClass: 'align-middle text-center',
  },
  {
    key: 'secondary_sales_reps',
    label: 'Secondary Sales Reps',
    thClass: 'align-middle text-center',
    sortable: false,
    show: false,
    type: 'text',
    tdClass: 'align-middle text-center',
    formatter: (value: any, key: string, media_plan: MediaPlan) =>
      (media_plan.hasOwnProperty('secondary_sales_reps')
      && media_plan.secondary_sales_reps.length > 0
        ? media_plan.secondary_sales_reps.map((rep: any) => rep.name).join(', ')
        : 'N/A'),
  },
  {
    key: 'account_manager',
    label: 'AM',
    thClass: 'align-middle text-center',
    sortable: true,
    show: true,
    type: 'object',
    object: {
      key: 'account_manager',
      property: 'name',
    },
    tdClass: 'align-middle text-center',
  },
  {
    key: 'status',
    label: 'Status',
    thClass: 'align-middle text-center',
    sortable: true,
    show: true,
    type: 'badge',
    color: (media_plan: MediaPlan) => media_plan.status_color,
    formatter: (value: any, key: string, media_plan: MediaPlan) => media_plan.formStatus,
    tdClass: 'align-middle text-center status-col',
    filter_type: 'picker',
    options: [
      new SelectOption('Status', ''),
      new SelectOption('Draft', 'draft'),
      new SelectOption('Pending Documents', 'pending_documents'),
      new SelectOption('Pending Review', 'pending_review'),
      new SelectOption('Confirmed', 'confirmed'),
      new SelectOption('Cancelled', 'cancelled'),
      new SelectOption('Lost', 'lost'),
      new SelectOption('Pending Revision', 'pending_revision'),
    ],
  },
  {
    key: 'demo_targets',
    label: 'Demo Group',
    thClass: 'align-middle text-center',
    sortable: true,
    show: false,
    tdClass: 'align-middle text-center',
  },
  {
    key: 'call_letters',
    label: 'Call Letters',
    thClass: 'align-middle text-center',
    sortable: true,
    show: false,
    tdClass: 'align-middle text-center',
    type: 'object',

  },
  {
    key: 'idb_number',
    label: 'IDB Number',
    thClass: 'align-middle text-center',
    sortable: true,
    show: false,
    tdClass: 'align-middle text-center',
  },
  {
    key: 'lost_category',
    label: 'Lost Category',
    thClass: 'align-middle text-center',
    sortable: true,
    show: false,
    type: 'text',
    formatter: (value: any, key: string, media_plan: MediaPlan) =>
      (value ? startCase(value.replaceAll('_', ' ')) : '-'),
    filter_type: 'picker',
    options: [new SelectOption('Lost Category', ''), ...LostCategories],
    tdClass: 'align-middle text-center',
  },
  {
    key: 'close_reason',
    label: 'Lost Reason',
    thClass: 'align-middle text-center',
    sortable: true,
    show: false,
    tdClass: 'align-middle text-center',
  },
  {
    key: 'created_at',
    label: 'Created At',
    thClass: 'align-middle text-center',
    sortable: true,
    show: false,
    type: 'date',
    tdClass: 'align-middle text-center',
    filter_type: 'date',
  },
  {
    key: 'updated_at',
    label: 'Update At',
    thClass: 'align-middle text-center',
    sortable: true,
    show: false,
    type: 'date',
    tdClass: 'align-middle text-center',
    filter_type: 'date',
  },
  {
    key: 'action',
    label: '',
    thClass: 'align-middle text-center',
    sortable: false,
    show: true,
    type: 'action_list',
    tdClass: 'actions',
    thStyle: { width: '160px' },
    actions: [
      {
        icon: 'eye',
        title: 'View Plan',
        event: 'view',
        top_level: user?.department !== 'adops',
        action: (media_plan: MediaPlan) => {
          let ref = window.location.href.includes('legacy-dashboard') ? '?ref=LegacyDashboard' : ''
          router.push(`/app/sales/media_plan/${media_plan.id}/view${ref}`)
        },
      },
      {
        icon: 'calendar',
        title: 'View Schedule',
        event: 'view-schedule',
        top_level: user?.department === 'adops',
        action: (media_plan: MediaPlan) => {
          let ref = window.location.href.includes('legacy-dashboard') ? '?ref=LegacyDashboard' : ''
          router.push(`/app/sales/media_plan/${media_plan.id}/view/schedule${ref}`)
        },
      },
      {
        icon: 'search',
        title: 'Show Details',
        event: 'details',
        action: (media_plan: MediaPlan) => {
          if (media_plan.line_items.length === 0) {
            media_plan.loadLineItems()
          }
        },
        top_level: true,
      },
      {
        icon: 'las la-handshake',
        title: 'View Opportunity',
        event: 'viewOpportunity',
        action: (media_plan: MediaPlan) => {
          router.push(`/app/opportunity/${media_plan.opportunity_id}`)
        },
      },
      {
        icon: 'pencil',
        title: 'Edit',
        event: 'edit',
        top_level: true,
        show: () => user && user.can('media_plan', 'edit'),
        action: (media_plan: MediaPlan) => {
          let ref = window.location.href.includes('legacy-dashboard') ? '?ref=LegacyDashboard' : ''
          router.push(`/app/sales/media_plan/${media_plan.id}${ref}`)
        },
      },

      {
        icon: 'copy',
        title: 'Clone',
        event: 'clone',
        show: () => user && user.can('media_plan', 'edit'),
        action: (media_plan: MediaPlan) => {
          let ref = window.location.href.includes('legacy-dashboard') ? '?ref=LegacyDashboard' : ''
          router.push(`/app/sales/media_plan/?from=${media_plan.id}${ref}`)
        },
      },
      {
        icon: 'download',
        title: 'Export',
        event: 'exportXML',
        action: (media_plan: MediaPlan, dataTable: any) => {
          let Component = Vue.extend(ExportMediaPlan)
          let instance = new Component({
            propsData: {
              media_plans: [media_plan],
              mode: 'single',
            },
          })
          instance.$mount()
        },
      },
      {
        icon: 'file',
        title: 'Confirm Order',
        event: 'createIO',
        show: (media_plan: MediaPlan) => user && user.can('media_plan', 'edit'),
        action: (media_plan: MediaPlan) => {
          let Component = Vue.extend(ConfirmMediaPlan)
          let instance = new Component({
            propsData: {
              media_plan,
            },
          })
          instance.$mount()
        },
      },
      {
        icon: 'times',
        title: 'Cancel',
        event: 'cancel',
        show: (media_plan: MediaPlan) =>
          user && user.can('media_plan', 'edit') && !['cancelled'].includes(media_plan.status),
        action: (media_plan: MediaPlan, dataTable: any) => {
          let Component = Vue.extend(CancelMediaPlan)
          let instance = new Component({
            propsData: {
              media_plan,
              dataTable,
            },
          })
          instance.$mount()
        },
      },
      {
        icon: 'trash',
        title: 'Delete',
        event: 'delete',
        show: (media_plan: MediaPlan) => user && user.can('media_plan', 'delete'),
        action: (media_plan: MediaPlan, dataTable: any) => {
          if (media_plan.metrics.impressions > 0) {
            WebMessage.error(
              'You cannot delete a media plan with active campagins associate with it.',
            )
            return
          }

          WebMessage.confirm(
            `Are you sure that you want to delete the Media Plan "<strong>${media_plan.name}</strong>"? You won't be able to restore it!`,
            'Delete Media Plan',
          ).then((value: boolean) => {
            if (value) {
              media_plan.delete().then(() => {
                dataTable.refresh()
              })
            }
          })
        },
      },
      {
        icon: 'exclamation-circle',
        title: 'Mark as Pending Revision',
        event: 'pending-revision',
        show: (media_plan: MediaPlan) => user && user.can('media_plan', 'edit'),
        action: (media_plan: MediaPlan, dataTable: any) => {
          WebMessage.confirm(
            `Are you sure that you want to set the Media Plan "<strong>${media_plan.name}</strong>" as pending revision?`,
            'Mark as Pending Revision',
          ).then((value: boolean) => {
            if (value) {
              media_plan.status = 'pending_revision'
              media_plan.save().then(() => {
                dataTable.refresh()
              })
            }
          })
        },
      },
    ],
  },
]
