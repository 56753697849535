import store from '@/store'
import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators'
import SelectOption from '@/models/interface/SelectOption'
import Api from '@/models/Api'
import ReportQuery from '@/models/ReportQuery'
import PaginateOptions from '@/models/interface/PaginateOptions'

@Module({
  dynamic: true, store, namespaced: true, name: 'report_query',
})
export default class ReportQueryModule extends VuexModule {
  public data: ReportQuery[] = [];

  public options: SelectOption[] = [];

  public synchronized: boolean = false;

  public get api() {
    return new Api(false)
  }

  @Mutation
  public setSynchronized(status: boolean) {
    this.synchronized = status
  }

  @Mutation
  public update(report_queries: ReportQuery | ReportQuery[]) {
    let data: Array<ReportQuery> = []
    if (report_queries instanceof ReportQuery) {
      data.push(report_queries)
    } else {
      data = report_queries
    }

    let result = this.data

    data.forEach((report_query: ReportQuery) => {
      let found: boolean = false
      result = result.map((obj: ReportQuery) => {
        if (obj.id == report_query.id) {
          found = true
          return report_query
        }
        return obj
      })

      if (!found) {
        result.push(report_query)
      }
    })

    this.data = result
  }

  @Mutation
  public delete(report_queries: ReportQuery | ReportQuery[]) {
    let data: Array<ReportQuery> = []
    if (report_queries instanceof ReportQuery) {
      data.push(report_queries)
    } else {
      data = report_queries
    }

    let result = this.data

    data.forEach((report_query: ReportQuery) => {
      result = result.filter((obj: ReportQuery) => obj.id != report_query.id)
    })

    this.data = result
  }

  @Action
  public async find(id: string): Promise<ReportQuery | null> {
    return new Promise(resolve => {
      const o = this.data.find(report_query => report_query.id === id)

      if (o instanceof ReportQuery) {
        resolve(o)
      } else {
        return this.api.get(`reports/${id}`)
          .then(response => {
            let data = ReportQuery.toObject(response.data.result.report)

            if (data instanceof ReportQuery) {
              resolve(data)
            }

            return data
          }).catch(e => {
            resolve(null)
          })
      }
    })
  }

  @Action
  public async paginate(options: PaginateOptions) {
    return this.api.get('reports/paginate', options)
      .then(response => {
        // Parse & cache data
        const data = ReportQuery.toObjectList(response.data.result.reports)

        return {
          records: response.data.result.records,
          data,
        }
      }).catch(() => ({
        records: 0,
        data: [],
      }))
  }
}
