
import { Component, Prop, Watch } from 'vue-property-decorator'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import User from '@/models/User'
import ViewModel from '@/models/ViewModel'
import SelectOption from '@/models/interface/SelectOption'

@Component({
  components: {
    SelectPicker,
  },
})
export default class UserPicker extends ViewModel {
  @Prop({ default: true })
  public edit!: boolean

  @Prop({ default: false })
  public hideDescription!: boolean

  @Prop({ default: false })
  public allow_empty!: boolean

  @Prop()
  public company_id!: string

  @Prop()
  public station_id!: string

  @Prop({ default: 30 })
  public duration!: number

  @Prop({ default: false })
  public vertical!: boolean

  @Prop({ default: false })
  public create_panel!: boolean

  @Prop({ default: 'User' })
  public label!: string

  @Prop({ default: () => null })
  public value!: string | null

  @Prop({ default: () => [] })
  public preload_options!: SelectOption[]

  @Prop({ default: false })
  public disabled!: boolean

  @Prop()
  public userScope!: any

  public local_value: string[] | string | null = []

  public new_id: string = ''

  public show_sidebar: boolean = false

  public update: boolean = true

  public uuid: string = ''

  public busy: boolean = false

  public ready: boolean = false

  public options: SelectOption[] = []

  public label_name: string = 'N/A'

  public selected_opt: SelectOption | null = null

  public mounted() {
    this.uuid = this.randomUUID()
    // this.local_value = this.value // in case of future bug uncomment this line, it was added to created()
  }

  public created() {
    if (this.preload_options.length > 0) {
      this.options = [...this.preload_options]
    }

    if (!this.value || (Array.isArray(this.value) && this.value.length == 0)) {
      this.local_value = this.value
      this.ready = true
    } else {
      this.busy = true

      let has_option = false

      if (Array.isArray(this.value)) {
        has_option = true
        this.value.forEach((v: any) => {
          if (!this.options.some((o: any) => o.value == v)) {
            has_option = false
          }
        })
      } else {
        has_option = this.options.some((o: any) => o.value == this.value)
      }

      if (has_option) {
        this.local_value = this.value
        this.label_name = this.options.find((o: any) => o.value == this.value)?.name ?? 'N/A'
        this.ready = true
        this.busy = false
        return
      }

      User.searchOptions({
        value: this.value,
      }).then(response => {
        this.options = response
        this.busy = false
        this.local_value = this.value
        this.label_name = response[0]?.name
        this.updateLabel()
        this.ready = true
      })
    }
  }

  public updateLabel() {
    if (!this.value) {
      this.label_name = 'N/A'
    } else if (!Array.isArray(this.value)) {
      User.searchOptions({
        value: this.value,
      }).then(response => {
        this.label_name = response[0]?.name
        if (response.length) {
          this.selected_opt = response[0]
        }
        this.ready = true
      })
    }
  }

  public searchOptions(search = '*') {
    this.busy = true
    if (!search) search = '*'

    User.searchOptions({
      search: search.includes('*') ? search : `*${search}*`,
      owner_id: this.station_id ?? this.company_id,
      type: this.userScope,
    }).then(response => {
      this.options = response
      this.busy = false
      this.ready = true
    })
  }

  public get can_create() {
    return this.create_panel && this.user.can('user', 'edit')
  }

  public get panel_callback(): any {
    return this.layout.side_panel_callback.user
  }

  @Watch('local_value')
  public onChangeValue() {
    this.update = false
    this.$emit('input', this.local_value)
  }

  @Watch('value')
  public onChangeProp() {
    if (this.update) {
      let has_options = false
      if (Array.isArray(this.value)) {
        has_options = true
        this.value.forEach((v: any) => {
          if (!this.options.some((o: any) => o.value === v)) {
            has_options = false
          }
        })
      }
      if (
        (Array.isArray(this.value) && this.value.length > 0 && !has_options)
        || typeof this.value === 'string'
      ) {
        User.searchOptions({
          value: Array.isArray(this.value) ? this.value : [this.value],
        }).then(response => {
          this.options = response
          this.busy = false
          this.local_value = this.value
          this.ready = true
        })
      } else {
        this.local_value = this.value
      }
    }
    this.update = true
    this.updateLabel()
  }

  @Watch('panel_callback')
  public onCallback() {
    if (this.uuid == this.layout.side_panel_id && this.panel_callback) {
      this.local_value = this.panel_callback
      this.layout.clearSidePanelCallback()
    }
  }

  public toggleSideBar() {
    this.layout.showSidePanel({
      name: 'user',
      id: this.uuid,
      data: {
        company_id: this.company_id,
        station_id: this.station_id,
      },
    })
  }

  public open() {
    if (this.options.length == 0) this.searchOptions()
  }
}
