import Api from './Api'
import CompanyContact from './CompanyContact'
import Model from './interface/Model'

export default class OpportunityActivity extends Model {
  protected api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'activity' as string | null,
      plural: 'activities' as string | null,
    },
  }

  public id: string | null = null

  public name: string = ''

  public type: string | null = null

  public sub_type: string | null = null

  public add_note: boolean = false

  public edit_note: boolean = false

  public notes: any = []

  public content: any = null // comment , notes

  public created_at: any = null

  public scheduled_at: any = null

  public owner_model: any = null

  public delete_on_save: any = false

  public company_contact_ids: string[] = []

  public contacts: CompanyContact[] = []

  public toggleAddNote() {
    this.add_note = !this.add_note
  }

  public get formOpportunityStatus() {
    return this.owner_model.status
  }

  public get formOpportunityAgency() {
    return this.owner_model.agency.name
  }

  public get formOpportunityAdvertiser() {
    return this.owner_model.advertiser.name
  }

  public get formOpportunityStation() {
    return this.owner_model.station.name
  }

  public get formOpportunitySalesRep() {
    return this.owner_model.sales_rep.name
  }

  public get formOpportunityName() {
    return this.owner_model.name
  }

  public get formOpportunityAmount() {
    return this.owner_model.amount
  }

  // public get apiData() {
  //   return {
  //     id: this.id,
  //     name: this.name,
  //     type: this.type,
  //     sub_type: this.sub_type,
  //     notes: this.notes,
  //     content: this.content,
  //     created_at: this.created_at,
  //   }
  // }

  public static amountSummary(payload: any) {
    let api = new Api()
    return api.post('activities/summary-card', payload)
  }
}
