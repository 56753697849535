import ModelWithFiles from './interface/ModelWithFiles'
import Invoice from './Invoice'

export default class Payment extends ModelWithFiles {
  protected api_settings = {
    save_mode: 'form',
    paths: {
      singular: 'payment' as string | null,
      plural: 'payments' as string | null,
    },
  }

  public name!: string

  public id: null | string = null

  public invoice_id: null | string = ''

  public invoice: Invoice = new Invoice()

  public payment_method: string = 'wire'

  public amount: number = 0

  public status: string = 'confirmed'

  public reference_code: string = ''

  public created_at: string = ''

  public updated_at: string = ''

  public conflict : boolean = false

  public get apiData(): any {
    return {
      invoice_id: this.invoice_id,
      payment_method: this.payment_method,
      reference_code: this.reference_code,
      amount: this.amount,
      status: this.status,
      created_at: this.created_at,
    }
  }
}
