
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'

import ViewModel from '@/models/ViewModel'

import Api from '@/models/Api'

import { read, utils } from 'xlsx'

// @ts-ignore
import SlimGrid from 'vue-slimgrid'
import 'vue-slimgrid/dist/slimgrid.css'
import AutoColumnSize from './slick.autocolumnsize'

@Component({
  components: { SlimGrid },
})
export default class SheetModal extends ViewModel {
  @Ref() public excelViewerComp!: any

  @Ref() public sheetGrid!: SlimGrid

  public open_modal: boolean = false

  public loading_data: boolean = true

  public tableData: any = []

  public file: any = {}

  public table_settings: any = {
    height: 500,
    explicitColumns: [],
    columnOptions: {
      '*': {
        asyncPostRender: this.checkPostRender,
      },
    },
    customPlugins: {
      AutoColumnSize: {
        register: true,
        plugin: new AutoColumnSize(200),
      },
    },
  }

  @Watch('open_modal')
  public onOpenModalChange(val) {
    this.toggleFooterNav(val)
  }

  public checkPostRender(cellNode, row, dataContext, colDef) {
    setTimeout(() => {
      this.sheetGrid.slickGrid.autosizeColumns()
      // autosizeColumns is from the plugin
    }, 100)
  }

  public toggleFooterNav(hide = true) {
    let footerNav = document.querySelector('footer.footer-nav')
    if (footerNav) {
      if (hide) {
        footerNav.classList.add('d-none')
      } else {
        footerNav.classList.remove('d-none')
      }
    }
  }

  public previewSheet(file: any) {
    this.file = file
    this.open_modal = true

    let api = new Api()

    api.get(`opportunities/view_file/${file.id}`, { responseType: 'arraybuffer' }).then(res => {
      let el: any = document.getElementById('sheet-modal')

      let width = Number(el.querySelector('.modal-body').clientWidth)
      let height = Number(el.querySelector('.modal-body').clientHeight)

      // this.table_settings.height = height

      const workbook = read(res.data)

      const sheetName = workbook.SheetNames[0]

      let excelData: any = utils.sheet_to_json(workbook.Sheets[sheetName], {
        header: 1,
      })

      // get the longest array
      const lengths = excelData.map(a => a.length)
      let longest = lengths.indexOf(Math.max(...lengths))

      // create Alphabet cols based on the length of the longest array(row)
      this.table_settings.explicitColumns = excelData[longest].map((str, idx) =>
        String.fromCharCode(65 + idx))

      excelData = excelData.filter(d => d.length > 0)

      let rows: any = []

      let format_this_indexes = {}

      excelData.forEach((data: any, index) => {
        let obj: any = {}

        if (data.length > 1) {
          // can be a header check for dates
          if (data.toString().toLowerCase().includes('date')) {
            let possible_date_names = ['flight start', 'flight end', 'end date', 'start date']
            // check next array if it contains same len as this data
          }
        }
        data.forEach((d, i) => {
          // check if d is a number and if its contains decimals, if it does format to money
          if (!isNaN(d)) {
            // check if it is a decimal or integer
            if (d.toString().includes('.')) {
              // d = parseFloat(d) make two decimals
              d = d.toFixed(2)
            } else {
              // d = d.toLocaleString('en-US')
              // format as #,##0
            }
          }
          obj[String.fromCharCode(65 + i)] = d
          obj.id = String.fromCharCode(65 + i) + (index + 1)
        })

        rows.push(obj)
      })

      this.tableData = rows

      this.loading_data = false
    })
  }
}
