import moment from 'moment'
import { clone as _clone } from 'lodash'
import BigNumber from 'bignumber.js'
import Model from './interface/Model'
import MediaPackage from './MediaPackage'
import Api from './Api'
import SelectOption from './interface/SelectOption'
import DynamicRate from './DynamicRate'
import LineItem from './LineItem'
import Order from './Order'
import MediaPlan, { Schedule } from './MediaPlan'
import RateCard from './RateCard'

export default class MediaPlanItem extends Model {
  public api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'media_plan_item' as string | null,
      plural: 'media_plan_items' as string | null,
    },
  }

  public number: number = 1

  public name: string = ''

  public creative_length: number = 30

  public media_plan_id: string | null = null

  public media_plan: MediaPlan | null = null

  public media_package_id: string | null = null

  public media_package: MediaPackage | null = null

  public start_at: string = moment().format('YYYY-MM-DD')

  public end_at: string = moment().endOf('month').format('YYYY-MM-DD')

  public impressions: number = 0

  public open_ended: boolean = false

  public product: string = 'ssl'

  public model: string = 'cpm'

  public gross_rate: number = 0

  public net_rate: number = 0

  public gross_cost: number = 0

  public net_cost: number = 0

  public agency_commission: number = 0

  public agency_commission_model: string = 'none'

  public type: string = 'default'

  public notes: string = ''

  public max_avails: number = 0

  public grps: number = 0

  public dynamic_rate: null | DynamicRate = null

  public dynamic_rate_id: null | string = null

  public special_features: string[] = []

  public third_party_deal: any = false

  public metadata: any = {
    invoice_name: '',
    program_name: '',
    order_type: 'cash',
    spots: [] as number[],
    flight_time: {
      start_at: '19:00:00',
      end_at: '23:59:59',
    },
    demo_target: 0,
    days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
    targetting: {
      include: {
        dmas: [],
        states: [],
        zipcodes: [],
      },
      exclude: {
        dmas: [],
        states: [],
        zipcodes: [],
      },
    },
    delivery_schedule_overwrite: false,
    delivery_schedule: [],
    billing_schedule_overwrite: false,
    billing_schedule: [],
    targets: {
      vcr: 0,
      ctv: 0,
      live: 0,
      audience: false,
      foot_traffick: false,
    },
    publishers: {
      live: [],
      fep: [],
    },
  }

  public get hasTargets() {
    // @ts-ignore
    return (
      this.metadata.targets.vcr > 0
      || this.metadata.targets.ctv > 0
      || this.metadata.targets.live > 0
      || this.metadata.targets.audience
      || this.metadata.targets.foot_traffick
    )
  }

  public get lowGrossRate() {
    // @ts-ignore
    return this.formGrossRate < this.recommendedRate
  }

  public get recommendedRate() {
    const rates = new RateCard()

    const vcr = new BigNumber(this.metadata.targets.vcr).dividedBy(100)
    const ctv = new BigNumber(this.metadata.targets.ctv).dividedBy(100)
    const live = new BigNumber(this.metadata.targets.live).dividedBy(100)

    return rates.calculateRate(
      vcr,
      ctv,
      live,
      this.metadata.targets.audience,
      this.metadata.targets.foot_traffick,
    )
  }

  public metrics: any = {
    distribution: {
      ctv: 0.1,
    },
  }

  public has_listener: boolean = false
  /**
   * UI Form Fields
   */

  public visible: boolean = true

  public edit: boolean = true

  public _showDetails: boolean = true

  public _loading: boolean = false

  private schedule_dates: any = null

  public third_party: any = false

  public billing_schedule: Schedule = {}

  public revenue_schedule: Schedule = {}

  public get effective_start_at() {
    if (!this.media_plan?.isLinear || !this.metadata.days || this.metadata.days.length === 0) {
      return this.start_at
    }

    let pointer = moment(this.start_at)
    let end = moment(this.end_at)

    while (pointer.isBefore(end)) {
      if (this.metadata.days.includes(pointer.format('dddd').toLowerCase())) {
        return pointer.format('YYYY-MM-DD')
      }
      pointer.add(1, 'day')
    }

    return this.start_at
  }

  public get effective_end_at() {
    if (!this.media_plan?.isLinear || !this.metadata.days || this.metadata.days.length === 0) {
      return this.end_at
    }

    let pointer = moment(this.end_at)
    let start = moment(this.start_at)

    while (pointer.isAfter(start)) {
      if (this.metadata.days.includes(pointer.format('dddd').toLowerCase())) {
        return pointer.format('YYYY-MM-DD')
      }
      pointer.subtract(1, 'day')
    }

    return this.end_at
  }

  private buildScheduleDates() {
    if (!this.isLinear) {
      this.schedule_dates = {
        start_at: this.start_at,
        end_at: this.end_at,
      }
      return
    }

    let start = moment(this.start_at)
    let end = moment(this.end_at)

    let pointer = start.clone()
    let itemEnd = end.clone()
    let lockStart = false
    while (pointer.isSameOrBefore(itemEnd)) {
      if (this.metadata.days.includes(pointer.format('dddd').toLowerCase())) {
        if (!lockStart) {
          lockStart = true
          start = pointer.clone()
        }
        end = pointer.clone()
      }

      pointer.add(1, 'day')
    }
    this.schedule_dates = {
      start_at: start.format('YYYY-MM-DD'),
      end_at: end.format('YYYY-MM-DD'),
    }
  }

  public get projected_revenue(): number {
    if (this.metadata.order_type === 'trade') return 0

    return this.net_cost
  }

  public get projected_net_revenue(): number {
    if (this.metadata.order_type === 'trade') return 0

    return this.net_cost - this.projected_media_cost
  }

  public get projected_media_rate_cost(): number {
    if (
      !this.media_package
      || !this.media_package.metrics
      || !this.media_package.metrics.rates
      || !this.media_package.metrics.rates.cpm
    ) {
      return 0
    }

    // Fetch Default AVG Rate
    let target: any = this.media_package.metrics.rates.cpm.find(
      (rate: any) => !rate.special_features || rate.special_features.length === 0,
    )

    // CHeck if package has a special rate for this item
    this.media_package.metrics.rates.cpm.forEach((rate: any) => {
      if (
        this.special_features.length === rate.special_features.length
        && this.special_features.every((feature: any) => rate.special_features.includes(feature))
      ) {
        target = rate
      }
    })

    if (!target) return 0

    return target.average_rate
  }

  public get projected_media_cost(): number {
    if (!this.media_package) return 0

    return this.projected_media_rate_cost * (this.impressions / 1000)
  }

  public get projected_profitability(): number {
    return this.projected_net_revenue / this.net_cost
  }

  public get schedule_start_at(): string {
    if (this.schedule_dates) {
      return this.schedule_dates.start_at
    }
    this.buildScheduleDates()
    return this.schedule_dates.start_at
  }

  public get schedule_end_at(): string {
    if (this.schedule_dates) {
      return this.schedule_dates.end_at
    }
    this.buildScheduleDates()
    return this.schedule_dates.end_at
  }

  public get period() {
    let start = moment().month(4).startOf('month')
    let end = moment().month(9).endOf('month')

    if (moment(this.start_at).isBetween(start, end)) {
      return 'april_september'
    }
    return 'october_march'
  }

  public get fullName(): string {
    return this.name !== '' ? `#${this.number} ${this.name}` : 'N/A'
  }

  public getScheduleName(media_plan: MediaPlan): string {
    let week = media_plan.lineItemSpotIndex(this.start_at, 0)
    let start = moment(this.schedule_start_at)
    let end = moment(this.schedule_end_at)
    let name = `${this.name}: ${start.format('MM/DD')} - ${end.format('MM/DD')}`
    if (this.metadata.program_name) {
      name = `${this.metadata.program_name} (Week ${week}): ${start.format('MM/DD')} - ${end.format(
        'MM/DD',
      )}`
    }

    return name
  }

  public get topISCI(): string {
    if (!this.metrics || !this.metrics.isci_codes || this.metrics.isci_codes.length === 0) return ''
    return this.metrics.isci_codes.sort((a: any, b: any) => b.impressions - a.impressions)[0]
      .isci_code
  }

  public get topISCIDuration(): string {
    if (!this.metrics || !this.metrics.isci_codes || this.metrics.isci_codes.length === 0) return ''
    return (
      this.metrics.isci_codes.sort((a: any, b: any) => b.impressions - a.impressions)[0].duration
      ?? 0
    )
  }

  public get formModel() {
    return this.model
  }

  public set formModel(model: string) {
    this.model = model
    this.formImpressions = this.impressions
  }

  public get formMediaPackageId() {
    return this.media_package_id
  }

  public set formMediaPackageId(media_package_id: string | null) {
    this.media_package_id = media_package_id

    if (media_package_id) {
      MediaPackage.find(media_package_id).then((media_package: MediaPackage) => {
        this.media_package = media_package
      })
    }
  }

  public get formAgencyCommissionModel() {
    return this.agency_commission_model
  }

  public set formAgencyCommissionModel(agency_commission_model: string) {
    this.agency_commission_model = agency_commission_model
    this.formAgencyCommission = this.agency_commission
  }

  public get formAgencyCommission() {
    return this.agency_commission
  }

  public set formAgencyCommission(agency_commission: number) {
    this.agency_commission = agency_commission
    // Recalculate values
    if (this.isLinear) {
      if (this.impressions > 0 && this.gross_cost > 0) {
        this.gross_rate = +this.bnGrossCost.div(
          this.model === 'flat' ? this.bnImpressions : this.bnImpressions.div(1000),
        )
      }
    } else if (this.impressions > 0 && this.net_rate > 0) {
      if (this.impressions > 0 && this.gross_cost > 0) {
        this.net_cost = +this.bnNetRate.times(
          this.model === 'flat' ? this.bnImpressions : this.bnImpressions.div(1000),
        )
      }
    }

    this.updateCostAndRate()
  }

  public get billing_schedule_total(): number {
    let total: any = Object.values(this.billing_schedule).reduce(
      (a: any, b: any) => Number(a) + Number(b),
      0,
    )
    return total ?? 0
  }

  public get revenue_schedule_total(): number {
    let total: any = Object.values(this.revenue_schedule).reduce(
      (a: any, b: any) => Number(a) + Number(b),
      0,
    )
    return total || 0
  }

  public get schedule_exceeds() {
    return {
      revenue_schedule_total: this.revenue_schedule_total - this.net_cost !== 0,
    }
  }

  public toggleDay(day: string) {
    if (!this.metadata.days.includes(day)) {
      this.metadata.days.push(day)
    } else {
      this.metadata.days = this.metadata.days.filter((d: string) => d !== day)
    }
  }

  public updateCostAndRate() {
    if (this.isLinear) {
      if (this.agency_commission_model === 'none' || this.agency_commission == 0) {
        this.net_cost = +this.bnGrossCost
        this.net_rate = +this.bnGrossRate
      } else if (this.agency_commission_model === 'percentage') {
        this.net_rate = +this.bnGrossRate.times(this.bnAgencyCommission.div(100).negated().plus(1))
        this.net_cost = +this.bnNetRate.times(
          this.model === 'flat' ? this.bnImpressions : this.bnImpressions.div(1000),
        )
      } else if (this.agency_commission_model === 'cpm') {
        this.net_rate = +this.bnGrossRate.minus(this.bnImpressions)
        this.net_cost = +this.bnNetRate.times(
          this.model === 'flat' ? this.bnImpressions : this.bnImpressions.div(1000),
        )
      }
      return
    }

    if (this.agency_commission_model === 'none' || this.agency_commission == 0) {
      this.gross_cost = +this.bnNetCost
      this.gross_rate = +this.bnNetRate
    } else if (this.agency_commission_model === 'percentage') {
      this.gross_rate = +this.bnNetRate.div(this.bnAgencyCommission.div(100).negated().plus(1))
      this.gross_cost = +this.bnGrossRate.times(
        this.model === 'flat' ? this.bnImpressions : this.bnImpressions.div(1000),
      )
    } else if (this.agency_commission_model === 'cpm') {
      this.gross_rate = +this.bnNetRate.plus(this.bnAgencyCommission)
      this.gross_cost = +this.bnGrossRate.times(
        this.model === 'flat' ? this.bnImpressions : this.bnImpressions.div(1000),
      )
    }
  }

  public get formSpotImpressions() {
    return this.impressions / (this.total_spots > 0 ? this.total_spots : 1)
  }

  public set formSpotImpressions(impressions: number) {
    this.formImpressions = parseInt(`${impressions}`.replace(/[^0-9.]+/g, ''))
      * (this.total_spots > 0 ? this.total_spots : 1)
  }

  public get formImpressions() {
    return this.impressions
  }

  public set formImpressions(impressions: number) {
    this.impressions = parseInt(`${impressions}`.replace(/[^0-9.]+/g, ''))

    if (this.impressions == 0 && !this.open_ended) {
      this.net_cost = 0
      this.gross_cost = 0
      this.net_rate = 0
      return
    }

    if (this.isLinear) {
      // Linear
      if (this.gross_rate > 0) {
        this.gross_cost = +this.bnGrossRate.times(
          this.model === 'flat' ? this.bnImpressions : this.bnImpressions.div(1000),
        )
      } else if (this.gross_cost > 0) {
        this.gross_rate = +this.bnGrossCost.div(
          this.model === 'flat' ? this.bnImpressions : this.bnImpressions.div(1000),
        )
      }
    } else if (this.net_rate > 0) {
      // Non-linear
      this.net_cost = +this.bnNetRate.times(
        this.model === 'flat' ? this.bnImpressions : this.bnImpressions.div(1000),
      )
    } else if (this.net_cost > 0) {
      // Non-linear
      this.net_rate = +this.bnNetCost.div(
        this.model === 'flat' ? this.bnImpressions : this.bnImpressions.div(1000),
      )
    }
    this.updateCostAndRate()
  }

  public get formGrossCost() {
    return this.gross_cost
  }

  public set formGrossCost(gross_cost: number) {
    this.gross_cost = parseFloat(`${gross_cost}`.replace(/[^0-9.]+/g, ''))
    if (this.isLinear) {
      if (this.impressions > 0 && this.gross_cost == 0) {
        this.gross_rate = 0
        this.net_cost = 0
        this.net_rate = 0

        return
      }

      if (this.impressions == 0 && this.gross_cost == 0 && this.gross_rate == 0) {
        this.net_cost = 0
        this.net_rate = 0

        return
      }

      if (this.impressions > 0 && this.gross_cost > 0) {
        this.gross_rate = +this.bnGrossCost.div(
          this.model === 'flat' ? this.bnImpressions : this.bnImpressions.div(1000),
        )
      } else if (this.impressions == 0 && this.gross_rate > 0 && this.gross_cost > 0) {
        this.impressions = +this.bnGrossCost
          .div(this.gross_rate)
          .times(this.model === 'flat' ? 1 : 1000)
          .integerValue()
      }

      this.updateCostAndRate()
    }
  }

  public get formNetCost() {
    return this.net_cost
  }

  public set formNetCost(net_cost: number) {
    // if (this.net_cost == net_cost) return
    this.net_cost = parseFloat(`${net_cost}`.replace(/[^0-9.]+/g, ''))

    if (!this.isLinear) {
      if (this.impressions > 0 && this.net_cost == 0) {
        this.gross_rate = 0
        this.gross_cost = 0
        this.net_rate = 0
        return
      }

      if (this.impressions == 0 && this.net_cost == 0 && this.net_rate == 0) {
        this.gross_cost = 0
        this.gross_rate = 0

        return
      }

      if (this.impressions > 0 && this.net_cost > 0) {
        this.net_rate = +this.bnNetCost.div(
          this.model === 'flat' ? this.bnImpressions : this.bnImpressions.div(1000),
        )
      } else if (this.impressions == 0 && this.net_cost > 0 && this.net_rate > 0) {
        this.impressions = +this.bnNetCost
          .div(this.net_rate)
          .times(this.model === 'flat' ? 1 : 1000)
          .integerValue()
      }

      this.updateCostAndRate()
    }
  }

  public get formNetRate() {
    return this.net_rate
  }

  public set formNetRate(net_rate: number) {
    // if (this.net_rate == net_rate) return
    this.net_rate = parseFloat(`${net_rate}`.replace(/[^0-9.]+/g, ''))
    if (!this.isLinear) {
      if (this.impressions > 0 && this.net_rate == 0) {
        this.net_cost = 0
        this.gross_cost = 0
        this.gross_rate = 0

        return
      }

      if (this.impressions == 0 && this.net_cost == 0 && this.net_rate == 0) {
        this.gross_cost = 0
        this.gross_rate = 0

        return
      }

      if (this.impressions > 0 && this.net_rate > 0) {
        this.net_cost = +this.bnNetRate.times(
          this.model === 'flat' ? this.bnImpressions : this.bnImpressions.div(1000),
        )
      } else if (this.impressions == 0 && this.net_cost > 0 && this.net_rate > 0) {
        this.impressions = +this.bnNetCost
          .div(this.net_rate)
          .times(this.model === 'flat' ? 1 : 1000)
          .integerValue()
      }

      this.updateCostAndRate()
    }
  }

  public set formGrossRate(gross_rate: number) {
    // if (this.gross_rate == gross_rate) return

    this.gross_rate = parseFloat(`${gross_rate}`.replace(/[^0-9.]+/g, ''))
    if (this.isLinear) {
      if (this.impressions > 0 && this.gross_rate == 0) {
        this.net_cost = 0
        this.gross_cost = 0
        this.net_rate = 0

        return
      }

      if (this.impressions == 0 && this.gross_cost == 0 && this.gross_rate == 0) {
        this.net_cost = 0
        this.net_rate = 0

        return
      }

      if (this.impressions > 0 && this.gross_rate > 0) {
        this.gross_cost = +this.bnGrossRate.times(
          this.model === 'flat' ? this.bnImpressions : this.bnImpressions.div(1000),
        )
      } else if (this.impressions == 0 && this.gross_rate > 0 && this.gross_cost > 0) {
        this.impressions = +this.bnGrossCost
          .div(this.gross_rate)
          .times(this.model === 'flat' ? 1 : 1000)
          .integerValue()
      }

      this.updateCostAndRate()
    }
  }

  public get bnAgencyCommission() {
    return new BigNumber(this.agency_commission)
  }

  public get bnImpressions() {
    return new BigNumber(this.impressions)
  }

  public get bnNetRate() {
    return new BigNumber(this.net_rate)
  }

  public get bnNetCost() {
    return new BigNumber(this.net_cost)
  }

  public get bnGrossRate() {
    return new BigNumber(this.gross_rate)
  }

  public get bnGrossCost() {
    return new BigNumber(this.gross_cost)
  }

  public get formGrossRate() {
    return this.gross_rate
  }

  public get formStartAt() {
    return this.start_at
  }

  public set formStartAt(start_at: string) {
    this.start_at = start_at
    this.updateSpots()
  }

  public get formEndAt() {
    return this.end_at
  }

  public set formEndAt(end_at: string) {
    this.end_at = end_at
    this.updateSpots()
  }

  public get total_spots() {
    if (!this.metadata.spots) return [] // to prevent error
    return this.metadata.spots.reduce(
      (total: number, spot: number | string) =>
        total + (typeof spot === 'string' ? Number(spot) : spot),
      0,
    )
  }

  private updateSpots() {
    if (!this.metadata.spots) this.metadata.spots = [] // to prevent error
    if (this.start_at && this.end_at) {
      const start = moment(this.start_at)
      const end = moment(this.end_at)
      const spots = []
      const local_spots = _clone(this.metadata.spots)
      let count = 0
      if (start.isSame(end)) {
        const value = local_spots[count++] ?? 0
        spots.push(value)
      } else {
        while (start.isBefore(end)) {
          const value = local_spots[count++] ?? 0
          spots.push(value)
          start.add(7, 'days')
        }
      }
      this.metadata.spots = spots
    }
  }

  public get short_name() {
    const limit = 50
    if (!this.name) return 'Untitled Line Item'
    return this.name.length > limit ? `${this.name.substring(0, limit)}...` : this.name
  }

  public get isLinear() {
    return ['media_ocean', 'strata'].includes(this.type)
  }

  public setSpecialFeature(feature: Array<any>) {
    this.special_features = []
    if (!feature.length) return
    feature.forEach(f => {
      let index = this.special_features.findIndex((i: any) => i === f)
      if (index < 0) this.special_features.push(f)
    })
  }

  public setDynamicRate(dynamic_rate_id: null | string) {
    if (!dynamic_rate_id || dynamic_rate_id === 'null') {
      this.dynamic_rate_id = null
      this.dynamic_rate = null
      return
    }

    this._loading = true
    DynamicRate.find(dynamic_rate_id)
      .then((dynamic_rate: DynamicRate) => {
        this.dynamic_rate = dynamic_rate
        this.dynamic_rate_id = dynamic_rate.id
        if (!dynamic_rate.allow_overwrite || !this.formGrossRate) {
          this.formGrossRate = dynamic_rate.rate
        }
        this._loading = false
      })
      .catch(() => {
        this._loading = false
      })
  }

  constructor(source: any = {}) {
    super()

    Object.assign(this, source)

    return this
  }

  public async searchOptions(query: any) {
    const api = new Api(false)
    return api
      .get('media_plans/item/search/option', query)
      .then(response => SelectOption.toObjectList(response.data.result.options))
      .catch(() => [])
  }

  public toObject(source: any) {
    let instance = this.clone()

    if (source.media_plan) {
      instance.media_plan = MediaPlan.toObject(source.media_plan)
      delete source.media_plan
    }

    if (source.media_package) {
      instance.media_package = MediaPackage.toObject(source.media_package)
      delete source.media_package
    }

    if (!source.revenue_schedule) {
      delete source.revenue_schedule
      instance.revenue_schedule = {}
    }
    if (!source.billing_schedule) {
      delete source.billing_schedule
      instance.billing_schedule = {}
    }

    Object.assign(instance, source)

    if (source.start_at) {
      instance.start_at = source.start_at.includes('T')
        ? source.start_at.split('T')[0]
        : moment(source.start_at).format('YYYY-MM-DD')
    }

    if (source.end_at) {
      instance.end_at = source.end_at.includes('T')
        ? source.end_at.split('T')[0]
        : moment(source.end_at).format('YYYY-MM-DD')
    }

    if (!instance.special_features) {
      instance.special_features = []
    }

    const base = new MediaPlanItem()
    if (source.metadata) {
      instance.metadata = { ...base.metadata, ...source.metadata }
    } else {
      instance.metadata = { ...base.metadata }
    }

    return instance
  }

  public static pushToAdServer(payload: any) {
    let api = new Api()
    return api.post('push-to-adserver', payload)
  }

  public static batchPushToAdServer(payload: any) {
    let api = new Api()
    return api.post('media_plan_item/bulk-push-to-adserver', payload)
  }

  public static async getReportingNames(media_package_id: string) {
    let api = new Api()
    return api.get(`media_packages/reporting-names/${media_package_id}`)
  }
}
