import SystemtModule from '@/store/SystemModule'
import { groupBy as _groupBy } from 'lodash'
import moment from 'moment'
// @ts-ignore
import { Md5 } from 'ts-md5/dist/md5'
import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import invoice_home_table_fields from '@/pages/Invoice/home-table-fields'
import router from '@/Routes'
import Api from './Api'
import Company from './Company'
import Expense from './Expense'
// @ts-ignore
import ModelWithFiles from './interface/ModelWithFiles'
import PaginateOptions from './interface/PaginateOptions'
import InvoiceItem from './InvoiceItem'
import MediaPlan from './MediaPlan'
import ModelFile from './ModelFile'
import Payment from './Payment'
import Reconciliation from './Reconciliation'
import User from './User'
import WebMessage from './WebMessage'
import ReconciliationClient from './ReconciliationClient'

export default class Invoice extends ModelWithFiles {
  protected api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'invoice' as string | null,
      plural: 'invoices' as string | null,
    },
  }

  public id: null | string = null

  public client_id: null | string = null

  public sales_rep_id: null | string = null

  public sales_management_id: null | string = null

  public account_manager_id: null | string = null

  public media_plan_ids: string[] = []

  public number: number = 0

  public revision: number = 1

  public name: string = ''

  public client_po: null | string = null

  public type: string = 'default'

  public template_id: string = 'default'

  public payment_terms: string = 'net_30'

  public status: string = 'draft'

  public group_mode: string = 'media_plan'

  public discount: number = 0

  public discount_mode: string = 'percent'

  public agency_fee: number = 0

  public tax_percentage: number = 0

  public pre_paid: number = 0

  public products: string[] = []

  public due_at: null | string = moment().add(1, 'month').endOf('month').format('YYYY-MM-DD')

  public external_id: null | string = null

  public created_at: string = moment().endOf('month').format('YYYY-MM-DD')

  public updated_at: string = ''

  public paid_at: null | string = null

  public terms: null | string = null

  public terms_id: null | string = null

  public notes: null | string = null

  public private_notes: null | string = null

  public review_status: string = 'pending'

  public review_notes: null | string = null

  public error_notes: null | string = null

  public reviewer_id: null | string = null

  public reviewed_at: null | string = null

  public conflict: boolean = false

  public get broadcast_month() {
    return this.metadata.header.broadcast_month
  }

  public set broadcast_month(value: string) {
    this.metadata.header.broadcast_month = value
  }

  public metadata: any = {
    view_columns: ['model', 'net_rate', 'net_cost'],
    invoice_mode: 'email',
    billing_custom: false,
    custom_billing: {
      country: 'US',
      state: '',
      city: '',
      zipcode: '',
      billing_email: [],
      billing_phone: '',
      address_line_1: '',
      address_line_2: '',
      address_line_3: '',
      address_line_4: '',
    },
    client_data: {
      name: '',
      order: '',
      product: '',
      product_estimate: '',
      estimate: '',
    },
    agency: {
      id: '',
      name: '',
      address_line_1: '',
      address_line_2: '',
      address_line_3: '',
      address_line_4: '',
    },
    station: {
      call_letters: '',
      media_type: 'TV',
      band: 'DV',
      name: 'Cast Iron Media',
      address_line_1: '100 S. Main St. Suite #200',
      address_line_2: 'Doylestown, PA 18901',
      address_line_3: '',
      address_line_4: '',
      compuer_system: '',
      gst_registration_number: '',
      qst_registration_number: '',
    },
    payee: {
      name: 'Cast Iron Media',
      address_line_1: '100 S. Main St. Suite #200',
      address_line_2: 'Doylestown, PA 18901',
      address_line_3: '',
      address_line_4: '',
    },
    header: {
      representative: '',
      sales_person: '',
      advertiser_name: '',
      product_name: '',
      date: '',
      order_type: 'Cash',
      agency_estimate_code: '',
      number: '',
      broadcast_month: '2023-10',
      start_date: '',
      end_date: '',
      schedule_start_date: '',
      schedule_end_date: '',
      contract_start_date: '',
      contract_end_date: '',
      billing_insctructions: '',
      rate_card_number: '',
      agency_comission_flag: '',
      sales_tax: '',
      audience_percent: '',
      rep_order_number: '',
      station_order_number: '',
      station_trade_order_number: '',
      station_advertiser_code: '',
      agency_advertiser_code: '',
      station_product_code: '',
      agency_product_code: '',
      station_contact: '',
      agency_contact: '',
      due_date: '',
      network: '',
      trading_partner_code: '',
      deal_number: '',
      rep_id: '',
      package_code: '',
      reference_invoice_number: '',
      reference_invoice_code: '',
      invoice_version_code: '',
      national_local_code: '',
      special_paying_rep_code: '',
    },
  }

  // Relationships
  public items: InvoiceItem[] = []

  public payments: Payment[] = []

  public expenses: Expense[] = []

  public client: Company | null = null

  public station: Company | null = null

  public sales_rep: User | null = null

  public sales_management: User | null = null

  public reviewer: User | null = null

  public files: ModelFile[] = []

  public media_plans: MediaPlan[] = []

  // Dynamic properties
  public get formPaymentTerms(): string {
    return this.payment_terms
  }

  public set formPaymentTerms(paymentTerms: string) {
    if (this.payment_terms !== paymentTerms) {
      this.payment_terms = paymentTerms
      this.due_at = moment(this.created_at)
        .add(this.payment_terms.split('_')[1], 'days')
        .format('YYYY-MM-DD')
    }
  }

  // Computed properties
  public get billing_period() {
    let date = this.created_at
    let start_at = moment(date).startOf('month')
    let end_at = start_at.clone().endOf('month')

    if (!this.isLinear) {
      return {
        start_at: start_at.format('YYYY-MM-DD'),
        end_at: end_at.format('YYYY-MM-DD'),
      }
    }

    if (this.metadata.header.broadcast_month.length) {
      date = this.metadata.header.broadcast_month
    }

    while (start_at.format('dddd').toLocaleLowerCase() !== 'monday') {
      start_at = start_at.subtract(1, 'day')
    }

    while (end_at.format('dddd').toLocaleLowerCase() !== 'sunday') {
      end_at = end_at.subtract(1, 'day')
    }

    return {
      start_at: start_at.format('YYYY-MM-DD'),
      end_at: end_at.format('YYYY-MM-DD'),
    }
  }

  public get isLinear() {
    return ['media_ocean', 'strata'].includes(this.type)
  }

  public get discount_value(): number {
    if (this.discount_mode === 'percent') {
      return this.net_subtotal * (this.discount / 100)
    }
    return this.discount
  }

  public get tax(): number {
    if (this.discount_value === 0) return 0
    return (this.net_subtotal - this.discount_value) * (this.tax_percentage / 100)
  }

  public get gross_rate(): number {
    return this.gross_subtotal / (this.all_impressions / 1000)
  }

  public get gross_subtotal(): number {
    return this.items.reduce((acc, item) => acc + item.gross_total, 0)
  }

  public get cost_total(): number {
    return this.items.reduce((acc, item) => acc + item.cost_total, 0)
  }

  public get expense_total(): number {
    return this.items.reduce((acc, item) => acc + item.expense_total, 0)
  }

  public get itemsView() {
    if (this.group_mode === 'single' && this.items.length > 1) {
      const models: string[] = []
      this.items.forEach(i => {
        if (!models.includes(i.model)) {
          models.push(i.model)
        }
      })

      const products: string[] = []
      this.items.forEach(i => {
        if (!products.includes(i.product)) {
          products.push(i.product)
        }
      })

      const item = {
        name: this.items[0].name,
        number: this.items[0].number,
        metadata: this.items[0].metadata,
        type: this.items[0].type,
        booked: this.items.reduce((acc, item) => acc + item.booked, 0),
        delivered: this.items.reduce((acc, item) => acc + item.delivered, 0),
        billable: this.items.reduce((acc, item) => acc + item.billable, 0),
        model: models.length === 1 ? models[0] : 'mixed',
        product: products.length === 1 ? products[0] : products.join(', '),
        gross_rate: this.gross_rate,
        net_rate: this.net_rate,
        gross_total: this.gross_subtotal,
        net_total: this.net_subtotal,
        cost_total: this.cost_total,
        expense_total: this.expense_total,
        flight_dates: this.items[0].flight_dates,
        reconciliation_ids: this.items.reduce(
          (acc: string[], item) => [...acc, ...item.reconciliation_ids],
          [],
        ),
      }

      return [item]
    }
    return this.items
  }

  public get trade_gross_subtotal(): number {
    const numFix = (n: any) => (isNaN(n) ? 0 : n)
    return this.items
      .filter(i => i.metadata.order_type === 'trade')
      .reduce((acc, item) => acc + numFix(item.gross_total), 0)
  }

  public get cash_gross_subtotal(): number {
    const numFix = (n: any) => (isNaN(n) ? 0 : n)
    return this.items
      .filter(i => i.metadata.order_type === 'cash')
      .reduce((acc, item) => acc + numFix(item.gross_total), 0)
  }

  public get net_rate(): number {
    return this.net_subtotal / (this.all_impressions / 1000)
  }

  public get all_impressions(): number {
    const numFix = (n: any) => (isNaN(n) ? 0 : n)
    return this.items.reduce((acc, item) => acc + numFix(item.booked), 0)
  }

  public get net_subtotal(): number {
    const numFix = (n: any) => (isNaN(n) ? 0 : n)
    return this.items.reduce((acc, item) => acc + numFix(item.net_total), 0)
  }

  public get trade_net_subtotal(): number {
    const numFix = (n: any) => (isNaN(n) ? 0 : n)
    return this.items
      .filter(i => i.metadata.order_type === 'trade')
      .reduce((acc, item) => acc + numFix(item.net_total), 0)
  }

  public get cash_net_subtotal(): number {
    const numFix = (n: any) => (isNaN(n) ? 0 : n)
    return this.items
      .filter(i => i.metadata.order_type === 'cash')
      .reduce((acc, item) => acc + numFix(item.net_total), 0)
  }

  public get total(): number {
    return this.net_subtotal - this.discount_value - this.agency_fee + this.tax
  }

  public get full_agency_fee(): number {
    return this.agency_fee + (this.gross_subtotal - this.net_subtotal)
  }

  public get paid(): number {
    return (
      this.pre_paid
      + this.payments.reduce(
        // @ts-ignore
        (acc, payment) => acc + (payment.status === 'confirmed' ? payment.amount : 0),
        0,
      )
    )
  }

  public get due(): number {
    let ret = this.total - this.paid
    return ret < 0.01 ? 0 : ret
  }

  public get reconciliation_ids() {
    return this.items.reduce((acc: string[], item) => [...acc, ...item.reconciliation_ids], [])
  }

  public get invoice_number_revised() {
    let rev = ''
    if (this.revision > 1) {
      rev = `-${this.revision}`
    } else if (this.revision == 0) {
      rev = '-1'
    }
    return `${this.number}${rev}`
  }

  public get invoice_number_revised_trade() {
    let rev = ''
    if (this.revision > 1) {
      rev = `-${this.revision}`
    }
    return `${this.number}T${rev}`
  }

  public get has_cash_items() {
    return this.items.some(item => item.metadata.order_type === 'cash')
  }

  public get has_trade_items() {
    return this.items.some(item => item.metadata.order_type === 'trade')
  }

  public get total_spots(): number {
    return this.items.reduce((acc, item) => acc + item.metadata.spots, 0)
  }

  // Functions
  /**
   * Generate invoice items from a Reconciliation list
   * @param reconciliations
   * @returns
   *
   * @description This is used when creating an invoice from a Reconciliation list, or when an invoice receives a new reconciliation list.
   */
  public rebuildItems(reconciliations: Reconciliation[], force: boolean = false): void {
    // Compare reconciliations ids with existing items ids, if equal then don't add the item
    const reconciliationsIds = reconciliations.map(reconciliation => reconciliation.id)
    const result = reconciliationsIds.filter(id => !this.reconciliation_ids.includes(id))
    const media_plan_ids = [...new Set(reconciliations.map(r => r.media_plan_id))]

    if (!force && result.length === 0) return

    // Set Media Ocean metadata
    let target_plan = reconciliations[0].media_plan

    if (target_plan) {
      this.metadata.view_columns = target_plan.metadata.view_columns
      this.template_id = target_plan.metadata.invoice.template_id ?? 'default'
      this.metadata.client_data = target_plan.metadata.client_data

      if (target_plan.isLinear) {
        // Copy Agency Info
        this.metadata.agency.id = target_plan.metadata.agency.id
        this.metadata.agency.name = target_plan.metadata.agency.name
        this.metadata.agency.address_line_1 = target_plan.metadata.agency.address_line_1
        this.metadata.agency.address_line_2 = target_plan.metadata.agency.address_line_2
        this.metadata.agency.address_line_3 = target_plan.metadata.agency.address_line_3
        this.metadata.agency.address_line_4 = target_plan.metadata.agency.address_line_4

        // Copy Station Info
        this.metadata.station.call_letters = target_plan.metadata.station.call_letters

        // Copy Header Info
        this.metadata.header.representative = target_plan.metadata.header.representative
        this.metadata.header.advertiser_name = target_plan.metadata.header.advertiser_name
        this.metadata.header.product_name = target_plan.metadata.header.product_name
        this.metadata.header.agency_estimate_code = target_plan.metadata.header.agency_estimate_code
        this.metadata.header.start_date = target_plan.metadata.header.start_date
        this.metadata.header.end_date = target_plan.metadata.header.end_date
        this.metadata.header.station_order_number = target_plan.metadata.header.station_order_number
        this.metadata.header.station_trade_order_number = target_plan.metadata.header.station_trade_order_number
        this.metadata.header.agency_advertiser_code = target_plan.metadata.header.agency_advertiser_code
        this.metadata.header.agency_product_code = target_plan.metadata.header.agency_product_code
        this.metadata.header.broadcast_month = moment(reconciliations[0].billing_month).format(
          'YYYY-MM-DD',
        )

        this.metadata.invoice_mode = target_plan.metadata.invoice.delivery_driver
          ?? (target_plan.isLinear ? 'platform' : 'email')
        // this.metadata.billing_custom = target_plan.metadata.invoice.billing_custom ?? false
        // if (this.metadata.billing_custom) {
        //   this.metadata.custom_billing = target_plan.metadata.invoice.billing_info
        // }
        this.metadata.header.sales_person = target_plan.sales_rep ? target_plan.sales_rep.name : ''

        this.metadata.header.trading_partner_code = target_plan.metadata.header.trading_partner_code
        this.metadata.header.rep_id = target_plan.metadata.header.rep_id
        this.metadata.header.special_paying_rep_code = target_plan.metadata.header.special_paying_rep_code
      }

      this.metadata.billing_custom = target_plan.metadata.invoice.billing_custom ?? false
      if (this.metadata.billing_custom) {
        this.metadata.custom_billing = target_plan.metadata.invoice.billing_info
      }
    }

    let automated_items = this.items.filter(item => item.type !== 'custom')
    let item: InvoiceItem | null | undefined = null
    let groups: any = []

    const custom_items = reconciliations.filter(r => r.type === 'custom')
    const items = reconciliations.filter(r => r.type !== 'custom')
    const customToGroup = () => custom_items.forEach(m => (groups[m.id] = [m]))

    if (this.group_mode === 'single') {
      // Merge all reconciliations in a single item
      // groups.singleItem = items
      groups = _groupBy(items, 'invoiceSingleKey')
      customToGroup()
    } else {
      const objKey = `${this.group_mode}_id`
      groups = _groupBy(items, objKey)
      customToGroup()
    }
    const group_count = Object.keys(groups).length
    let key = ''

    for (key in groups) {
      let group_item = groups[key][0]
      if (automated_items.length > 0) {
        item = automated_items.shift()
      }

      if (!item) {
        item = new InvoiceItem()
        if (group_item.type === 'custom') {
          this.items.unshift(item)
        } else {
          this.items.push(item)
        }
        item.name = ''
        item.id = group_item.id
      }

      if (this.group_mode === 'media_plan') {
        item.name = group_item.media_plan?.name ?? ''
      } else if (this.group_mode === 'media_plan_item') {
        if (media_plan_ids && media_plan_ids.length > 1) {
          item.name = item.metadata.invoice_name
            ?? `${group_item.media_plan?.name ?? ''}: ${group_item.media_plan_item?.name ?? ''}`
        } else {
          item.name = item.metadata.invoice_name ?? `${group_item.media_plan_item?.name ?? ''}`
        }
      } else if (this.group_mode === 'single' && group_count > 1) {
        item.name = group_item.media_plan?.name ?? ''
      }

      if (group_item.type === 'custom') {
        item.type = 'custom'
      } else {
        item.fromReconciliations(groups[key])
      }

      // Reset item pointer
      item = null
    }
    // Clean up any remaining items
    let orphan_items = automated_items.map(item => item.number)
    this.items = this.items.filter(
      item => item.type !== 'reconciliation' || !orphan_items.includes(item.number),
    )

    this.resetNumbers()
  }

  /**
   * Reset the invoice item numbers
   *
   * @returns
   *
   * @description This is used when rebuilding the items from a Reconciliation list
   * because the numbers are not sequential anymore
   */
  public resetNumbers() {
    const items = this.items

    let count = 1
    items.forEach(i => {
      i.number = count++
    })
    this.items = items
  }

  public download(files: string[] = ['pdf'], order_type: string = 'all'): Promise<any> {
    if (this.id) return Invoice.download([this.id], files, order_type)

    WebMessage.error('You must save the invoice before exporting it')
    return Promise.reject(new Error('Invoice not saved'))
  }

  public static download(
    invoices: string[],
    files: string[] = ['pdf'],
    order_type: string = 'all',
  ): Promise<any> {
    const api = new Api()
    const instance_id = getModule(SystemtModule)._uuid

    return api.post('invoices/export', {
      invoices,
      files,
      order_type,
      instance_id,
    })
  }

  public send(order_type: string = 'all'): Promise<any> {
    if (this.id) return Invoice.send([this.id], order_type)

    WebMessage.error('You must save the invoice before sending it')
    return Promise.reject(new Error('Invoice not saved'))
  }

  public static send(
    invoices: string[],
    order_type: string = 'all',
    fake_send: boolean = false,
  ): Promise<any> {
    const api = new Api()

    return api.post('invoices/send', {
      invoices,
      order_type,
      fake_send,
    })
  }

  public requestApproval(): Promise<any> {
    if (this.id) return Invoice.requestApproval([this.id])

    WebMessage.error('You must save the invoice before sending it')
    return Promise.reject(new Error('Invoice not saved'))
  }

  public static requestApproval(invoices: string[]): Promise<any> {
    const api = new Api()

    return api.post('invoices/request_approval', {
      invoices,
    })
  }

  public review(): Promise<any> {
    return Invoice.review([this])
  }

  public static review(invoices: Invoice[]): Promise<any> {
    const api = new Api()

    return api
      .post('invoices/review', {
        invoices: invoices.map(i => ({
          id: i.id,
          review_status: i.review_status,
          review_notes: i.review_notes,
        })),
      })
      .then(data => {
        WebMessage.success('Invoice review submitted')
        return data
      })
      .catch(error => Invoice.onError)
  }

  public static async loadPendingReviews(options: PaginateOptions): Promise<any> {
    const api = new Api()

    return api
      .get('invoices/invoice_reviews', options)
      .then(response => {
        // @ts-ignore
        const data = this.toObjectList(response.data.result.invoices)
        return {
          records: response.data.result.records,
          statusList: response.data.result.statusList,
          net_total_pending: response.data.result.net_subtotal,
          data,
        }
      })
      .catch(() => ({
        records: 0,
        data: [],
      }))
  }

  public cancel(): Promise<any> {
    const api = new Api()

    return api.post(`/invoices/${this.id}/cancel`)
  }

  public static toObjectList(source: any) {
    // @ts-ignore
    return source.map((item: Invoice) => this.toObject(item))
  }

  public toObjectList(source: Invoice) {
    return Invoice.toObjectList(source)
  }

  public toObject(source: any) {
    // @ts-ignore
    let instance = this.clone()

    // Remove dynamic data
    if (source.sub_total !== undefined) delete source.sub_total
    if (source.total !== undefined) delete source.total
    if (source.tax !== undefined) delete source.tax
    if (source.paid !== undefined) delete source.paid
    if (source.due !== undefined) delete source.due
    if (source.gross_subtotal !== undefined) delete source.gross_subtotal
    if (source.net_subtotal !== undefined) delete source.net_subtotal
    if (source.cost_total !== undefined) delete source.cost_total
    if (source.expense_total !== undefined) delete source.expense_total

    Object.assign(instance, source)

    if (source.client) {
      instance.client = Company.toObject(source.client)
    }

    if (source.station) {
      instance.station = Company.toObject(source.station)
    }

    if (source.sales_rep) {
      instance.sales_rep = User.toObject(source.sales_rep)
    }

    if (source.sales_management) {
      instance.sales_management = User.toObject(source.sales_management)
    }

    if (source.reviewer) {
      instance.reviewer = User.toObject(source.reviewer)
    }

    if (source.items) {
      instance.items = InvoiceItem.toObjectList(source.items)
    }

    if (source.payments) {
      instance.payments = Payment.toObjectList(source.payments)
    }

    if (source.expenses) {
      // @ts-ignore
      instance.expenses = Expense.toObjectList(source.expenses)
    }

    if (source.files) {
      instance.files = ModelFile.toObjectList(source.files)
    }

    if (source.media_plans) {
      instance.media_plans = MediaPlan.toObjectList(source.media_plans)
    }

    const base = new Invoice()
    if (source.metadata) {
      instance.metadata = { ...base.metadata, ...source.metadata }
    } else {
      instance.metadata = { ...base.metadata }
    }

    instance.updateChecksum()

    return instance
  }

  /**
   * Fetch batch invoiceble reconciliation items.
   *
   * @returns Promise<Reconciliation[]>
   */
  public static getInvoicesOverview(): Promise<Reconciliation[]> {
    const api = new Api()

    return api
      .get('invoices/overview')
      .then((response: any) => response.data.result)
      .catch(Invoice.onError)
  }

  public findInvoiceWithReconciliation(id: string): Promise<Invoice> {
    const api = new Api()

    return api
      .get(`invoice/${id}?with_reconciliation=true`)
      .then((response: any) => this.toObject(response.data.result.invoice))
      .catch(Invoice.onError)
  }

  public static findInvoiceWithReconciliation(id: string): Promise<Invoice> {
    return new this().findInvoiceWithReconciliation(id)
  }

  private static onError(error: any) {
    return error
  }

  protected static onSave(response: any) {
    return response
  }

  public get apiData(): any {
    return {
      client_id: this.client_id,
      sales_rep_id: this.sales_rep_id,
      sales_management_id: this.sales_management_id,
      account_manager_id: this.account_manager_id,
      media_plan_ids: this.media_plan_ids,
      broadcast_month: this.broadcast_month,
      number: this.number,
      revision: this.revision,
      name: this.name,
      client_po: this.client_po,
      type: this.type,
      template_id: this.template_id,
      payment_terms: this.payment_terms,
      status: this.status,
      due_at: this.due_at,
      group_mode: this.group_mode,
      discount_mode: this.discount_mode,
      discount: this.discount,
      agency_fee: this.agency_fee,
      tax_percentage: this.tax_percentage,
      pre_paid: this.pre_paid,
      created_at: this.created_at,
      terms: this.terms,
      terms_id: this.terms_id,
      notes: this.notes,
      private_notes: this.private_notes,
      metadata: this.metadata,
      items: this.items.map(item => item.apiData),
    }
  }

  public static batchCreate(
    invoices: any,
    request_approval: boolean = false,
    send_reminder: boolean = false,
  ): Promise<any> {
    const instance_id = getModule(SystemtModule)._uuid
    const api = new Api()
    return api
      .post('invoices/create_batch', {
        invoices,
        request_approval,
        send_reminder,
        instance_id,
      })
      .then((response: any) => {
        Invoice.onSave(response)
        return response
      })
      .catch((error: any) => {
        Invoice.onError(error)
        return error
      })
  }

  public static setGroups(filtred_clients: any) {
    // regroup by media plan
    let acc: any = []
    filtred_clients.forEach((f: any, index: number) => {
      if (f.invoice_group === 'single') {
        let media_plan_ids = f.items.map((i: any) => i.media_plan_id)
        media_plan_ids = [...new Set(media_plan_ids)]
        acc.push({
          ...filtred_clients[index],
          group_by: f.group_by,
          items: f.items,
          media_plan_ids,
        })
      } else if (f.invoice_group === 'media_plan') {
        let group = _groupBy(f.items, 'media_plan_id')
        let key = ''
        for (key in group) {
          let grouped = group[key]
          acc.push({
            ...filtred_clients[index],
            group_by: f.group_by,
            items: grouped,
            media_plan_ids: [key],
          })
        }
      } else if (f.invoice_group === 'advertiser') {
        let group = _groupBy(f.items, 'advertiser_id')
        let key = ''
        for (key in group) {
          let grouped = group[key]
          acc.push({
            ...filtred_clients[index],
            group_by: f.group_by,
            items: grouped,
            media_plan_ids: [key],
          })
        }
      } else {
        let group = _groupBy(f.items, 'media_plan_id')
        acc.push({ ...f, media_plan_ids: Object.keys(group) })
      }
    })
    return acc
  }

  public _hash: string | Int32Array = ''

  // Check if object has changed
  public get is_dirty(): boolean {
    return this._hash !== this.calculateChecksum()
  }

  private calculateChecksum(): string | Int32Array {
    return Md5.hashStr(JSON.stringify({ ...this.apiData, review_status: this.review_status }))
  }

  public updateChecksum() {
    this._hash = this.calculateChecksum()
  }

  public static invoiceTableFields() {
    let base_fields: Array<any> = invoice_home_table_fields

    let index = base_fields.findIndex(f => f.key === 'action')

    base_fields[index] = {
      key: 'action',
      label: '',
      sortable: false,
      show: true,
      type: 'action_list',
      actions: [
        {
          icon: 'pencil',
          title: 'Edit',
          event: 'edit',
          action: (invoice: Invoice) => {
            router.push({
              name: 'Invoice',
              params: { id: invoice.id! },
              query: { ref: 'MyDashboard' },
            })
            // window.open(`invoice/${invoice.id}?ref=MyDashboard`)
          },
        },
      ],
    }
    return base_fields
  }

  public async rebuildInvoice(
    invoice_group: string | null = null,
    invoice_detail: string | null = null,
  ) {
    let data = await Reconciliation.batchPaginate({
      page_size: 'all',
      page: 1,
      order_by: 'created_at',
      order: 'desc',
      invoice_id: this.id,
    }).then((result: any) => {
      let reconciliation_clients = result.data.map((clientGroup: any) => {
        if (clientGroup.type === 'default') {
          clientGroup.items = clientGroup.items.map((i: any) => {
            if (invoice_group) {
              // @ts-ignore
              i.invoice_group = invoice_group
            } else if (clientGroup.client?.billing_info.hasOwnProperty('invoice_group')) {
              // @ts-ignore
              i.invoice_group = clientGroup.client?.billing_info.invoice_group
            }
            if (invoice_detail) {
              // @ts-ignore
              i.group_by = invoice_detail
            } else if (clientGroup.client?.billing_info.hasOwnProperty('invoice_detail')) {
              // @ts-ignore
              i.group_by = clientGroup.client?.billing_info.invoice_detail
            }
            return i
          })
        }

        clientGroup.invoice_count.advertiser = clientGroup.items.reduce(
          (total: any, item: any) => {
            if (item.advertiser_id && !total.set.includes(item.advertiser_id)) {
              total.set.push(item.advertiser_id)
              total.count++
            }
            return total
          },
          { set: [] as string[], count: 0 },
        ).count

        clientGroup.invoice_count.media_plan = clientGroup.items.reduce(
          (total: any, item: any) => {
            if (item.media_plan_id && !total.set.includes(item.media_plan_id)) {
              total.set.push(item.media_plan_id)
              total.count++
            }
            return total
          },
          { set: [] as string[], count: 0 },
        ).count
        return clientGroup
      })

      return reconciliation_clients
    })

    if (invoice_detail) {
      // @ts-ignore
      data = data.map(r => {
        r.group_by = invoice_detail
        r.invoice_group = invoice_group
        return r
      })
    }

    return Invoice.buildInvoice(Invoice.setGroups(data)[0], true)
  }

  public static buildInvoice(data: any, preview: boolean = false) {
    let invoice = new Invoice()
    invoice.media_plan_ids = data.media_plan_ids

    let name = `${data.client!.name} - ${moment(data.items[0].billing_month).format('MMM, YYYY')}`

    if (invoice.media_plan_ids.length === 1) {
      name = `${data.items[0].media_plan.name} - ${moment(data.items[0].billing_month).format(
        'MMM, YYYY',
      )}`
    }

    invoice.name = name
    invoice.created_at = moment(data.items[0].billing_month).endOf('month').format('YYYY-MM-DD')
    invoice.due_at = data.due_at

    invoice.client_id = data.client_id
    invoice.sales_rep_id = data.items[0].media_plan ? data.items[0].media_plan.sales_rep_id : null
    invoice.sales_management_id = data.items[0].media_plan
      ? data.items[0].media_plan.sales_management_id
      : null
    invoice.account_manager_id = data.items[0].media_plan
      ? data.items[0].media_plan.account_manager_id
      : null
    invoice.type = data.items[0].media_plan.type
    invoice.group_mode = data.group_by
    invoice.payment_terms = data.items[0].media_plan
      ? data.items[0].media_plan.metadata.invoice_terms ?? 'net_30'
      : 'net_30'
    invoice.metadata.header.broadcast_month = moment(data.items[0].billing_month).format(
      'YYYY-MM-DD',
    )

    invoice.rebuildItems(data.items)

    if (data.group_by === 'single' && invoice.items.length === 1) {
      let target = invoice.items.find(item => item.type !== 'custom')
      if (target) {
        target.name = data.group_by_name
      }
    }

    if (data.client) {
      if (data.client.billing_info.invoice_template) {
        invoice.template_id = data.client.billing_info.invoice_template
      }
      if (data.client.billing_info.invoice_delivery_driver) {
        invoice.metadata.invoice_mode = data.client.billing_info.invoice_delivery_driver
      }
    }

    invoice.updateChecksum()

    if (preview) {
      let included_ids: string[] = []

      data.items.forEach((item: any) => {
        if (item.media_plan_item_id && !included_ids.includes(item.media_plan_item_id)) {
          included_ids.push(item.media_plan_item_id)
          invoice.media_plans.push(item.media_plan)
          if (!invoice.sales_rep && item.media_plan.sales_rep) {
            invoice.sales_rep = item.media_plan.sales_rep
          }
          if (!invoice.sales_management && item.media_plan.sales_management) {
            invoice.sales_management = item.media_plan.sales_management
          }
        }
      })

      invoice.client = data.client

      return invoice
    }

    return invoice.apiData
  }

  public getHistory(options: PaginateOptions) {
    const api = new Api()
    return api.get(`invoices/${this.id}/history`, options)
  }

  public static recalculateAgencyCommission(invoice_ids: string[]) {
    const api = new Api()
    return api.post('invoices/recalculate_comission', { invoice_ids }).then((response: any) => {
      Invoice.onSave(response)
      return response
    })
  }

  public get isBooksClosed() {
    return Invoice.isBooksClosedFor(this.created_at)
  }

  public static isBooksClosedFor(target: string) {
    const system = getModule(SystemtModule)

    if (
      system.env_vars.finance
      && system.env_vars.finance.accounting
      && system.env_vars.finance.accounting.closed_books_date
    ) {
      return moment(system.env_vars.finance.accounting.closed_books_date)
        .endOf('day')
        .isSameOrAfter(moment(target))
    }

    return false
  }

  public static closeBooks(date: string) {
    const api = new Api()
    return api.post('invoices/close-books', { date }).then((response: any) => {
      Invoice.onSave(response)

      const system = getModule(SystemtModule)
      let env_vars = system.env_vars
      if (!env_vars.finance) {
        env_vars.finance = {
          accounting: {
            closed_books_date: date,
          },
        }
      } else if (!env_vars.finance.accounting) {
        env_vars.finance.accounting = { closed_books_date: date }
      } else {
        env_vars.finance.accounting.closed_books_date = date
      }

      system.updateState({
        name: 'env_vars',
        data: env_vars,
      })

      return response
    })
  }

  public static buildQuery(payload: any) {
    let api = new Api()
    return api.post('invoices/report', payload)
  }

  public static tableFields: any = Invoice.invoiceTableFields()
}
