/** @format */

import store from '@/store'
import Api from '@/models/Api'
import { getModule } from 'vuex-module-decorators'
import SelectOption from '@/models/interface/SelectOption'
import PublisherModule from '@/store/model/PublisherModule'
import ApiParameters from '@/models/interface/ApiParameters'
import InsightsSettings from '@/models/InsightsSettings'
import PaginateOptions from './interface/PaginateOptions'
import defaultPermissions from './DefaultPermissions'
import WebMessage from './WebMessage'
import FeatureAccess from './FeatureAccess'

export default class Publisher {
  public name: string = ''

  public static toObject(data: any, cache: boolean = true): Publisher {
    const publisher = new Publisher()

    publisher.name = data.name
    return publisher
  }

  public static toObjectList(data: any, cache: boolean = true): Publisher[] {
    const publishers = new Array<Publisher>()
    data.forEach((value: any) => {
      publishers.push(Publisher.toObject(value, false))
    })

    //  Cache Object List
    if (cache) Publisher.module.update(publishers)

    return publishers
  }

  public toOption(): SelectOption {
    return new SelectOption(this.name, this.name)
  }

  // State Management
  public static get module(): PublisherModule {
    if (!store.hasModule('publisher')) {
      store.registerModule('publisher', PublisherModule)
    }

    return getModule(PublisherModule)
  }

  public static find(name: string): Publisher | null {
    const o = Publisher.module.data.find(publisher => publisher.name === name)
    return o instanceof Publisher ? o : null
  }

  public static filter(filter: any): Publisher[] {
    if (Array.isArray(filter)) {
      return Publisher.module.data.filter(
        publisher => publisher.name && filter.includes(publisher.name),
      )
    }
    return Publisher.module.data.filter(filter)
  }

  public static async get(name: string): Promise<Publisher | null> {
    return Publisher.module.find(name)
  }

  public static async paginate(options: PaginateOptions) {
    return Publisher.module.paginate(options)
  }
}
