export default [
  {
    key: 'month',
    label: 'Month',
    sortable: true,
    show: true,
    type: 'month',
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'publisher',
    label: 'Publisher',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'adunit',
    label: 'Adunit',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'gam_id',
    label: 'Gam Id',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'impressions',
    label: 'Impressions',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
    type: 'number',
  },
  {
    key: 'rate',
    label: 'Rate',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
    type: 'currency',
  },
  {
    key: 'features',
    label: 'Features',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'key_values',
    label: 'Key Values',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'cost',
    label: 'Cost',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
    type: 'currency',
  },
]
