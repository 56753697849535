export default [
  { name: 'Abilene-Sweetwater (662)', value: 662 },
  { name: 'Albany, GA (525)', value: 525 },
  { name: 'Albany-Schenectady-Troy (532)', value: 532 },
  { name: 'Albuquerque-Santa Fe (790)', value: 790 },
  { name: 'Alexandria, LA (644)', value: 644 },
  { name: 'Alpena (583)', value: 583 },
  { name: 'Amarillo (634)', value: 634 },
  { name: 'Anchorage (743)', value: 743 },
  { name: 'Atlanta (524)', value: 524 },
  { name: 'Augusta-Aiken (520)', value: 520 },
  { name: 'Austin (635)', value: 635 },
  { name: 'Bakersfield (800)', value: 800 },
  { name: 'Baltimore (512)', value: 512 },
  { name: 'Bangor (537)', value: 537 },
  { name: 'Baton Rouge (716)', value: 716 },
  { name: 'Beaumont-Port Arthur (692)', value: 692 },
  { name: 'Bend, OR (821)', value: 821 },
  { name: 'Billings (756)', value: 756 },
  { name: 'Biloxi-Gulfport (746)', value: 746 },
  { name: 'Binghamton (502)', value: 502 },
  { name: 'Birmingham (Ann and Tusc) (630)', value: 630 },
  { name: 'Bluefield-Beckley-Oak Hill (559)', value: 559 },
  { name: 'Boise (757)', value: 757 },
  { name: 'Boston (Manchester) (506)', value: 506 },
  { name: 'Bowling Green (736)', value: 736 },
  { name: 'Buffalo (514)', value: 514 },
  { name: 'Burlington-Plattsburgh (523)', value: 523 },
  { name: 'Butte-Bozeman (754)', value: 754 },
  { name: 'Casper-Riverton (767)', value: 767 },
  { name: 'Cedar Rapids-Wtrlo-IWC&Dub (637)', value: 637 },
  { name: 'Champaign&Sprngfld-Decatur (648)', value: 648 },
  { name: 'Charleston, SC (519)', value: 519 },
  { name: 'Charleston-Huntington (564)', value: 564 },
  { name: 'Charlotte (517)', value: 517 },
  { name: 'Charlottesville (584)', value: 584 },
  { name: 'Chattanooga (575)', value: 575 },
  { name: 'Cheyenne-Scottsbluff (759)', value: 759 },
  { name: 'Chicago (602)', value: 602 },
  { name: 'Chico-Redding (868)', value: 868 },
  { name: 'Cincinnati (515)', value: 515 },
  { name: 'Clarksburg-Weston (598)', value: 598 },
  { name: 'Cleveland-Akron (Canton) (510)', value: 510 },
  { name: 'Colorado Springs-Pueblo (752)', value: 752 },
  { name: 'Columbia, SC (546)', value: 546 },
  { name: 'Columbia-Jefferson City (604)', value: 604 },
  { name: 'Columbus, GA (Opelika, AL) (522)', value: 522 },
  { name: 'Columbus, OH (535)', value: 535 },
  { name: 'Columbus-Tupelo-W Pnt-Hstn (673)', value: 673 },
  { name: 'Corpus Christi (600)', value: 600 },
  { name: 'Dallas-Ft. Worth (623)', value: 623 },
  { name: 'Davenport-R.Island-Moline (682)', value: 682 },
  { name: 'Dayton (542)', value: 542 },
  { name: 'Denver (751)', value: 751 },
  { name: 'Des Moines-Ames (679)', value: 679 },
  { name: 'Detroit (505)', value: 505 },
  { name: 'Dothan (606)', value: 606 },
  { name: 'Duluth-Superior (676)', value: 676 },
  { name: 'El Paso (Las Cruces) (765)', value: 765 },
  { name: 'Elmira (Corning) (565)', value: 565 },
  { name: 'Erie (516)', value: 516 },
  { name: 'Eugene (801)', value: 801 },
  { name: 'Eureka (802)', value: 802 },
  { name: 'Evansville (649)', value: 649 },
  { name: 'Fairbanks (745)', value: 745 },
  { name: 'Fargo (724)', value: 724 },
  { name: 'Flint-Saginaw-Bay City (513)', value: 513 },
  { name: 'Fresno-Visalia (866)', value: 866 },
  { name: 'Ft. Myers-Naples (571)', value: 571 },
  { name: 'Ft. Smith-Fay-Sprngdl-Rgrs (670)', value: 670 },
  { name: 'Ft. Wayne (509)', value: 509 },
  { name: 'Gainesville (592)', value: 592 },
  { name: 'Glendive (798)', value: 798 },
  { name: 'Grand Junction-Montrose (773)', value: 773 },
  { name: 'Grand Rapids-Kalmzoo-B.Crk (563)', value: 563 },
  { name: 'Great Falls (755)', value: 755 },
  { name: 'Green Bay-Appleton (658)', value: 658 },
  { name: 'Greensboro-H.Point-W.Salem (518)', value: 518 },
  { name: 'Greenville-N.Bern-Washngtn (545)', value: 545 },
  { name: 'Greenvll-Spart-Ashevll-And (567)', value: 567 },
  { name: 'Greenwood-Greenville (647)', value: 647 },
  { name: 'Harlingen-Wslco-Brnsvl-McA (636)', value: 636 },
  { name: 'Harrisburg-Lncstr-Leb-York (566)', value: 566 },
  { name: 'Harrisonburg (569)', value: 569 },
  { name: 'Hartford & New Haven (533)', value: 533 },
  { name: 'Hattiesburg-Laurel (710)', value: 710 },
  { name: 'Helena (766)', value: 766 },
  { name: 'Honolulu (744)', value: 744 },
  { name: 'Houston (618)', value: 618 },
  { name: 'Huntsville-Decatur (Flor) (691)', value: 691 },
  { name: 'Idaho Fals-Pocatllo(Jcksn) (758)', value: 758 },
  { name: 'Indianapolis (527)', value: 527 },
  { name: 'Jackson, MS (718)', value: 718 },
  { name: 'Jackson, TN (639)', value: 639 },
  { name: 'Jacksonville (561)', value: 561 },
  { name: 'Johnstown-Altoona-St Colge (574)', value: 574 },
  { name: 'Jonesboro (734)', value: 734 },
  { name: 'Joplin-Pittsburg (603)', value: 603 },
  { name: 'Juneau (747)', value: 747 },
  { name: 'Kansas City (616)', value: 616 },
  { name: 'Knoxville (557)', value: 557 },
  { name: 'La Crosse-Eau Claire (702)', value: 702 },
  { name: 'Lafayette, IN (582)', value: 582 },
  { name: 'Lafayette, LA (642)', value: 642 },
  { name: 'Lake Charles (643)', value: 643 },
  { name: 'Lansing (551)', value: 551 },
  { name: 'Laredo (749)', value: 749 },
  { name: 'Las Vegas (839)', value: 839 },
  { name: 'Lexington (541)', value: 541 },
  { name: 'Lima (558)', value: 558 },
  { name: 'Lincoln & Hastings-Krny (722)', value: 722 },
  { name: 'Little Rock-Pine Bluff (693)', value: 693 },
  { name: 'Los Angeles (803)', value: 803 },
  { name: 'Louisville (529)', value: 529 },
  { name: 'Lubbock (651)', value: 651 },
  { name: 'Macon (503)', value: 503 },
  { name: 'Madison (669)', value: 669 },
  { name: 'Mankato (737)', value: 737 },
  { name: 'Marquette (553)', value: 553 },
  { name: 'Medford-Klamath Falls (813)', value: 813 },
  { name: 'Memphis (640)', value: 640 },
  { name: 'Meridian (711)', value: 711 },
  { name: 'Miami-Ft. Lauderdale (528)', value: 528 },
  { name: 'Milwaukee (617)', value: 617 },
  { name: 'Minneapolis-St. Paul (613)', value: 613 },
  { name: 'Minot-Bsmrck-Dcknsn(Wlstn) (687)', value: 687 },
  { name: 'Missoula (762)', value: 762 },
  { name: 'Mobile-Pensacola (Ft Walt) (686)', value: 686 },
  { name: 'Monroe-El Dorado (628)', value: 628 },
  { name: 'Monterey-Salinas (828)', value: 828 },
  { name: 'Montgomery-Selma (698)', value: 698 },
  { name: 'Myrtle Beach-Florence (570)', value: 570 },
  { name: 'Nashville (659)', value: 659 },
  { name: 'New Orleans (622)', value: 622 },
  { name: 'New York (501)', value: 501 },
  { name: 'Norfolk-Portsmth-Newpt Nws (544)', value: 544 },
  { name: 'North Platte (740)', value: 740 },
  { name: 'Odessa-Midland (633)', value: 633 },
  { name: 'Oklahoma City (650)', value: 650 },
  { name: 'Omaha (652)', value: 652 },
  { name: 'Orlando-Daytona Bch-Melbrn (534)', value: 534 },
  { name: 'Ottumwa-Kirksville (631)', value: 631 },
  { name: 'Paducah-Cape Girard-Harsbg (632)', value: 632 },
  { name: 'Palm Springs (804)', value: 804 },
  { name: 'Panama City (656)', value: 656 },
  { name: 'Parkersburg (597)', value: 597 },
  { name: 'Peoria-Bloomington (675)', value: 675 },
  { name: 'Philadelphia (504)', value: 504 },
  { name: 'Phoenix (Prescott) (753)', value: 753 },
  { name: 'Pittsburgh (508)', value: 508 },
  { name: 'Portland, OR (820)', value: 820 },
  { name: 'Portland-Auburn (500)', value: 500 },
  { name: 'Presque Isle (552)', value: 552 },
  { name: 'Providence-New Bedford (521)', value: 521 },
  { name: 'Quincy-Hannibal-Keokuk (717)', value: 717 },
  { name: 'Raleigh-Durham (Fayetvlle) (560)', value: 560 },
  { name: 'Rapid City (764)', value: 764 },
  { name: 'Reno (811)', value: 811 },
  { name: 'Richmond-Petersburg (556)', value: 556 },
  { name: 'Roanoke-Lynchburg (573)', value: 573 },
  { name: 'Rochester, NY (538)', value: 538 },
  { name: 'Rochestr-Mason City-Austin (611)', value: 611 },
  { name: 'Rockford (610)', value: 610 },
  { name: 'Sacramnto-Stkton-Modesto (862)', value: 862 },
  { name: 'Salisbury (576)', value: 576 },
  { name: 'Salt Lake City (770)', value: 770 },
  { name: 'San Angelo (661)', value: 661 },
  { name: 'San Antonio (641)', value: 641 },
  { name: 'San Diego (825)', value: 825 },
  { name: 'San Francisco-Oak-San Jose (807)', value: 807 },
  { name: 'SantaBarbra-SanMar-SanLuOb (855)', value: 855 },
  { name: 'Savannah (507)', value: 507 },
  { name: 'Seattle-Tacoma (819)', value: 819 },
  { name: 'Sherman-Ada (657)', value: 657 },
  { name: 'Shreveport (612)', value: 612 },
  { name: 'Sioux City (624)', value: 624 },
  { name: 'Sioux Falls(Mitchell) (725)', value: 725 },
  { name: 'South Bend-Elkhart (588)', value: 588 },
  { name: 'Spokane (881)', value: 881 },
  { name: 'Springfield, MO (619)', value: 619 },
  { name: 'Springfield-Holyoke (543)', value: 543 },
  { name: 'St. Joseph (638)', value: 638 },
  { name: 'St. Louis (609)', value: 609 },
  { name: 'Syracuse (555)', value: 555 },
  { name: 'Tallahassee-Thomasville (530)', value: 530 },
  { name: 'Tampa-St. Pete (Sarasota) (539)', value: 539 },
  { name: 'Terre Haute (581)', value: 581 },
  { name: 'Toledo (547)', value: 547 },
  { name: 'Topeka (605)', value: 605 },
  { name: 'Traverse City-Cadillac (540)', value: 540 },
  { name: 'Tri-Cities, TN-VA (531)', value: 531 },
  { name: 'Tucson (Sierra Vista) (789)', value: 789 },
  { name: 'Tulsa (671)', value: 671 },
  { name: 'Twin Falls (760)', value: 760 },
  { name: 'Tyler-Longview(Lfkn&Ncgd) (709)', value: 709 },
  { name: 'Utica (526)', value: 526 },
  { name: 'Victoria (626)', value: 626 },
  { name: 'Waco-Temple-Bryan (625)', value: 625 },
  { name: 'Washington, DC (Hagrstwn) (511)', value: 511 },
  { name: 'Watertown (549)', value: 549 },
  { name: 'Wausau-Rhinelander (705)', value: 705 },
  { name: 'West Palm Beach-Ft. Pierce (548)', value: 548 },
  { name: 'Wheeling-Steubenville (554)', value: 554 },
  { name: 'Wichita Falls & Lawton (627)', value: 627 },
  { name: 'Wichita-Hutchinson Plus (678)', value: 678 },
  { name: 'Wilkes Barre-Scranton-Hztn (577)', value: 577 },
  { name: 'Wilmington (550)', value: 550 },
  { name: 'Yakima-Pasco-Rchlnd-Knnwck (810)', value: 810 },
  { name: 'Youngstown (536)', value: 536 },
  { name: 'Yuma-El Centro (771)', value: 771 },
  { name: 'Zanesville (596)', value: 596 },
]
