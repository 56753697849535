import moment from 'moment'

export default [
  {
    key: 'name',
    label: 'Name',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'type',
    label: 'Type',
    sortable: true,
    formatter: (v: string) => v.replaceAll('_', ' '),
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'period',
    label: 'Period',
    sortable: true,
    formatter: (v: string) => v.replaceAll('_', ' '),
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'user_id',
    label: 'Owner',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'end_at',
    label: 'Expire At',
    sortable: true,
    formatter: (v: string) => moment(v).format('MMMM, Do YYYY'),
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'created_at',
    label: 'Created At',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'updated_at',
    label: 'Update At',
    sortable: true,
    class: 'text-center',
    show: false,
  },
  {
    key: 'action',
    label: 'Action',
    sortable: false,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
]
