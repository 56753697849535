export default [
  {
    key: 'invoice_number',
    label: 'Invoice Number',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'invoice_date',
    label: 'Invoice Date',
    type: 'date',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'invoice_type',
    label: 'Invoice Type',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'client',
    label: 'Client',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'sales_management',
    label: 'Sales Manager',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'sales_rep',
    label: 'Sales Rep',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'media_plan_number',
    label: 'Media Plan Number',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'media_plan',
    label: 'Media Plan',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'actual',
    label: 'Actual',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
    type: 'currency',
  },
  {
    key: 'cost',
    label: 'Cost',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
    type: 'currency',
  },
  {
    key: 'net_invoice',
    label: 'Net Invoice',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
    type: 'currency',
  },
  {
    key: 'profit',
    label: 'Profit',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
    type: 'currency',
  },
  {
    key: 'profit_percentage',
    label: 'Profit Percentage',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
]
