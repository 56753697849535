
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import ReportQuery from '@/models/ReportQuery'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import DataTable from '@/components/DataTable/index.vue'
import DatePickerDate from '@/models/DatePickerDate'
import { groupBy, startCase, uniqBy } from 'lodash'
import Multiselect from 'vue-multiselect'
import QueryFilter from './Components/QueryFilter.vue'
import WebMessage from '../../models/WebMessage'
import User from '../../models/User'
import CheckboxInput from '../../components/CheckboxInput/index.vue'
import invoice_report_fields from './invoice-report-fields'
import mediaplan_report_fields from './mediaplan-report-fields'
import pnl_report_fields from './pnl-report-fields'
import calendar_revenue_fields from './calendar-revenue-fields'
import calendar_cost_fields from './calendar-cost-fields'
import report_options_and_settings from './report-options'
import mediaplan_finance_fields from './mediaplan-finance-fields'
import invoice_overview_fields from './invoice-overview-fields'
import campaign_cost_distribution_fields from './campaign-cost-distribution-fileds'
import invoice_expense_details_feilds from './invoice-expense-details-fields'

interface OptionItem {
  group: string
  content: {
    name: string
    value: string
    show: boolean
    dimensions: Array<any>
    metrics: Array<any>
    filters: any
    dimensions_metrics_reorder_columns: boolean
  }
}

@Component({
  components: {
    Widget,
    SelectPicker,
    DatePicker,
    IconAction,
    FormInput,
    QueryFilter,
    DataTable,
    CheckboxInput,
    Multiselect,
  },
})
export default class ReportEdit extends ViewModel {
  @Ref() readonly shareForm!: HTMLFormElement

  @Ref() readonly saveForm!: HTMLFormElement

  @Prop({ default: null })
  public id!: string | null

  public loading: boolean = false

  public report: ReportQuery = new ReportQuery()

  public title: string = 'Create Report'

  public show_report: boolean = true

  public data: object[] | null = []

  public fields: object[] = []

  public sortBy: string = 'date'

  public sortDesc: boolean = true

  public busy: boolean = false

  public custom_to_options: any = []

  public to_options: any = []

  public ready = false

  public fieldsOrder: any = []

  public can_load_select: boolean = false

  public report_options: any = []

  public type_Selected: any = []

  public settings_list: any = {}

  @Watch('reportType')
  public onReportTypeChange(val: any) {
    if (!val || !val.length) return

    this.report.clearFilters()

    this.clearReportSettings()

    this.setPreselected(val)
  }

  public normalizeCode(code: string) {
    return startCase(code.replace(/_/g, ' '))
  }

  public clearReportSettings() {
    this.report.updateSettings({ dimensions: [], metrics: [] })
  }

  public get reportType() {
    return this.report.type
  }

  public get email_picker_options() {
    return [...this.to_options, ...this.custom_to_options]
  }

  public modal: any = {
    delete: false,
    send: false,
    filter: false,
    save: false,
  }

  public get report_fields() {
    let field_options: any = {
      aging_report: invoice_report_fields,
      media_plan_report: mediaplan_report_fields,
      pnl_report: pnl_report_fields,
      calendar_cost: calendar_cost_fields,
      calendar_revenue: calendar_revenue_fields,
      media_plan_finance: mediaplan_finance_fields,
      invoice_overview: invoice_overview_fields,
      campaign_cost_distribution: campaign_cost_distribution_fields,
      invoice_expense_details: invoice_expense_details_feilds,
    }
    return this.filterKeysToFields(field_options[this.report.type]) || []
  }

  public setPreselected(val: string) {
    let found: any = report_options_and_settings.find(
      g => g.content.value === val,
    )

    this.report.updateSettings({
      metrics: found.content.metrics
        .filter(d => d.selected)
        .map(item => item.value),
      dimensions: found.content.dimensions
        .filter(d => d.selected)
        .map(item => item.value),
    })

    Vue.set(this, 'settings_list', found)
  }

  public filterKeysToFields(fields: any) {
    if (!this.data || !this.data.length) return fields
    let keys = Object.keys(this.data[0])
    fields = fields.filter(
      (field: any) => keys.includes(field.key) && field.show,
    )
    return this.orderFieldsByDimensionsAndMetrics(fields)
  }

  public orderFieldsByDimensionsAndMetrics(fields: any) {
    if (!this.settings_list.content.dimensions_metrics_reorder_columns) return fields

    let orderedFields: any = []

    this.report.settings.dimensions.forEach((dim: any) => {
      const field = fields.find(f => f.key === dim)
      if (field) orderedFields.push(field)
    })

    this.report.settings.metrics.forEach((metric: any) => {
      const field = fields.find(f => f.key === metric)
      if (field) orderedFields.push(field)
    })

    if (!orderedFields || !orderedFields.length) return fields

    return orderedFields
  }

  public get report_types() {
    let visable_types = report_options_and_settings.filter(
      (op: OptionItem) => op.content?.show,
    )
    let grouped = groupBy(visable_types, 'group')

    let type_groups_orders = [
      'Planning Reports',
      'Traffcking',
      'Finance Reports',
    ]

    let types = Object.keys(grouped).map(key => ({
      group: key,
      content: grouped[key].map((item: OptionItem) => ({
        name: item.content.name,
        value: item.content.value,
      })),
    }))

    let in_order: any = []

    type_groups_orders.forEach(group => {
      let found = types.find(t => t.group === group)
      if (found) in_order.push(found)
    })

    return in_order
  }

  /**
   * Returns array of filters names that need to be hidden
   *
   * Used on Prop[hide_fields] in the QueryFilter component
   */
  public get hide_filter_option_by_type() {
    let filters = this.settings_list?.content
      ? this.settings_list?.content.filters?.hidden_fields
      : false
    if (!filters || !filters.length) return ['filter-clients']
    return filters
  }

  public period_options: object[] = [
    { name: 'None', value: 'none' },
    { name: 'Daily (Everyday)', value: 'daily' },
    { name: 'Weekly (Every Monday)', value: 'weekly' },
    { name: 'Monthly (1st day of Month)', value: 'monthly' },
  ]

  public clearData() {
    this.data = []
  }

  public addTag(newTag: string) {
    if (this.validateEmail(newTag)) {
      const tag = {
        name: newTag,
        value: newTag,
      }
      this.custom_to_options.push(tag)
      this.report.to.push(newTag)
    } else {
      WebMessage.error('Please enter a valid email.')
    }
  }

  public validateEmail(email: string) {
    const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return res.test(email.toLowerCase())
  }

  public filterModal() {
    this.modal.filter = true
  }

  public saveModal() {
    this.modal.save = true
  }

  public confirmDelete() {
    this.modal.delete = true
  }

  public handleSaveOk() {
    this.saveForm.validate().then((success: boolean) => {
      if (!success) {
        return
      }
      this.modal.save = false
      this.save()
    })
  }

  public handleShareOk() {
    this.shareForm.validate().then((success: boolean) => {
      if (!success) {
        return
      }
      this.shareConfirm()
    })
  }

  public save() {
    this.loading = true

    this.report
      .save()
      .then(response => {
        this.loading = false
        if (response.status == 200) {
          this.$router.push({ name: 'Reports' })
        }
      })
      .catch(() => {
        this.loading = false
      })
  }

  public cancel() {
    this.$router.push({ name: 'Reports' })
  }

  public download() {
    this.loading = true
    WebMessage.success(
      'Your report is being generated. Please wait for the download to start.',
    )
    this.report.download().then(() => {
      this.loading = false
    })
  }

  public share() {
    this.modal.send = true
  }

  public shareConfirm() {
    this.modal.send = false
    this.loading = true
    this.report
      .share()
      .then(() => (this.loading = false))
      .catch(() => (this.loading = false))
  }

  public mounted() {
    this.setPreselected(this.report.type)
  }

  public created() {
    if (this.user.report_view_id) {
      this.report.settings.filters.view = this.user.report_view_id
    }

    if (this.id) {
      this.loading = true
      ReportQuery.get(this.id).then((o: ReportQuery | null) => {
        if (o instanceof ReportQuery) {
          this.report = o
          this.title = 'Edit Report'

          this.populateEmailOptions()
        }
        this.loading = false
        this.ready = true
        this.run()
      })
    } else {
      const { query } = this.$route

      if (query.from && typeof query.from === 'string') {
        ReportQuery.get(query.from).then(o => {
          if (o instanceof ReportQuery) {
            this.report = Object.assign(new ReportQuery(), o)
            this.report.id = null
            this.report.name += ' (Copy)'
          }

          this.populateEmailOptions()
          this.loading = false
          this.ready = true
          this.run()
        })
      } else if (this.user.type === 'sales') {
        // @ts-ignore
        this.report.settings.filters.sales_reps.push(this.user.id)
        this.ready = true
      } else {
        this.ready = true
      }
      // this.report_options = report_options_and_settings
      // make the report_options_and_settings unique by key group
      // this.report_options = uniqBy(report_options_and_settings, 'group')
    }
  }

  public searchOptions(search = '*') {
    this.busy = true
    if (!search) search = '*'

    User.searchOptions({
      search: search.includes('*') ? search : `*${search}*`,
    }).then(response => {
      this.to_options = response.map((o: any) => ({
        name: o.name,
        value: o.detail,
      }))
      this.cleanCustomToList()
      this.busy = false
    })
  }

  public populateEmailOptions() {
    if (this.report.to.length > 0) {
      this.report.to.forEach(item => {
        if (!this.custom_to_options.some((o: any) => o.value == item)) {
          this.custom_to_options.push({
            name: item,
            value: item,
          })
        }
      })
      this.busy = true
      User.searchOptions({
        value: this.custom_to_options,
      }).then(response => {
        this.to_options = response
        this.cleanCustomToList()
        this.busy = false
      })
    }
  }

  public cleanCustomToList() {
    if (this.custom_to_options.length > 0) {
      this.custom_to_options = this.custom_to_options.filter((o: any) => {
        if (this.report.to.includes(o.value)) return true
        return !this.to_options.some(
          (so: any) => so.value.toLowerCase() == o.value.toLowerCase(),
        )
      })
    }
  }

  public getTotal(key: string) {
    let total = 0
    let count = 0

    const special_processing = [
      'completion_rate',
      'lifetime_frequency',
      'daily_avg_frequency',
      'coviewing',
      'reach_increment',
    ]

    for (const i in this.data) {
      let val = 0
      if (special_processing.includes(key)) {
        // @ts-ignore
        val = parseFloat(this.data![i][key] ?? 0)
        total += val
      } else {
        // @ts-ignore
        val = parseInt(this.data![i][key] ?? 0)
        total += val
      }
      if (
        (key != 'lifetime_frequency'
          && key != 'daily_avg_frequency'
          && key != 'coviewing'
          && key != 'reach_increment')
        || val > 0
      ) {
        count++
      }
    }

    if (special_processing.includes(key)) {
      total /= count
    }
    return total
  }

  public canRun(): boolean {
    if (this.report.type === 'delivery') {
      if (
        this.report.settings.filters.dmas.length > 0
        && (this.report.settings.metrics.length > 1
          || this.report.settings.metrics[0] != 'impressions')
      ) {
        WebMessage.error(
          'DMA Filter is only compatible with the "impressions" metric',
          [
            {
              text: 'Fix Filters',
              action: (toast: any) => {
                this.report.settings.filters.dmas = []
                WebMessage.hide(toast.id)
                this.run()
              },
            },
            {
              text: 'Fix Metrics',
              action: (toast: any) => {
                this.report.settings.metrics = ['impressions']
                WebMessage.hide(toast.id)
                this.run()
              },
            },
          ],
        )
      }
    }

    return true
  }

  public run() {
    // if (this.report.type === 'delivery_forecast') {
    //   this.report.settings.date.shortcut = null
    // }
    if (!this.canRun()) {
      return
    }

    if (this.settings_list.content && this.settings_list.content.export_only) {
      this.download()
      return
    }
    this.loading = true
    this.report
      .fetch()
      .then(response => {
        if (typeof response.data.result == 'string') {
          this.loading = false
          WebMessage.warning(
            'Your report is too large to display. A download should start shortly.',
          )
          return
        }
        this.data = []
        this.data = response.data.result

        this.fields = []

        for (const key in response.data.result[0]) {
          if (key !== 'advertiser_id') {
            this.fields.push({
              key,
              sortable: true,
              class: 'text-center align-middle',
            })
          }
        }

        this.loading = false
      })
      .catch(error => {
        this.loading = false
        if (error.response.status == 422) {
          let message = ''
          let type = ''
          if (error.response.data.errors.dimensions) {
            message = error.response.data.errors.dimensions[0]
            type = 'dimenssions'
          } else {
            message = error.response.data.errors.metrics[0]
            type = 'frequency'
          }
          WebMessage.error(message, [
            {
              text: 'Fix Dimensions',
              action: (toast: any) => {
                const dimensions: any = []
                this.report.settings.dimensions.forEach(item => {
                  if (type == 'frequency') {
                    if (
                      ![
                        'date',
                        'hour',
                        'device_type',
                        'region',
                        'order',
                        'line_item',
                      ].includes(item)
                    ) {
                      dimensions.push(item)
                    }
                  } else if (item != 'hour' && item != 'region') {
                    dimensions.push(item)
                  }
                })

                if (type == 'frequency') {
                  if (!dimensions.includes('media_plan')) {
                    dimensions.push('media_plan')
                  }
                }

                this.report.settings.dimensions = dimensions
                WebMessage.hide(toast.id)
                this.run()
              },
            },
            {
              text: 'Fix Metrics',
              action: (toast: any) => {
                const metrics: any = []
                this.report.settings.metrics.forEach(item => {
                  if (type == 'frequency') {
                    if (item != 'lifetime_frequency') {
                      metrics.push(item)
                    }
                  } else if (item == 'impressions') {
                    metrics.push(item)
                  }
                })

                this.report.settings.metrics = metrics
                WebMessage.hide(toast.id)
                this.run()
              },
            },
          ])
        }
      })
  }

  public shortcutSelect(shortcut = 'month') {
    this.report.settings.date = new DatePickerDate(null, null, shortcut)
  }

  /** For line_item targetting */
  public filterTargetting(data: any) {
    // Country, DMA, State, City
    let clear = (d: any) => {
      if (!d || !d.length) return 'N/A'
      return d.toString()
    }
    return {
      country: clear(data.country),
      dmas: clear(data.dmas),
      states: clear(data.states),
      cities: clear(data.cities),
      devices: clear(data.devices),
    }
  }
}
