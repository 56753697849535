import Api from './Api'
import Company from './Company'
import Model from './interface/Model'
import User from './User'

export default class ModelFile extends Model {
  public name: string = ''

  public description: string = ''

  public type: string = ''

  public size: number = 0

  public source_name: string = ''

  public versions: Array<any> = []

  public user_id: string = ''

  public user: User = new User()

  public company_id: string = ''

  public company: Company = new Company()

  public owner_model_type: string = ''

  public owner_model_id: string = ''

  public create_at: string = ''

  public update_at: string = ''

  public download(version: null | string = null) {
    const api = new Api()

    let url = `model_file/download/${this.id}`

    if (version) {
      url += `/${version}`
    }

    return api.download(url, this.name)
  }
}
