import Api from './Api'
import Model from './interface/Model'

export default class GeoTargettingModule extends Model {
  protected api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'geo-targetting' as string | null,
      plural: 'geo-targetting' as string | null,
    },
  }

  public api = new Api()

  public async geoSearchAll(query: any) {
    return await this.api.get('geo-targetting/search', query)
  }

  public async searchOptions(query: any) {
    const api = new Api(false)
    return api
      .get(`${this.api_settings.paths.plural}/search/option`, query)
      .then(response =>
        // response.data.result.options
        response.data.result.options.map((option: any) => ({
          name: option.name,
          value: option.value,
          owner_id: option.owner_id ?? null,
          type: option.type,
          full_name: option.full_name,
          state_code: option.state_code ?? null,
          country_code: option.country_code ?? null,
        })))
      .catch(() => [])
  }
}
