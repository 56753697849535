export default [
  {
    key: 'date',
    label: 'Date',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'number',
    label: 'Number',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'name',
    label: 'Name',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'count',
    label: 'Count',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'type',
    label: 'Type',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'billing_cycle',
    label: 'Billing Cycle',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'status',
    label: 'Status',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'advertiser',
    label: 'Advertiser',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'agency',
    label: 'agency',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'station',
    label: 'Station',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'sales_rep',
    label: 'Sales Rep',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'account_manager',
    label: 'Account Manager',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'gross_total',
    label: 'Gross Total',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'impressions',
    label: 'Impressions',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'net_total',
    label: 'Net Total',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'agency_commission',
    label: 'Agency commission',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'gross_rate',
    label: 'Gross Rate',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'impressions',
    label: 'Impressions',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'net_rate',
    label: 'Net Rate',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
]
