import { LINEAR_COMPANY_IDS } from '@/models/interface/Common'
import SystemtModule from '@/store/SystemModule'
import { dimensions } from 'vee-validate/dist/rules'
import { getModule } from 'vuex-module-decorators'

const { user }: any = getModule(SystemtModule)

let dimension_options = [
  { name: 'Date', value: 'date', show: true },
  { name: 'Hour', value: 'hour', show: true },
  { name: 'Order', value: 'media_plan', show: true },
  { name: 'Line Item', value: 'media_plan_item', show: true },
  { name: 'Advertiser', value: 'advertiser', show: true },
  { name: 'Publisher', value: 'publisher', show: true },
  { name: 'Device', value: 'device_type', show: true },
  { name: 'Region', value: 'region', show: true },
  { name: 'Agency', value: 'agency', show: user.isSuperAdmin },
  { name: 'Order (Ad Server)', value: 'order', show: user.isSuperAdmin },
  { name: 'Line Item (Ad Server)', value: 'line_item', show: user.isSuperAdmin },
  { name: 'Ad Server', value: 'adserver', show: user.isSuperAdmin },
  { name: 'Ad Unit', value: 'adunit', show: user.isSuperAdmin },
  { name: 'Station Market', value: 'station', show: !user.isStation },
  { name: 'Media Plan Id', value: 'media_plan_id', show: true },
  { name: 'Media Plan Item Id', value: 'media_plan_item_id', show: true },
  { name: 'Creative Name', value: 'creative_name', show: true },
  { name: 'Creative Id', value: 'creative_id', show: true },
]

let metric_options = [
  { name: 'Impressions', value: 'impressions', show: true },
  { name: 'First Quartile', value: 'first_quartile', show: true },
  { name: 'Midpoint', value: 'midpoint', show: true },
  { name: 'Third Quartile', value: 'third_quartile', show: true },
  { name: 'Completed', value: 'completed', show: true },
  { name: 'Completion Rate', value: 'completion_rate', show: true },
]

const filter_dimensions = (options: any, hide: boolean = true) =>
  dimension_options.filter((opt: any) =>
    (hide ? !options.includes(opt.value) : options.includes(opt.value)))

const filter_metrics = (options: any, hide: boolean = true) =>
  metric_options.filter((opt: any) =>
    (hide ? !options.includes(opt.value) : options.includes(opt.value)))

const default_dimensions = (options: string[]): any[] =>
  dimension_options.map((opt: any) => {
    opt.selected = opt.show && options.includes(opt.value)
    return opt
  })

const default_metrics = (options: string[]): any[] =>
  metric_options.map((opt: any) => {
    opt.selected = opt.show && options.includes(opt.value)
    return opt
  })

export default [
  {
    group: 'Finance Reports',
    content: {
      name: 'Invoice Overview',
      value: 'invoice_overview',
      show: user.can('invoice', 'view'),
      dimensions: [],
      metrics: [],
      filters: {
        hidden_fields: [
          'filter-media-placements',
          'filter-order-type',
          'filter-line-items',
          'filter-isci-code',
          'filter-devices',
          'filter-advertiser',
          'filter-agencies',
          'filter-stations',
          'filter-dmas',
        ],
      },
      display_settings: {
        dimensions: false,
        metrics: false,
      },
      dimensions_metrics_reorder_columns: true,
    },
  },
  {
    group: 'Finance Reports',
    content: {
      name: 'Campagin Cost Distribution',
      value: 'campaign_cost_distribution',
      show: user.can('invoice', 'view'),
      export_only: true,
      dimensions: [],
      metrics: [],
      filters: {
        hidden_fields: [
          'filter-media-placements',
          'filter-order-type',
          'filter-line-items',
          'filter-isci-code',
          'filter-devices',
          'filter-agency-estimate-code',
          'filter-product-name',
          'filter-advertiser-name',
          'filter-dmas',
        ],
      },
      display_settings: {
        dimensions: false,
        metrics: false,
      },
      dimensions_metrics_reorder_columns: true,
    },
  },
  {
    group: 'Finance Reports',
    content: {
      name: 'Invoice Expense Details',
      value: 'invoice_expense_details',
      show: user.can('invoice', 'view'),
      export_only: false,
      dimensions: [],
      metrics: [],
      filters: {
        hidden_fields: [
          'filter-media-placements',
          'filter-order-type',
          'filter-line-items',
          'filter-isci-code',
          'filter-devices',
          'filter-agency-estimate-code',
          'filter-product-name',
          'filter-advertiser-name',
          'filter-dmas',
        ],
      },
      display_settings: {
        dimensions: false,
        metrics: false,
      },
      dimensions_metrics_reorder_columns: true,
    },
  },
  {
    group: 'Planning Reports',
    content: {
      name: 'Media Plan Finance Overview',
      value: 'media_plan_finance',
      show: user.can('invoice', 'view'),
      dimensions: [
        {
          name: 'Number',
          value: 'number',
          show: true,
          selected: true,
        },
        {
          name: 'Name',
          value: 'name',
          show: true,
          selected: true,
        },
        {
          name: 'Sales Rep',
          value: 'sales_rep',
          show: true,
          selected: true,
        },
        {
          name: 'Client',
          value: 'client',
          show: true,
          selected: true,
        },
        {
          name: 'Status',
          value: 'status',
          show: true,
          selected: true,
        },
        {
          name: 'Invoice Status',
          value: 'invoices_status',
          show: true,
          selected: false,
        },
        {
          name: 'Invoice Number',
          value: 'invoices_numbers',
          show: true,
          selected: false,
        },
        {
          name: 'Billing Periods',
          value: 'billing_periods',
          show: true,
          selected: true,
        },
      ],
      metrics: [
        {
          name: 'Invoices',
          value: 'invoices',
          show: true,
          selected: true,
        },
        {
          name: 'Paid Invoices',
          value: 'paid_invoices',
          show: true,
          selected: true,
        },
        {
          name: 'Gross Total',
          value: 'gross_total',
          show: true,
          selected: true,
        },
        {
          name: 'Net Total',
          value: 'net_total',
          show: true,
          selected: true,
        },
        {
          name: 'Invoice Total',
          value: 'invoice_total',
          show: true,
          selected: true,
        },
        {
          name: 'Total Paid',
          value: 'total_paid',
          show: true,
          selected: true,
        },
      ],
      filters: {
        hidden_fields: [
          'filter-media-placements',
          'filter-line-items',
          'filter-isci-code',
          'filter-devices',
          'filter-clients',
          'filter-dmas',
        ],
      },
      display_settings: {
        dimensions: true,
        metrics: true,
      },
      dimensions_metrics_reorder_columns: true,
    },
  },
  {
    group: 'Planning Reports',
    content: {
      name: 'Media Plan Report',
      value: 'media_plan_report',
      show: user.can('media_plan', 'view'),
      dimensions: [
        {
          name: 'Number',
          value: 'number',
          show: true,
          selected: true,
        },
        {
          name: 'Name',
          value: 'name',
          show: true,
          selected: true,
        },
        {
          name: 'Agency',
          value: 'agency',
          show: true,
          selected: true,
        },
        {
          name: 'Type',
          value: 'type',
          show: true,
          selected: true,
        },
        {
          name: 'Status',
          value: 'status',
          show: true,
          selected: true,
        },
        {
          name: 'Sales Rep',
          value: 'sales_rep',
          show: true,
          selected: true,
        },
        {
          name: 'Account Manager',
          value: 'account_manager',
          show: true,
          selected: true,
        },
        {
          name: 'Billing cycle',
          value: 'billing_cycle',
          show: true,
          selected: true,
        },
        ...filter_dimensions([
          'agency',
          'hour',
          'media_plan',
          'media_plan_item',
          'device_type',
          'region',
          'publisher',
          'media_plan',
          'line_item',
          'order',
        ]),
      ],
      metrics: [
        {
          name: 'Line Item Count',
          value: 'count',
          show: true,
          selected: true,
        },
        {
          name: 'Gross Total',
          value: 'gross_total',
          show: true,
          selected: true,
        },
        {
          name: 'Net Total',
          value: 'net_total',
          show: true,
          selected: true,
        },
        {
          name: 'Agency Comission',
          value: 'agency_commission',
          show: true,
          selected: true,
        },
        {
          name: 'Gross Rate',
          value: 'gross_rate',
          show: true,
          selected: true,
        },
        {
          name: 'Net Rate',
          value: 'net_rate',
          show: true,
          selected: true,
        },
        ...filter_metrics([
          'first_quartile',
          'midpoint',
          'third_quartile',
          'completed',
          'completion_rate',
        ]),
      ],
      filters: {
        hidden_fields: [
          'filter-media-placements',
          'filter-line-items',
          'filter-isci-code',
          'filter-devices',
          'filter-clients',
          'filter-dmas',
        ],
      },
      display_settings: {
        dimensions: true,
        metrics: true,
      },
      dimensions_metrics_reorder_columns: true,
    },
  },
  {
    group: 'Traffcking',
    content: {
      name: 'Delivery',
      value: 'delivery',
      show: true,
      dimensions: [
        ...default_dimensions(
          LINEAR_COMPANY_IDS.includes(user.company_id)
            ? ['media_plan', 'media_plan_item']
            : ['date'],
        ),
        { name: 'Line Item Code', value: 'media_plan_item_code', show: user.isSuperAdmin },
        {
          name: 'C/P/E',
          value: 'cpe_code',
          show: true,
          selected: LINEAR_COMPANY_IDS.includes(user.company_id),
        },
        {
          name: 'Client Code',
          value: 'agency_advertiser_code',
          show: true,
          selected: false,
        },
        {
          name: 'Product Code',
          value: 'agency_product_code',
          show: true,
          selected: false,
        },
        {
          name: 'Estimate Code',
          value: 'agency_estimate_code',
          show: true,
          selected: false,
        },
      ],
      metrics: [
        ...default_metrics(['impressions', 'completed', 'completion_rate']),
        {
          name: 'Lifetime Frequency',
          value: 'lifetime_frequency',
          show: user.isSuperAdmin,
        },
        {
          name: 'Avg Daily Frequency',
          value: 'daily_avg_frequency',
          show: user.isSuperAdmin,
        },
        {
          name: 'Order Coviewing',
          value: 'coviewing',
          show: user.isSuperAdmin,
        },
        {
          name: 'Order Incremental Reach',
          value: 'reach_increment',
          show: user.isSuperAdmin,
        },
      ],
      filters: {
        hidden_fields: ['filter-clients'],
      },
      display_settings: {
        dimensions: true,
        metrics: true,
      },
      dimensions_metrics_reorder_columns: true,
    },
  },
  {
    group: 'Traffcking',
    content: {
      name: 'Filtered Impressions',
      value: 'filtered_impressions',
      show: user.can('reconciliation', 'view'),
      dimensions: [],
      export_only: true,
      metrics: [],
      filters: {
        hidden_fields: ['filter-clients', 'filter-dmas'],
      },
      display_settings: {
        dimensions: false,
        metrics: false,
      },
      dimensions_metrics_reorder_columns: true,
    },
  },
  {
    group: 'Traffcking',
    content: {
      name: 'Delivery Forecast',
      value: 'delivery_forecast',
      show: user.can('adserver', 'view'),
      dimensions: [],
      export_only: true,
      metrics: [],
      filters: {
        hidden_fields: ['filter-clients', 'filter-dmas'],
      },
      display_settings: {
        dimensions: false,
        metrics: false,
      },
      dimensions_metrics_reorder_columns: true,
    },
  },
  {
    group: 'Finance Reports',
    content: {
      name: 'Invoice Aging Report',
      value: 'aging_report',
      show: user.can('invoice', 'view'),
      dimensions: [],
      metrics: [],
      filters: {
        hidden_fields: [
          'filter-clients',
          'filter-media-placements',
          'filter-order-type',
          'filter-line-items',
          'filter-isci-code',
          'filter-devices',
          'filter-advertiser',
          'filter-agencies',
          'filter-stations',
          'filter-dmas',
        ],
      },
      display_settings: {
        dimensions: false,
        metrics: false,
      },
      dimensions_metrics_reorder_columns: false,
    },
  },
  {
    group: 'Finance Reports',
    content: {
      name: 'Calendar Cost Report',
      value: 'calendar_cost',
      show: user.can('invoice', 'view'),
      dimensions: [],
      metrics: [],
      filters: {
        hidden_fields: [
          'filter-clients',
          'filter-media-placements',
          'filter-order-type',
          'filter-line-items',
          'filter-isci-code',
          'filter-devices',
          'filter-advertiser',
          'filter-agencies',
          'filter-stations',
          'filter-dmas',
        ],
      },
      display_settings: {
        dimensions: false,
        metrics: false,
      },
      dimensions_metrics_reorder_columns: false,
    },
  },
  {
    group: 'Finance Reports',
    content: {
      name: 'Calendar Revenue Report',
      value: 'calendar_revenue',
      show: user.can('invoice', 'view'),
      dimensions: [],
      metrics: [],
      filters: {
        hidden_fields: [
          'filter-clients',
          'filter-media-placements',
          'filter-order-type',
          'filter-line-items',
          'filter-isci-code',
          'filter-devices',
          'filter-advertiser',
          'filter-agencies',
          'filter-stations',
          'filter-dmas',
        ],
      },
      display_settings: {
        dimensions: false,
        metrics: false,
      },
      dimensions_metrics_reorder_columns: false,
    },
  },
  {
    group: 'Finance Reports',
    content: {
      name: 'P&L Report',
      value: 'pnl_report',
      show: false,
      dimensions: [
        {
          name: 'Invoice Number',
          value: 'number',
          show: true,
          selected: true,
        },
        {
          name: 'AE',
          value: 'ae',
          show: true,
          selected: true,
        },
        {
          name: 'Opportunity',
          value: 'opportunity',
          show: true,
          selected: true,
        },
        {
          name: 'Company To Invoice',
          value: 'company_to_invoice',
          show: true,
          selected: true,
        },
        {
          name: 'MediaOcean/Strata',
          value: 'media_ocean_or_strata',
          show: true,
          selected: true,
        },
        ...filter_dimensions(
          ['number', 'ae', 'opportunity', 'company_to_invoice', 'media_ocean_or_strata'],
          false,
        ),
      ],
      metrics: [
        {
          name: 'Calendar Revenue',
          value: 'calendar_revenue',
          show: true,
          selected: true,
        },
        {
          name: 'Cost',
          value: 'cost',
          show: true,
          selected: true,
        },
        {
          name: 'Profit',
          value: 'profit',
          show: true,
          selected: true,
        },
        {
          name: 'Amount to be paid',
          value: 'invoice_amount_to_be_paid',
          show: true,
          selected: true,
        },
        ...filter_metrics(
          ['calendar_revenue', 'cost', 'profit', 'invoice_amount_to_be_paid'],
          false,
        ),
      ],
      filters: {
        hidden_fields: ['filter-clients', 'filter-dmas'],
      },
      display_settings: {
        dimensions: true,
        metrics: true,
      },
      dimensions_metrics_reorder_columns: true,
    },
  },
]
