export default [
  {
    key: 'calendar_revenue',
    label: 'Calendar Revenue',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
    type: 'currency',
  },
  {
    key: 'cost',
    label: 'Cost',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
    type: 'currency',
  },
  {
    key: 'ae',
    label: 'AE',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'sales_rep',
    label: 'Sales Rep',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'profit',
    label: 'Profit',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
    type: 'currency',
  },
  {
    key: 'invoice_number',
    label: 'Invoice Number',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'opportunity',
    label: 'Opportunity',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'company_to_invoice',
    label: 'Company to invoice',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'invoice_amount_to_be_paid',
    label: 'Invoice amount to be paid (net/amount on Quickbooks invoice)',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
    type: 'currency',

  },
  {
    key: 'media_ocean_or_strata',
    label: 'Media Ocean or Strata',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
]
