export default [
  {
    key: 'client',
    label: 'Client',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'invoice_type',
    label: 'Media Plan Type',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    label: 'Media Plan #',
    key: 'media_plan_number',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    label: 'Media Plan Name',
    key: 'media_plan',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'media_plan_item_number',
    label: 'Item #',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'media_plan_item',
    label: 'Item Name',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'sales_rep',
    label: 'Sales Rep',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'invoice_number',
    label: 'Invoice #',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'invoice_date',
    label: 'Invoice Date',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'top_invnetory_name',
    label: 'Top Level Adunit Name',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'inventory_id',
    label: 'Adunit ID',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'inventory_name',
    label: 'Adunit Name',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'gross_rate',
    label: 'Gross Rate',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
    type: 'currency',
  },
  {
    key: 'net_rate',
    label: 'Net Rate',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
    type: 'currency',
  },
  {
    key: 'gross_total',
    label: 'Gross Total',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
    type: 'currency',
  },
  {
    key: 'net_total',
    label: 'Net Total',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
    type: 'currency',
  },
  {
    key: 'cost_rate',
    label: 'Cost Rate',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
    type: 'currency',
  },
  {
    key: 'cost_total',
    label: 'Total Cost',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
    type: 'currency',
  },
  {
    key: 'profit',
    label: 'Profit $',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
    type: 'currency',
  },
  {
    key: 'profit_percentage',
    label: 'Profit %',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
    type: 'currency',
  },
]
