
import Vue from 'vue'
import Pdf from 'pdfvuer'
import Api from '@/models/Api'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {
    Pdf,
  },
})
export default class SideNavigator extends Vue {
  @Prop({ default: 2 })
  public cols!: number

  @Prop()
  public file_path!: any

  @Prop()
  public custom_path!: any

  public file_content: any = null

  public pdfSettings = {
    page: 1,
    numPages: 0,
  }

  public mounted() {
    this.loadPdf()
  }

  public loadPdf() {
    let loadingTask: any = null
    if (this.custom_path) {
      loadingTask = new Api().getFileUrl(`model_file/download/${this.file_path}`)
    } else {
      loadingTask = new Api().getFileUrl(this.file_path)
    }
    // @ts-ignore
    this.file_content = Pdf.createLoadingTask(loadingTask)

    this.file_content.then((pdf: any) => {
      this.pdfSettings.numPages = pdf.numPages
    })
  }

  public scrollToPage(page: number) {
    let thumb: any = document.getElementById(`pdf-thumb-${page}`)
    let element: any = document.getElementById(`pdf-page-${page}`)
    // thumb.scrollIntoView({ behavior: 'smooth', block: 'center' })
    setTimeout(() => {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }, 500)
  }
}
