
import {
  Component, Vue, Ref, Prop,
} from 'vue-property-decorator'

@Component({})
export default class DropArea extends Vue {
  @Ref() private file_input!: HTMLInputElement

  @Prop()
  public filesLimit!: any

  private show_drop_overlay: boolean = false

  public files = []

  private events = ['dragover', 'dragleave', 'drop', 'dragdrop']

  public dismissSecs: number = 10

  public dismissCountDown: number = 0

  public countDownChanged(dismissCountDown: any) {
    this.dismissCountDown = dismissCountDown
  }

  public open() {
    this.file_input.click()
  }

  public filesSelected(e: Event) {
    const files = (e.target as HTMLInputElement).files
    if (files && files.length > this.filesLimit) {
      this.dismissCountDown = 10
      return
    }
    this.$emit('file-drop', files)
  }

  private onDragEnter(e: any) {
    e.preventDefault()

    this.show_drop_overlay = true
  }

  public onDragLeave() {
    this.show_drop_overlay = false
  }

  public onDrop(e: any) {
    e.preventDefault()
    this.show_drop_overlay = false
    if (e.dataTransfer.files.length > this.filesLimit) {
      this.dismissCountDown = 10
      return
    }
    this.$emit('file-drop', e.dataTransfer.files)
  }

  private preventDefaults(e: any) {
    e.preventDefault()
  }

  public mounted() {
    document.ondragenter = this.onDragEnter
    this.events.forEach(eventName => {
      document.addEventListener(eventName, this.preventDefaults)
    })
  }

  public beforeDestroy() {
    document.ondragenter = null
    this.events.forEach(eventName => {
      document.removeEventListener(eventName, this.preventDefaults)
    })
  }
}
