/** @format */

import Api from '@/models/Api'
import store from '@/store'
import CreativeModule from '@/store/model/CreativeModule'
import { getModule } from 'vuex-module-decorators'
// @ts-ignore
import { VASTClient } from '@dailymotion/vast-client'
import ApiParameters from '@/models/interface/ApiParameters'
import axios from 'axios'
import SystemtModule from '@/store/SystemModule'
import Company from './Company'
import WebMessage from './WebMessage'
import PaginateOptions from './interface/PaginateOptions'
import SelectOption from './interface/SelectOption'

export default class Creative {
  public id: string | null = null

  public name: string = ''

  public advertiser_id: string | null = null

  public type: string = 'vast'

  public adserver: string = 'cim'

  public duration: number = 30000

  public preview_url: string | null = null

  public vast_url: string | null = null

  public destination_url: string | null = null

  public created_at: string | null = null

  public updated_at: string | null = null

  public isci_code: string | null = null

  public ready: boolean = true

  public enabled: boolean = true

  public status: string = 'ready'

  public advertiser: Company = new Company()

  public tracking_events: any = []

  public unwrapper: any = 'auto'

  public _showDetails: boolean = false

  public get isVast() {
    return this.type === 'vast'
  }

  public save(asset: any = null, onProgress: any = null) {
    const api = new Api(true, {
      onUploadProgress: (progressEvent: any) => {
        if (onProgress) onProgress(progressEvent)
      },
    })

    const data: ApiParameters = {
      name: this.name,
      type: this.type,
      advertiser_id: this.advertiser_id,
      duration: this.duration > 1000 ? this.duration : this.duration * 1000,
      asset_file: asset,
      destination_url: this.destination_url,
      vast_url: this.vast_url,
      tracking_events: JSON.stringify(this.tracking_events),
      unwrapper: this.unwrapper,
      enabled: this.enabled,
      isci_code: this.isci_code,
    }

    if (asset) {
      delete data.vast_url
    } else {
      delete data.asset_file
      if (this.type == 'vast') delete data.destination_url
    }

    if (this.type == 'video') delete data.vast_url

    if (this.id) {
      return api.form(`creative/${this.id}`, data).then(this.onSave).catch(this.onError)
    }
    return api.form('creative', data).then(this.onSave).catch(this.onError)
  }

  public delete() {
    const api = new Api()

    return api.delete(`creative/${this.id}`, {}).then(this.onDelete).catch(this.onError)
  }

  public updateTag() {
    const api = new Api()

    return api.post(`creative/${this.id}/tag`, {}).then(this.onTagUpdate).catch(this.onError)
  }

  private onTagUpdate(response: any) {
    const creatives = Creative.toObject(response.data.result.creative)

    WebMessage.success(`Generating new TAG for creative "${creatives.name}"!`)

    return response
  }

  private onSave(response: any) {
    const creatives = Creative.toObject(response.data.result.creative)

    WebMessage.success(`Creative "${creatives.name}" saved!`)

    return response
  }

  private onDelete(response: any) {
    const creatives = Creative.filter(response.data.result.deleted)

    let message

    if (creatives.length == 1) {
      message = `Entity "${creatives[0].name}" deleted!`
    } else {
      message = 'Entity deleted!'
    }

    WebMessage.success(message)

    Creative.module.delete(creatives)

    return response
  }

  private onError(error: any) {
    return error
  }

  private getUrl(): string {
    // Use VAST TAG if type is VAST
    if (this.type == 'vast' && this.vast_url) return this.vast_url
    // If Google Hosted, use preview URL
    if (this.preview_url) return this.preview_url
    // If Revvid Hosted Use static TAG
    if (this.type == 'video' && this.id) {
      return `https://cdn.revvidmedia.com/videos/${this.id}/tag.xml`
    }

    return ''
  }

  public test(proxy: boolean = false, uri: string | null = null) {
    // Skip Validation If new Revvid Hosted
    if (this.type == 'video' && !this.id) {
      return new Promise(resolve => {
        resolve(true)
      })
    }

    const vastClient = new VASTClient()
    const url: string = uri ?? this.getUrl()

    const cache_buster = Math.floor(Math.random() * 1000000000).toString()

    const valid = /^(?:https:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.{}%]+$/.test(
      url.replace('http:', 'https:').replaceAll('%%CACHEBUSTER%%', cache_buster),
    )

    if (valid && url) {
      let proxy_url = url
        .replace('http:', 'https:')
        .replaceAll('%%CACHEBUSTER%%', cache_buster)
        .replace(/\[.*?\]/gim, '')
        .replace(/%%.*?%%/gim, '')

      if (proxy) {
        const system = getModule(SystemtModule)
        const clean_url = proxy_url.replace(/\[.*?\]/gim, '')

        proxy_url = `${process.env.VUE_APP_BASE_API_URL}creatives/preview-vast-url?api_token=${
          system.api_token
        }&vast_url=${encodeURIComponent(clean_url)}`
      }

      return vastClient.get(proxy_url)
    }
    return new Promise(() => {
      throw 'Invalide Tag'
    })
  }

  public wrapperDepth(proxy: boolean = false) {
    // Skip Validation If new Revvid Hosted
    if (this.type == 'video' && !this.id) {
      return new Promise(resolve => {
        resolve(true)
      })
    }

    const vastClient = new VASTClient()
    const url: string = this.getUrl()

    const cache_buster = Math.floor(Math.random() * 1000000000).toString()

    const valid = /^(?:https:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.{}%]+$/.test(
      url.replace('http:', 'https:').replaceAll('%%CACHEBUSTER%%', cache_buster),
    )

    if (valid && url) {
      let proxy_url = url
        .replace('http:', 'https:')
        .replaceAll('%%CACHEBUSTER%%', cache_buster)
        .replace(/\[.*?\]/gim, '')
        .replace(/%%.*?%%/gim, '')

      const system = getModule(SystemtModule)

      if (proxy) {
        const clean_url = proxy_url.replace(/\[.*?\]/gim, '')

        proxy_url = `${process.env.VUE_APP_BASE_API_URL}creatives/preview-vast-url?api_token=${
          system.api_token
        }&vast_url=${encodeURIComponent(clean_url)}`
      }

      let wrapperCount = (url: string, depth: number = 0): any =>
        axios.get(url).then((response: any) => {
          let parser = new DOMParser()
          let xmlDoc = parser.parseFromString(response.data, 'text/xml')

          let result = vastClient.getParser().parseVastXml(xmlDoc, { wrapperDepth: -1 })

          // @ts-ignore
          if (result[0].nextWrapperURL) {
            depth++
            // @ts-ignore
            const path = encodeURIComponent(result[0].nextWrapperURL)
            const remote_url = `${process.env.VUE_APP_BASE_API_URL}creatives/preview-vast-url?api_token=${system.api_token}&vast_url=${path}`
            return wrapperCount(remote_url, depth)
          }
          return Promise.resolve(depth)
        })

      return wrapperCount(proxy_url)
    }

    return new Promise(() => {
      throw 'Invalide Tag'
    })
  }

  public inspect(mode: string = 'flow') {
    const url = this.getUrl()

    if (!url) return

    const cache_buster = Math.floor(Math.random() * 1000000000).toString()

    if (mode == 'google') {
      window.open(
        `https://developers.google.com/interactive-media-ads/docs/sdks/html5/client-side/vastinspector?tag=${encodeURIComponent(
          url.replace('http:', 'https:').replaceAll('%%CACHEBUSTER%%', cache_buster),
        )}`,
        '_blank',
      )
    } else {
      window.open(
        `https://flowplayer.com/developers/tools/ad-tester?ad=0&custom=${encodeURIComponent(
          url.replace('http:', 'https:').replaceAll('%%CACHEBUSTER%%', cache_buster),
        )}&release_channel=stable`,
        '_blank',
      )
    }
  }

  protected recursiveExtractVPAIDMediaFiles(parameters: any): any {
    const formats = ['video/webm', 'video/mp4']
    let obj: any = {}
    let ad: any = {}
    // convert HTML string to text
    if (typeof parameters === 'string') {
      let txt = document.createElement('textarea')
      txt.innerHTML = parameters
      let text = txt.value

      // Parse XML
      let parser = new DOMParser()
      let xmlDoc = parser.parseFromString(text, 'text/xml')

      obj = this.xml2json(xmlDoc)

      ad = obj.VAST.Ad
    } else {
      ad = parameters.ads
    }

    if (Array.isArray(ad)) {
      ad = ad[0]
    }

    if (ad.Wrapper) {
      return this.test(true, ad.Wrapper.VASTAdTagURI).then((response: any) =>
        this.recursiveExtractVPAIDMediaFiles(response))
    }
    const files = ad.creatives[Math.floor(Math.random() * ad.creatives.length)].mediaFiles

    return files.reduce((carry: any, current: any) => {
      if (!carry) return current
      if (current.width > carry.width && formats.includes(current.mimeType)) return current
      if (
        current.width == carry.width
        && formats.includes(current.mimeType)
        && current.deliveryType == 'STREAMING'
      ) {
        return current
      }
      return carry
    }, null)
  }

  protected xml2json(xml: any) {
    const log = false
    try {
      let obj: any = {}
      if (xml.children.length > 0) {
        for (let i = 0; i < xml.children.length; i++) {
          let item = xml.children.item(i)
          let nodeName = item.nodeName

          if (typeof obj[nodeName] == 'undefined') {
            obj[nodeName] = this.xml2json(item)
          } else {
            if (typeof obj[nodeName].push == 'undefined') {
              let old = obj[nodeName]

              obj[nodeName] = []
              obj[nodeName].push(old)
            }
            obj[nodeName].push(this.xml2json(item))
          }
        }
      } else {
        obj = xml.textContent
      }
      return obj
    } catch (e) {
      if (log) {
        throw 'Invalide Tag'
      }
    }
  }

  protected previewVPAID(ad: any) {
    const creative = ad.creatives[Math.floor(Math.random() * ad.creatives.length)]

    if (creative.adParameters && creative.adParameters.value) {
      return Promise.resolve(
        this.recursiveExtractVPAIDMediaFiles(creative.adParameters.value.trim()),
      )
    }
    throw 'Invalide Tag'
  }

  public preview() {
    const formats = ['video/webm', 'video/mp4']
    return this.test()
      .then((r: any) => {
        if (r.ads && r.ads.length > 0) {
          const ad = r.ads[Math.floor(Math.random() * r.ads.length)]

          const files = ad.creatives[Math.floor(Math.random() * ad.creatives.length)].mediaFiles

          if (files[0].mimeType === 'application/javascript' && files.length === 1) {
            return this.previewVPAID(ad)
          }

          return files.reduce((carry: any, current: any) => {
            if (!carry) return current
            if (current.width > carry.width && formats.includes(current.mimeType)) return current
            if (
              (current.width == carry.width
                && formats.includes(current.mimeType)
                && current.deliveryType == 'STREAMING')
              || (carry.mimeType === 'application/javascript' && current.mimeType !== 'application/javascript')
            ) {
              return current
            }
            return carry
          }, null)
        }
        throw 'Invalide Tag'
      })
      .catch((e: any) => {
        WebMessage.error('Could not load the preview, the tag returned an empty response.', [
          {
            text: 'Inspect',
            action: () => {
              this.inspect()
            },
          },
        ])

        return e
      })
  }

  public static toObject(data: any, cache: boolean = true): Creative {
    const creative = new Creative()

    creative.id = data.id
    creative.name = data.name
    creative.advertiser_id = data.advertiser_id
    creative.type = data.type
    creative.duration = data.duration
    creative.preview_url = data.preview_url
    creative.vast_url = data.vast_url
    creative.destination_url = data.destination_url
    creative.created_at = data.created_at
    creative.updated_at = data.updated_at
    creative.ready = data.ready
    creative.enabled = data.enabled
    creative.adserver = data.adserver
    creative.tracking_events = data.tracking_events
    creative.unwrapper = data.unwrapper
    creative.isci_code = data.isci_code

    if (data.advertiser) {
      creative.advertiser = Company.toObject(data.advertiser)
    }

    //  Cache Object List
    if (cache) Creative.module.update(creative)

    return creative
  }

  public static toObjectList(data: any, cache: boolean = true): Creative[] {
    const creatives = new Array<Creative>()
    data.forEach((value: any) => {
      const creative = Creative.toObject(value, false)
      creatives.push(creative)
    })

    //  Cache Object List
    if (cache) Creative.module.update(creatives)

    return creatives
  }

  public toOption(): SelectOption {
    return new SelectOption(
      this.name,
      this.id,
      this.advertiser_id,
      this.duration > 16000 ? '30' : '15',
    )
  }

  public toggleStatus() {
    if (this.enabled) {
      return WebMessage.doubleConfirm(
        `Are you sure that you want to disable the creative <strong>${this.name}</strong>? This action will can cause some line items to stop delivering if they don't have an alternative creative.`,
        'Disable Creative',
        "Yes, I understand and I'll review all line items associated with it.",
        { okTitle: 'Disable' },
      ).then(result => {
        if (result) {
          this.enabled = false
          return this.save()
        }
      })
    }

    return WebMessage.confirm(
      `Are you sure that you want to enable the creative <strong>${this.name}</strong>? If the creative is associated with an active line item it will automatically resume delivery.`,
      'Enable Creative',
      { okTitle: 'Enable' },
    ).then((result: boolean) => {
      if (result) {
        this.enabled = true
        return this.save()
      }
    })
  }

  public unlink() {
    return WebMessage.doubleConfirm(
      `Are you sure that you want to remove the creative <strong>${this.name}</strong> from all line items? This action will can cause some line items to stop delivering if they don't have an alternative creative.`,
      'Unlink Creative',
      "Yes, I understand and I'll review all line items associated with it.",
      { okTitle: 'Unlink' },
    ).then(result => {
      if (result) {
        return this.unlinkFromLineItems().then(() => {
          WebMessage.success(`Creative "${this.name}" unlinked from all line items!`)
        })
      }
    })
  }

  // State Management
  public static get module(): CreativeModule {
    if (!store.hasModule('creative')) {
      store.registerModule('creative', CreativeModule)
    }
    const m = getModule(CreativeModule)
    if (!m.synchronized) {
      m.syncOptions()
    }

    return m
  }

  public static find(id: string): Creative | null {
    const o = Creative.module.data.find(creative => creative.id === id)
    return o instanceof Creative ? o : null
  }

  public static filter(filter: any): Creative[] {
    if (Array.isArray(filter)) {
      return Creative.module.data.filter(creative => creative.id && filter.includes(creative.id))
    }
    return Creative.module.data.filter(filter)
  }

  public static async get(id: string): Promise<Creative | null> {
    return Creative.module.find(id)
  }

  public static async paginate(options: PaginateOptions) {
    return Creative.module.paginate(options)
  }

  public unlinkFromLineItems() {
    const api = new Api()

    return api.post(`creative/${this.id}/unlink`, {}).catch(this.onError)
  }

  public static async getCreativesByIds(ids: any[]) {
    const api = new Api()
    return api
      .post('creatives/find-by-ids', {
        creative_ids: ids,
      })
      .then(response => response.data.result.creatives)
  }

  public async loadAssociated(options: PaginateOptions) {
    const api = new Api()
    return api
      .get(`creatives-associated/${this.id}`, options)
      .then(response => response.data.result)
  }
}
