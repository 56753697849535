
import {
  Component, Prop, Ref, Vue,
} from 'vue-property-decorator'
import DropArea from '@/components/DropArea/DropArea.vue'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import ModelFile from '@/models/ModelFile'
import WebMessage from '@/models/WebMessage'

import PdfModal from '@/components/PdfPreview/PdfModal.vue'
// @ts-ignore
import * as FileSaver from 'file-saver'
import router from '@/Routes'
import GoogleClient from '@/models/GoogleClient'
import SheetModal from '@/components/SheetModal/SheetModal.vue'

// https://developers.google.com/drive/picker/guides/overview#appreg
// https://developers.google.com/identity/sign-in/web/reference

// TODO: Drive Download

@Component({
  components: {
    DropArea,
    IconAction,
    PdfModal,
    SheetModal,
  },
})
export default class ModelFileUploader extends ViewModel {
  @Ref() private drop_area!: DropArea

  @Ref() private preview_modal!: SheetModal

  @Prop()
  public fileFormat!: string

  @Prop({ default: false })
  public fullPageDropArea!: boolean

  @Prop({ default: false })
  public edit!: boolean

  @Prop({ default: () => [] })
  public customOptions!: any[]

  @Prop({ default: () => [] })
  public files!: ModelFile[]

  @Prop({ default: () => [] })
  public model_files_meta!: Array<any>

  @Prop({ default: () => [] })
  public model_files_binary!: Array<File>

  @Prop()
  public filesLimit!: any

  public target: string = ''

  public name: string = ''

  public description: string = ''

  public preview_file_id: string | null = null

  public preview_pdf: boolean = false

  @Prop({ default: false })
  public fit_name!: boolean

  @Prop({ default: false })
  public apiComponent!: boolean

  @Prop({ default: false })
  public preserveImgSrc!: boolean

  public loaded_input: boolean = true

  public loaded_thumb_srcs: any = []

  public get computed_thumb_src() {
    return this.loaded_thumb_srcs
  }

  public get local_model_files_meta() {
    return this.model_files_meta
  }

  public set local_model_files_meta(value) {
    this.$emit('update:model_files_meta', value)
  }

  public get local_model_files_binary() {
    return this.model_files_binary
  }

  public set local_model_files_binary(value) {
    this.$emit('update:model_files_binary', value)
  }

  public get show_files() {
    return [
      ...this.files
        .filter(file => !this.local_model_files_meta.some(f => f.id === file.id && f.delete))
        .map(file => {
          let meta = this.local_model_files_meta.find(f => f.id === file.id)

          if (meta) {
            file.name = meta.name
            file.description = meta.description
          }
          return file
        }),
      ...this.local_model_files_meta.filter(file => !file.delete && file.id === undefined),
    ]
  }

  public getIcon(type: string): string {
    if (type.includes('pdf')) {
      return 'file-pdf'
    }
    if (type.includes('xls')) {
      return 'file-excel'
    }
    if (type.includes('doc')) {
      return 'file-word'
    }
    if (['png', 'jpg', 'jpeg', 'bmp'].includes(type)) return 'file-image'

    return 'file'
  }

  public fileDrop(files: FileList) {
    if (this.filesLimit && this.local_model_files_meta.length === this.filesLimit) return
    for (let i = 0; i < files.length; i++) {
      // check files format is they have fileFormat, eg of prop:  file-format="image/*"
      if (this.fileFormat && !this.fileFormat.includes(files[i].type)) {
        WebMessage.warning('File format not supported.')
        return
      }

      // Check if already has a file with the same name
      if (this.local_model_files_meta.some((f: any) => f.source_name === files[i].name)) {
        WebMessage.error(
          `You already have a file with the same name "${files[i].name}", please delete the original or change the name of the new file.`,
        )
      } else {
        let match = files[i].name.match(/\.([0-9a-z]+$)/i)

        let ext = ''
        if (match) {
          ext = match[1]
        }

        if (this.preserveImgSrc) {
          const reader = new FileReader()
          reader.onloadend = () => {
            this.loaded_thumb_srcs.push({
              id: files[i].name,
              src: reader.result,
            })
          }

          reader.readAsDataURL(files[i])
        }

        this.local_model_files_meta.push({
          name: files[i].name.replace(/\.[0-9a-z]+$/, ''),
          source_name: files[i].name,
          description: '',
          size: files[i].size,
          type: ext,
        })
        this.local_model_files_binary.push(files[i])
      }
    }
  }

  public download(id: string) {
    let file = this.files.find(f => f.id === id)

    if (file && !file.download) {
      file = ModelFile.toObject(file)
    }

    if (file) {
      file.download()
      return
    }

    let meta = this.local_model_files_meta.find(f => f.source_name === id)

    if (meta.remote_id) {
      window.open(`https://drive.google.com/file/d/${meta.remote_id}/view?usp=drive_web`)
      return
    }
    FileSaver.saveAs(
      this.local_model_files_binary.find(f => f.name === id),
      meta.name,
    )
  }

  public editFileInfo(id: string) {
    this.target = id

    let file = this.local_model_files_meta.find(
      f => f.id === this.target || f.source_name === this.target,
    )

    if (!file) {
      file = this.files.find(f => f.id === this.target)
    }

    this.name = file.name
    this.description = file.description

    this.$bvModal.show('modal-edit')
  }

  public save() {
    let file = this.local_model_files_meta.find(
      f => f.id === this.target || f.source_name === this.target,
    )

    if (!file) {
      this.local_model_files_meta.push({
        name: this.name,
        description: this.description,
        id: this.target,
      })
      return
    }

    file.name = this.name
    file.description = this.description
  }

  public add() {
    if (this.edit !== false) this.drop_area.open()
  }

  public findSrc(file_src_name: string) {
    let found = this.loaded_thumb_srcs.find(f => f.id === file_src_name)
    if (found) {
      return found.src
    }
    return null
  }

  public remove(id: string) {
    let target = this.files.find(f => f.id === id)
    let remove_binary: undefined | null | File = null
    if (target) {
      this.local_model_files_meta.push({
        id: target.id,
        delete: true,
      })
      if (this.preserveImgSrc) {
        this.loaded_thumb_srcs = this.loaded_thumb_srcs.filter(f => f.id !== target.source_name)
      }
    } else {
      this.local_model_files_meta = this.local_model_files_meta.filter(f => f.source_name !== id)
      remove_binary = this.local_model_files_binary.find(f => f.name === id)
    }

    if (remove_binary) {
      this.local_model_files_binary = this.local_model_files_binary.filter(
        f => f.name !== remove_binary?.name,
      )
    }

    this.loaded_input = false

    setTimeout(() => {
      this.loaded_input = true
    }, 300)
  }

  public fromDrive() {
    if (!this.user.web_oauth.google) {
      this.pleaseConnectGoogleAccount()

      return
    }

    this.user.getOauthToken('google').then(token => {
      if (!token) {
        this.pleaseConnectGoogleAccount()

        return
      }
      // @ts-ignore
      const pickerBuilder = new window.google.picker.PickerBuilder()
        .setOAuthToken(token)
        .setDeveloperKey(process.env.VUE_APP_GOOGLE_DEVELOPER_KEY)
        .setCallback(this.pickerCallback)
        // @ts-ignore
        .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)

        // @ts-ignore
        .addView(window.google.picker.ViewId.DOCS)
        .addViewGroup(
          // @ts-ignore
          new window.google.picker.ViewGroup(window.google.picker.ViewId.DOCS)
            // @ts-ignore
            .addView(window.google.picker.ViewId.DOCUMENTS)
            // @ts-ignore
            .addView(window.google.picker.ViewId.PRESENTATIONS)
            // @ts-ignore
            .addView(window.google.picker.ViewId.SPREADSHEETS),
        )
        .build()

      pickerBuilder.setVisible(true)
    })
  }

  private pleaseConnectGoogleAccount() {
    WebMessage.error('Please connect your google account before using this feature', [
      {
        text: 'Connect Now!',
        action: (toast: any) => {
          router.push({ name: 'Account' })
          WebMessage.hide(toast.id)
        },
      },
    ])
  }

  private supported_formats = [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.google-apps.document',
    'application/vnd.google-apps.spreadsheet',
    'application/vnd.google-apps.presentation',
    'application/vnd.google-apps.drawing',
    'text/csv',
    'image/jpeg',
    'image/png',
  ]

  private pickerCallback(data: any) {
    if (data.docs && data.docs.length > 0) {
      data.docs.forEach((d: any) => {
        if (!this.supported_formats.includes(d.mimeType)) {
          WebMessage.warning(`The file "${d.name}"" format is not supported.`)
        } else {
          this.local_model_files_meta.push({
            name: d.name,
            source_name: d.name,
            description: d.description ?? '',
            size: d.sizeBytes,
            remote_id: d.id,
            remote_mime: d.mimeType,
            type: 'file',
          })
        }
      })
    }
  }

  public previewPdf(id: string) {
    this.preview_file_id = id
    this.preview_pdf = true
  }

  public previewSheet(file: any) {
    this.preview_modal.previewSheet(file)
  }
}
