/** @format */

const dmas = [
  {
    id: 500,
    name: 'Portland-Auburn ME',
    latitude: '44.0978509',
    longitude: '-70.2311655',
    state: 'ME',
    media_ocean_station: 'PM',
  },
  {
    id: 501,
    name: 'New York, NY',
    latitude: '40.7142691',
    longitude: '-74.0059729',
    state: 'NY',
    media_ocean_station: 'NY',
  },
  {
    id: 502,
    name: 'Binghamton NY',
    latitude: '42.0986867',
    longitude: '-75.9179738',
    state: 'NY',
    media_ocean_station: 'BH',
  },
  {
    id: 503,
    name: 'Macon GA',
    latitude: '32.8406946',
    longitude: '-83.6324022',
    state: 'GA',
    media_ocean_station: 'MA',
  },
  {
    id: 504,
    name: 'Philadelphia PA',
    latitude: '39.952335',
    longitude: '-75.163789',
    state: 'PA',
    media_ocean_station: 'PH',
  },
  {
    id: 505,
    name: 'Detroit MI',
    latitude: '42.331427',
    longitude: '-83.0457538',
    state: 'MI',
    media_ocean_station: 'DE',
  },
  {
    id: 506,
    name: 'Boston MA-Manchester NH',
    latitude: '42.9956397',
    longitude: '-71.4547891',
    state: 'NH',
    media_ocean_station: 'BO',
  },
  {
    id: 507,
    name: 'Savannah GA',
    latitude: '32.0835407',
    longitude: '-81.0998342',
    state: 'GA',
    media_ocean_station: 'SV',
  },
  {
    id: 508,
    name: 'Pittsburgh PA',
    latitude: '40.4406248',
    longitude: '-79.9958864',
    state: 'PA',
    media_ocean_station: 'PI',
  },
  {
    id: 509,
    name: 'Ft. Wayne IN',
    latitude: '41.1306041',
    longitude: '-85.1288597',
    state: 'IN',
    media_ocean_station: 'FW',
  },
  {
    id: 510,
    name: 'Cleveland-Akron (Canton) OH',
    latitude: '40.7989473',
    longitude: '-81.378447',
    state: 'OH',
    media_ocean_station: 'CM',
  },
  {
    id: 511,
    name: 'Washington DC (Hagerstown MD)',
    latitude: '38.8951118',
    longitude: '-77.0363658',
    state: 'MD',
    media_ocean_station: 'WD',
  },
  {
    id: 512,
    name: 'Baltimore MD',
    latitude: '39.2903848',
    longitude: '-76.6121893',
    state: 'MD',
    media_ocean_station: 'BT',
  },
  {
    id: 513,
    name: 'Flint-Saginaw-Bay City MI',
    latitude: '43.5944677',
    longitude: '-83.8888647',
    state: 'MI',
    media_ocean_station: 'FL',
  },
  {
    id: 514,
    name: 'Buffalo NY',
    latitude: '42.8864468',
    longitude: '-78.8783689',
    state: 'NY',
    media_ocean_station: 'BU',
  },
  {
    id: 515,
    name: 'Cincinnati OH',
    latitude: '39.1361111',
    longitude: '-84.5030556',
    state: 'OH',
    media_ocean_station: 'CI',
  },
  {
    id: 516,
    name: 'Erie PA',
    latitude: '42.1292241',
    longitude: '-80.085059',
    state: 'PA',
    media_ocean_station: 'ER',
  },
  {
    id: 517,
    name: 'Charlotte NC',
    latitude: '35.2270869',
    longitude: '-80.8431267',
    state: 'NC',
    media_ocean_station: 'CK',
  },
  {
    id: 518,
    name: 'Greensboro-High Point-Winston Salem NC',
    latitude: '36.0998596',
    longitude: '-80.244216',
    state: 'NC',
    media_ocean_station: 'GH',
  },
  {
    id: 519,
    name: 'Charleston SC',
    latitude: '32.7765656',
    longitude: '-79.9309216',
    state: 'SC',
    media_ocean_station: 'CC',
  },
  {
    id: 520,
    name: 'Augusta GA',
    latitude: '33.47',
    longitude: '-81.975',
    state: 'GA',
    media_ocean_station: 'AU',
  },
  {
    id: 521,
    name: 'Providence-New Bedford,MA',
    latitude: '41.6362152',
    longitude: '-70.934205',
    state: 'MA',
    media_ocean_station: 'PV',
  },
  {
    id: 522,
    name: 'Columbus GA',
    latitude: '32.4609764',
    longitude: '-84.9877094',
    state: 'GA',
    media_ocean_station: 'CG',
  },
  {
    id: 523,
    name: 'Burlington VT-Plattsburgh NY',
    latitude: '44.6994873',
    longitude: '-73.4529124',
    state: 'NY',
    media_ocean_station: 'BP',
  },
  {
    id: 524,
    name: 'Atlanta GA',
    latitude: '33.7489954',
    longitude: '-84.3879824',
    state: 'GA',
    media_ocean_station: 'AT',
  },
  {
    id: 525,
    name: 'Albany GA',
    latitude: '31.5785074',
    longitude: '-84.155741',
    state: 'GA',
    media_ocean_station: 'AL',
  },
  {
    id: 526,
    name: 'Utica NY',
    latitude: '43.100903',
    longitude: '-75.232664',
    state: 'NY',
    media_ocean_station: 'UT',
  },
  {
    id: 527,
    name: 'Indianapolis IN',
    latitude: '39.7683765',
    longitude: '-86.1580423',
    state: 'IN',
    media_ocean_station: 'IN',
  },
  {
    id: 528,
    name: 'Miami-Ft. Lauderdale FL',
    latitude: '26.1223084',
    longitude: '-80.1433786',
    state: 'FL',
    media_ocean_station: 'MI',
  },
  {
    id: 529,
    name: 'Louisville KY',
    latitude: '38.2542376',
    longitude: '-85.759407',
    state: 'KY',
    media_ocean_station: 'LU',
  },
  {
    id: 530,
    name: 'Tallahassee FL-Thomasville GA',
    latitude: '30.8365815',
    longitude: '-83.9787808',
    state: 'GA',
    media_ocean_station: 'TA',
  },
  {
    id: 531,
    name: 'Tri-Cities TN-VA',
    latitude: '36.472723',
    longitude: '-82.4106862',
    state: 'TN-VA',
    media_ocean_station: 'TC',
  },
  {
    id: 532,
    name: 'Albany-Schenectady-Troy NY',
    latitude: '42.7284117',
    longitude: '-73.6917851',
    state: 'NY',
    media_ocean_station: 'AS',
  },
  {
    id: 533,
    name: 'Hartford & New Haven CT',
    latitude: '41.3081527',
    longitude: '-72.9281577',
    state: 'CT',
    media_ocean_station: 'HC',
  },
  {
    id: 534,
    name: 'Orlando-Daytona Beach-Melbourne FL',
    latitude: '28.0836269',
    longitude: '-80.6081089',
    state: 'FL',
    media_ocean_station: 'OR',
  },
  {
    id: 535,
    name: 'Columbus OH',
    latitude: '39.9611755',
    longitude: '-82.9987942',
    state: 'OH',
    media_ocean_station: 'CB',
  },
  {
    id: 536,
    name: 'Youngstown OH',
    latitude: '41.0997803',
    longitude: '-80.6495194',
    state: 'OH',
    media_ocean_station: 'YU',
  },
  {
    id: 537,
    name: 'Bangor ME',
    latitude: '44.8011821',
    longitude: '-68.7778138',
    state: 'ME',
    media_ocean_station: 'BG',
  },
  {
    id: 538,
    name: 'Rochester NY',
    latitude: '43.1547845',
    longitude: '-77.6155567',
    state: 'NY',
    media_ocean_station: 'RN',
  },
  {
    id: 539,
    name: 'Tampa-St. Petersburg (Sarasota) FL',
    latitude: '27.782254',
    longitude: '-82.667619',
    state: 'FL',
    media_ocean_station: 'TS',
  },
  {
    id: 540,
    name: 'Traverse City-Cadillac MI',
    latitude: '44.2519526',
    longitude: '-85.4011619',
    state: 'MI',
    media_ocean_station: 'TR',
  },
  {
    id: 541,
    name: 'Lexington KY',
    latitude: '38.0317136',
    longitude: '-84.4951359',
    state: 'KY',
    media_ocean_station: 'LE',
  },
  {
    id: 542,
    name: 'Dayton OH',
    latitude: '39.7589478',
    longitude: '-84.1916069',
    state: 'OH',
    media_ocean_station: 'DO',
  },
  {
    id: 543,
    name: 'Springfield-Holyoke MA',
    latitude: '42.2042586',
    longitude: '-72.6162009',
    state: 'MA',
    media_ocean_station: 'SM',
  },
  {
    id: 544,
    name: 'Norfolk-Portsmouth-Newport News VA',
    latitude: '36.9787588',
    longitude: '-76.428003',
    state: 'VA',
    media_ocean_station: 'NF',
  },
  {
    id: 545,
    name: 'Greenville-New Bern-Washington NC',
    latitude: '35.5465517',
    longitude: '-77.0521742',
    state: 'NC',
    media_ocean_station: 'GN',
  },
  {
    id: 546,
    name: 'Columbia SC',
    latitude: '34.0007104',
    longitude: '-81.0348144',
    state: 'SC',
    media_ocean_station: 'CS',
  },
  {
    id: 547,
    name: 'Toledo OH',
    latitude: '41.6639383',
    longitude: '-83.555212',
    state: 'OH',
    media_ocean_station: 'TD',
  },
  {
    id: 548,
    name: 'West Palm Beach-Ft. Pierce FL',
    latitude: '27.4467056',
    longitude: '-80.3256056',
    state: 'FL',
    media_ocean_station: 'WE',
  },
  {
    id: 549,
    name: 'Watertown NY',
    latitude: '43.9747838',
    longitude: '-75.9107565',
    state: 'NY',
    media_ocean_station: 'WT',
  },
  {
    id: 550,
    name: 'Wilmington NC',
    latitude: '34.2257255',
    longitude: '-77.9447102',
    state: 'NC',
    media_ocean_station: 'WN',
  },
  {
    id: 551,
    name: 'Lansing MI',
    latitude: '42.732535',
    longitude: '-84.5555347',
    state: 'MI',
    media_ocean_station: 'LM',
  },
  {
    id: 552,
    name: 'Presque Isle ME',
    latitude: '46.681153',
    longitude: '-68.0158615',
    state: 'ME',
    media_ocean_station: 'PQ',
  },
  {
    id: 553,
    name: 'Marquette MI',
    latitude: '46.5435442',
    longitude: '-87.395417',
    state: 'MI',
    media_ocean_station: 'MQ',
  },
  {
    id: 554,
    name: 'Wheeling WV-Steubenville OH',
    latitude: '40.3697905',
    longitude: '-80.6339638',
    state: 'OH',
    media_ocean_station: 'WH',
  },
  {
    id: 555,
    name: 'Syracuse NY',
    latitude: '43.0481221',
    longitude: '-76.1474244',
    state: 'NY',
    media_ocean_station: 'SY',
  },
  {
    id: 556,
    name: 'Richmond-Petersburg VA',
    latitude: '37.2279279',
    longitude: '-77.4019267',
    state: 'VA',
    media_ocean_station: 'RI',
  },
  {
    id: 557,
    name: 'Knoxville TN',
    latitude: '35.9606384',
    longitude: '-83.9207392',
    state: 'TN',
    media_ocean_station: 'KX',
  },
  {
    id: 558,
    name: 'Lima OH',
    latitude: '40.742551',
    longitude: '-84.1052256',
    state: 'OH',
    media_ocean_station: 'LI',
  },
  {
    id: 559,
    name: 'Bluefield-Beckley-Oak Hill WV',
    latitude: '37.9723339',
    longitude: '-81.1487135',
    state: 'WV',
    media_ocean_station: 'BL',
  },
  {
    id: 560,
    name: 'Raleigh-Durham (Fayetteville) NC',
    latitude: '35.0526641',
    longitude: '-78.8783585',
    state: 'NC',
    media_ocean_station: 'RD',
  },
  {
    id: 561,
    name: 'Jacksonville FL',
    latitude: '30.3321838',
    longitude: '-81.655651',
    state: 'FL',
    media_ocean_station: 'JK',
  },
  {
    id: 563,
    name: 'Grand Rapids-Kalamazoo-Battle Creek MI',
    latitude: '42.3211522',
    longitude: '-85.1797142',
    state: 'MI',
    media_ocean_station: 'GK',
  },
  {
    id: 564,
    name: 'Charleston-Huntington WV',
    latitude: '38.4192496',
    longitude: '-82.445154',
    state: 'WV',
    media_ocean_station: 'CU',
  },
  {
    id: 565,
    name: 'Elmira (Corning) NY',
    latitude: '42.0897965',
    longitude: '-76.8077338',
    state: 'NY',
    media_ocean_station: 'EN',
  },
  {
    id: 566,
    name: 'Harrisburg-Lancaster-Lebanon-York PA',
    latitude: '39.9625984',
    longitude: '-76.727745',
    state: 'PA',
    media_ocean_station: 'HB',
  },
  {
    id: 567,
    name: 'Greenville-Spartanburg-Asheville-Anderson',
    latitude: '34.5034394',
    longitude: '-82.6501332',
    state: 'SC',
    media_ocean_station: 'GS',
  },
  {
    id: 569,
    name: 'Harrisonburg VA',
    latitude: '38.4495688',
    longitude: '-78.8689155',
    state: 'VA',
    media_ocean_station: 'HL',
  },
  {
    id: 570,
    name: 'Florence-Myrtle Beach SC',
    latitude: '33.6890603',
    longitude: '-78.8866943',
    state: 'SC',
    media_ocean_station: 'MR',
  },
  {
    id: 571,
    name: 'Ft. Myers-Naples FL',
    latitude: '26.1420358',
    longitude: '-81.7948103',
    state: 'FL',
    media_ocean_station: 'FT',
  },
  {
    id: 573,
    name: 'Roanoke-Lynchburg VA',
    latitude: '37.4137536',
    longitude: '-79.1422464',
    state: 'VA',
    media_ocean_station: 'RO',
  },
  {
    id: 574,
    name: 'Johnstown-Altoona-State College PA',
    latitude: '40.5186809',
    longitude: '-78.3947359',
    state: 'PA',
    media_ocean_station: 'AO',
  },
  {
    id: 575,
    name: 'Chattanooga TN',
    latitude: '35.0456297',
    longitude: '-85.3096801',
    state: 'TN',
    media_ocean_station: 'CN',
  },
  {
    id: 576,
    name: 'Salisbury MD',
    latitude: '38.3606736',
    longitude: '-75.5993692',
    state: 'MD',
    media_ocean_station: 'SL',
  },
  {
    id: 577,
    name: 'Wilkes Barre-Scranton PA',
    latitude: '41.408969',
    longitude: '-75.6624122',
    state: 'PA',
    media_ocean_station: 'WB',
  },
  {
    id: 581,
    name: 'Terre Haute IN',
    latitude: '39.4667034',
    longitude: '-87.4139092',
    state: 'IN',
    media_ocean_station: 'TE',
  },
  {
    id: 582,
    name: 'Lafayette IN',
    latitude: '40.4167022',
    longitude: '-86.8752869',
    state: 'IN',
    media_ocean_station: 'LF',
  },
  {
    id: 583,
    name: 'Alpena MI',
    latitude: '45.0616794',
    longitude: '-83.4327528',
    state: 'MI',
    media_ocean_station: 'AP',
  },
  {
    id: 584,
    name: 'Charlottesville VA',
    latitude: '38.0293059',
    longitude: '-78.4766781',
    state: 'VA',
    media_ocean_station: 'CV',
  },
  {
    id: 588,
    name: 'South Bend-Elkhart IN',
    latitude: '41.6819935',
    longitude: '-85.9766671',
    state: 'IN',
    media_ocean_station: 'SN',
  },
  {
    id: 592,
    name: 'Gainesville FL',
    latitude: '29.6516344',
    longitude: '-82.3248262',
    state: 'FL',
    media_ocean_station: 'GA',
  },
  {
    id: 596,
    name: 'Zanesville OH',
    latitude: '39.9403453',
    longitude: '-82.0131924',
    state: 'OH',
    media_ocean_station: 'ZA',
  },
  {
    id: 597,
    name: 'Parkersburg WV',
    latitude: '39.2667418',
    longitude: '-81.5615135',
    state: 'WV',
    media_ocean_station: 'PK',
  },
  {
    id: 598,
    name: 'Clarksburg-Weston WV',
    latitude: '39.0384274',
    longitude: '-80.467313',
    state: 'WV',
    media_ocean_station: 'CL',
  },
  {
    id: 600,
    name: 'Corpus Christi TX',
    latitude: '27.8005828',
    longitude: '-97.396381',
    state: 'TX',
    media_ocean_station: 'CX',
  },
  {
    id: 602,
    name: 'Chicago IL',
    latitude: '41.850033',
    longitude: '-87.6500523',
    state: 'IL',
    media_ocean_station: 'CH',
  },
  {
    id: 603,
    name: 'Joplin MO-Pittsburg KS',
    latitude: '37.410884',
    longitude: '-94.70496',
    state: 'KS',
    media_ocean_station: 'JP',
  },
  {
    id: 604,
    name: 'Columbia-Jefferson City MO',
    latitude: '38.5767017',
    longitude: '-92.1735164',
    state: 'MO',
    media_ocean_station: 'CJ',
  },
  {
    id: 605,
    name: 'Topeka KS',
    latitude: '39.0483336',
    longitude: '-95.6780371',
    state: 'KS',
    media_ocean_station: 'TP',
  },
  {
    id: 606,
    name: 'Dothan AL',
    latitude: '31.2232313',
    longitude: '-85.3904888',
    state: 'AL',
    media_ocean_station: 'DT',
  },
  {
    id: 609,
    name: 'St. Louis MO',
    latitude: '38.646991',
    longitude: '-90.224967',
    state: 'MO',
    media_ocean_station: 'SR',
  },
  {
    id: 610,
    name: 'Rockford IL',
    latitude: '42.2711311',
    longitude: '-89.0939952',
    state: 'IL',
    media_ocean_station: 'RK',
  },
  {
    id: 611,
    name: 'Rochester-Mason City-Austin,IA',
    latitude: '43.6666296',
    longitude: '-92.9746367',
    state: 'MN',
    media_ocean_station: 'RM',
  },
  {
    id: 612,
    name: 'Shreveport LA',
    latitude: '32.5251516',
    longitude: '-93.7501789',
    state: 'LA',
    media_ocean_station: 'SZ',
  },
  {
    id: 613,
    name: 'Minneapolis-St. Paul MN',
    latitude: '44.944167',
    longitude: '-93.086075',
    state: 'MN',
    media_ocean_station: 'MS',
  },
  {
    id: 616,
    name: 'Kansas City MO',
    latitude: '39.0997265',
    longitude: '-94.5785667',
    state: 'MO',
    media_ocean_station: 'KC',
  },
  {
    id: 617,
    name: 'Milwaukee WI',
    latitude: '43.0389025',
    longitude: '-87.9064736',
    state: 'WI',
    media_ocean_station: 'MW',
  },
  {
    id: 618,
    name: 'Houston TX',
    latitude: '29.7628844',
    longitude: '-95.3830615',
    state: 'TX',
    media_ocean_station: 'HX',
  },
  {
    id: 619,
    name: 'Springfield MO',
    latitude: '37.215326',
    longitude: '-93.2982436',
    state: 'MO',
    media_ocean_station: 'SO',
  },
  {
    id: 622,
    name: 'New Orleans LA',
    latitude: '29.9647222',
    longitude: '-90.0705556',
    state: 'LA',
    media_ocean_station: 'NO',
  },
  {
    id: 623,
    name: 'Dallas-Ft. Worth TX',
    latitude: '32.725409',
    longitude: '-97.3208496',
    state: 'TX',
    media_ocean_station: 'DA',
  },
  {
    id: 624,
    name: 'Sioux City IA',
    latitude: '42.4999942',
    longitude: '-96.4003069',
    state: 'IA',
    media_ocean_station: 'SI',
  },
  {
    id: 625,
    name: 'Waco-Temple-Bryan TX',
    latitude: '30.6743643',
    longitude: '-96.3699632',
    state: 'TX',
    media_ocean_station: 'WA',
  },
  {
    id: 626,
    name: 'Victoria TX',
    latitude: '28.8052674',
    longitude: '-97.0035982',
    state: 'TX',
    media_ocean_station: 'VI',
  },
  {
    id: 627,
    name: 'Wichita Falls TX & Lawton OK',
    latitude: '33.6953791',
    longitude: '-98.3088441',
    state: 'OK',
    media_ocean_station: 'WI',
  },
  {
    id: 628,
    name: 'Monroe LA-El Dorado AR',
    latitude: '33.210973',
    longitude: '-92.665901',
    state: 'AR',
    media_ocean_station: 'MX',
  },
  {
    id: 630,
    name: 'Birmingham (Ann and Tusc) AL',
    latitude: '33.5206608',
    longitude: '-86.80249',
    state: 'AL',
    media_ocean_station: 'BM',
  },
  {
    id: 631,
    name: 'Ottumwa IA-Kirksville MO',
    latitude: '40.1947539',
    longitude: '-92.5832496',
    state: 'MO',
    media_ocean_station: 'OT',
  },
  {
    id: 632,
    name: 'Paducah KY-Cape Girardeau MO-Harrisburg-Mount Vernon IL',
    latitude: '38.3172714',
    longitude: '-88.9031201',
    state: 'IL',
    media_ocean_station: 'PD',
  },
  {
    id: 633,
    name: 'Odessa-Midland TX',
    latitude: '31.9973456',
    longitude: '-102.0779146',
    state: 'TX',
    media_ocean_station: 'OD',
  },
  {
    id: 634,
    name: 'Amarillo TX',
    latitude: '35.2219971',
    longitude: '-101.8312969',
    state: 'TX',
    media_ocean_station: 'AM',
  },
  {
    id: 635,
    name: 'Austin TX',
    latitude: '30.267153',
    longitude: '-97.7430608',
    state: 'TX',
    media_ocean_station: 'AZ',
  },
  {
    id: 636,
    name: 'Harlingen-Weslaco-Brownsville-McAllen TX',
    latitude: '26.2034071',
    longitude: '-98.2300124',
    state: 'TX',
    media_ocean_station: 'HW',
  },
  {
    id: 637,
    name: 'Cedar Rapids-Waterloo-Iowa City & Dubuque IA',
    latitude: '41.677204',
    longitude: '-91.5162792',
    state: 'IA',
    media_ocean_station: 'CE',
  },
  {
    id: 638,
    name: 'St. Joseph MO',
    latitude: '39.7577778',
    longitude: '-94.8363889',
    state: 'MO',
    media_ocean_station: 'SJ',
  },
  {
    id: 639,
    name: 'Jackson TN',
    latitude: '35.6145169',
    longitude: '-88.8139469',
    state: 'TN',
    media_ocean_station: 'JC',
  },
  {
    id: 640,
    name: 'Memphis TN',
    latitude: '35.1495343',
    longitude: '-90.0489801',
    state: 'TN',
    media_ocean_station: 'MP',
  },
  {
    id: 641,
    name: 'San Antonio TX',
    latitude: '29.4241219',
    longitude: '-98.4936282',
    state: 'TX',
    media_ocean_station: 'ST',
  },
  {
    id: 642,
    name: 'Lafayette LA',
    latitude: '30.2240897',
    longitude: '-92.0198427',
    state: 'LA',
    media_ocean_station: 'LY',
  },
  {
    id: 643,
    name: 'Lake Charles LA',
    latitude: '30.2265949',
    longitude: '-93.2173758',
    state: 'LA',
    media_ocean_station: 'LC',
  },
  {
    id: 644,
    name: 'Alexandria LA',
    latitude: '31.3112936',
    longitude: '-92.4451371',
    state: 'LA',
    media_ocean_station: 'AX',
  },
  {
    id: 647,
    name: 'Greenwood-Greenville MS',
    latitude: '33.4101161',
    longitude: '-91.0617735',
    state: 'MS',
    media_ocean_station: 'GG',
  },
  {
    id: 648,
    name: 'Champaign & Springfield-Decatur IL',
    latitude: '39.8403147',
    longitude: '-88.9548001',
    state: 'IL',
    media_ocean_station: 'CQ',
  },
  {
    id: 649,
    name: 'Evansville IN',
    latitude: '37.9747644',
    longitude: '-87.5558482',
    state: 'IN',
    media_ocean_station: 'EV',
  },
  {
    id: 650,
    name: 'Oklahoma City OK',
    latitude: '35.4675602',
    longitude: '-97.5164276',
    state: 'OK',
    media_ocean_station: 'OK',
  },
  {
    id: 651,
    name: 'Lubbock TX',
    latitude: '33.5778631',
    longitude: '-101.8551665',
    state: 'TX',
    media_ocean_station: 'LB',
  },
  {
    id: 652,
    name: 'Omaha NE',
    latitude: '41.254006',
    longitude: '-95.999258',
    state: 'NE',
    media_ocean_station: 'OM',
  },
  {
    id: 656,
    name: 'Panama City FL',
    latitude: '30.1588129',
    longitude: '-85.6602058',
    state: 'FL',
    media_ocean_station: 'PF',
  },
  {
    id: 657,
    name: 'Sherman-Ada, OK',
    latitude: '34.774531',
    longitude: '-96.6783449',
    state: 'OK',
    media_ocean_station: 'SH',
  },
  {
    id: 658,
    name: 'Green Bay-Appleton WI',
    latitude: '44.2619309',
    longitude: '-88.4153847',
    state: 'WI',
    media_ocean_station: 'GB',
  },
  {
    id: 659,
    name: 'Nashville TN',
    latitude: '36.1658899',
    longitude: '-86.7844432',
    state: 'TN',
    media_ocean_station: 'NA',
  },
  {
    id: 661,
    name: 'San Angelo TX',
    latitude: '31.4637723',
    longitude: '-100.4370375',
    state: 'TX',
    media_ocean_station: 'SX',
  },
  {
    id: 662,
    name: 'Abilene-Sweetwater TX',
    latitude: '32.4709519',
    longitude: '-100.4059384',
    state: 'TX',
    media_ocean_station: 'AB',
  },
  {
    id: 669,
    name: 'Madison WI',
    latitude: '43.0730517',
    longitude: '-89.4012302',
    state: 'WI',
    media_ocean_station: 'MD',
  },
  {
    id: 670,
    name: 'Ft. Smith-Fayetteville-Springdale-Rogers AR',
    latitude: '36.3320196',
    longitude: '-94.1185366',
    state: 'AR',
    media_ocean_station: 'FS',
  },
  {
    id: 671,
    name: 'Tulsa OK',
    latitude: '36.1539816',
    longitude: '-95.992775',
    state: 'OK',
    media_ocean_station: 'TK',
  },
  {
    id: 673,
    name: 'Columbus-Tupelo-West Point MS',
    latitude: '33.6076186',
    longitude: '-88.6503254',
    state: 'MS',
    media_ocean_station: 'CF',
  },
  {
    id: 675,
    name: 'Peoria-Bloomington IL',
    latitude: '40.4842027',
    longitude: '-88.9936873',
    state: 'IL',
    media_ocean_station: 'PE',
  },
  {
    id: 676,
    name: 'Duluth MN-Superior WI',
    latitude: '46.7207737',
    longitude: '-92.1040796',
    state: 'WI',
    media_ocean_station: 'DU',
  },
  {
    id: 678,
    name: 'Wichita-Hutchinson KS',
    latitude: '38.0608445',
    longitude: '-97.9297743',
    state: 'KS',
    media_ocean_station: 'WC',
  },
  {
    id: 679,
    name: 'Des Moines-Ames IA',
    latitude: '42.02335',
    longitude: '-93.625622',
    state: 'IA',
    media_ocean_station: 'DM',
  },
  {
    id: 682,
    name: 'Davenport IA-Rock Island-Moline IL',
    latitude: '41.5067003',
    longitude: '-90.5151342',
    state: 'IL',
    media_ocean_station: 'DR',
  },
  {
    id: 686,
    name: 'Mobile AL-Pensacola (Ft. Walton Beach) FL',
    latitude: '30.421309',
    longitude: '-87.2169149',
    state: 'FL',
    media_ocean_station: 'MO',
  },
  {
    id: 687,
    name: 'Minot-Bismarck-Dickinson(Williston) ND',
    latitude: '48.1469683',
    longitude: '-103.6179745',
    state: 'ND',
    media_ocean_station: 'BN',
  },
  {
    id: 691,
    name: 'Huntsville-Decatur (Florence) AL',
    latitude: '34.79981',
    longitude: '-87.677251',
    state: 'AL',
    media_ocean_station: 'HU',
  },
  {
    id: 692,
    name: 'Beaumont-Port Arthur TX',
    latitude: '29.8849504',
    longitude: '-93.939947',
    state: 'TX',
    media_ocean_station: 'BE',
  },
  {
    id: 693,
    name: 'Little Rock-Pine Bluff AR',
    latitude: '34.2284312',
    longitude: '-92.0031955',
    state: 'AR',
    media_ocean_station: 'LP',
  },
  {
    id: 698,
    name: 'Montgomery-Selma, AL',
    latitude: '32.4073589',
    longitude: '-87.0211007',
    state: 'AL',
    media_ocean_station: 'MG',
  },
  {
    id: 702,
    name: 'La Crosse-Eau Claire WI',
    latitude: '44.811349',
    longitude: '-91.4984941',
    state: 'WI',
    media_ocean_station: 'LO',
  },
  {
    id: 705,
    name: 'Wausau-Rhinelander WI',
    latitude: '45.6366228',
    longitude: '-89.4120753',
    state: 'WI',
    media_ocean_station: 'WR',
  },
  {
    id: 709,
    name: 'Tyler-Longview(Lufkin & Nacogdoches) TX',
    latitude: '31.6035129',
    longitude: '-94.6554874',
    state: 'TX',
    media_ocean_station: 'TY',
  },
  {
    id: 710,
    name: 'Hattiesburg-Laurel MS',
    latitude: '31.6940509',
    longitude: '-89.1306124',
    state: 'MS',
    media_ocean_station: 'LT',
  },
  {
    id: 711,
    name: 'Meridian MS',
    latitude: '32.3643098',
    longitude: '-88.703656',
    state: 'MS',
    media_ocean_station: 'DN',
  },
  {
    id: 716,
    name: 'Baton Rouge LA',
    latitude: '30.4507462',
    longitude: '-91.154551',
    state: 'LA',
    media_ocean_station: 'BR',
  },
  {
    id: 717,
    name: 'Quincy IL-Hannibal MO-Keokuk IA',
    latitude: '40.402525',
    longitude: '-91.394372',
    state: 'IA',
    media_ocean_station: 'QH',
  },
  {
    id: 718,
    name: 'Jackson MS',
    latitude: '32.2987573',
    longitude: '-90.1848103',
    state: 'MS',
    media_ocean_station: 'JA',
  },
  {
    id: 722,
    name: 'Lincoln & Hastings-Kearney NE',
    latitude: '40.699959',
    longitude: '-99.083107',
    state: 'NE',
    media_ocean_station: 'LH',
  },
  {
    id: 724,
    name: 'Fargo-Valley City ND',
    latitude: '46.9233129',
    longitude: '-98.0031547',
    state: 'ND',
    media_ocean_station: 'FM',
  },
  {
    id: 725,
    name: 'Sioux Falls(Mitchell) SD',
    latitude: '43.5499749',
    longitude: '-96.700327',
    state: 'SD',
    media_ocean_station: 'SU',
  },
  {
    id: 734,
    name: 'Jonesboro AR',
    latitude: '35.8422967',
    longitude: '-90.704279',
    state: 'AR',
    media_ocean_station: 'JN',
  },
  {
    id: 736,
    name: 'Bowling Green KY',
    latitude: '36.9903199',
    longitude: '-86.4436018',
    state: 'KY',
    media_ocean_station: 'BW',
  },
  {
    id: 737,
    name: 'Mankato MN',
    latitude: '44.1635775',
    longitude: '-93.9993996',
    state: 'MN',
    media_ocean_station: 'MN',
  },
  {
    id: 740,
    name: 'North Platte NE',
    latitude: '41.1238873',
    longitude: '-100.7654232',
    state: 'NE',
    media_ocean_station: 'NP',
  },
  {
    id: 743,
    name: 'Anchorage AK',
    latitude: '61.2180556',
    longitude: '-149.9002778',
    state: 'AK',
    media_ocean_station: 'AN',
  },
  {
    id: 744,
    name: 'Honolulu HI',
    latitude: '21.3069444',
    longitude: '-157.8583333',
    state: 'HI',
    media_ocean_station: 'HO',
  },
  {
    id: 745,
    name: 'Fairbanks AK',
    latitude: '64.8377778',
    longitude: '-147.7163889',
    state: 'AK',
    media_ocean_station: 'FB',
  },
  {
    id: 746,
    name: 'Biloxi-Gulfport MS',
    latitude: '30.3674198',
    longitude: '-89.0928155',
    state: 'MS',
    media_ocean_station: 'BX',
  },
  {
    id: 747,
    name: 'Juneau AK',
    latitude: '58.3019444',
    longitude: '-134.4197222',
    state: 'AK',
    media_ocean_station: 'JU',
  },
  {
    id: 749,
    name: 'Laredo TX',
    latitude: '27.506407',
    longitude: '-99.5075421',
    state: 'TX',
    media_ocean_station: 'LR',
  },
  {
    id: 751,
    name: 'Denver CO',
    latitude: '39.7391536',
    longitude: '-104.9847034',
    state: 'CO',
    media_ocean_station: 'DV',
  },
  {
    id: 752,
    name: 'Colorado Springs-Pueblo CO',
    latitude: '38.2544472',
    longitude: '-104.6091409',
    state: 'CO',
    media_ocean_station: 'CO',
  },
  {
    id: 753,
    name: 'Phoenix AZ',
    latitude: '33.4483771',
    longitude: '-112.0740373',
    state: 'AZ',
    media_ocean_station: 'PX',
  },
  {
    id: 754,
    name: 'Butte-Bozeman MT',
    latitude: '45.68346',
    longitude: '-111.050499',
    state: 'MT',
    media_ocean_station: 'BZ',
  },
  {
    id: 755,
    name: 'Great Falls MT',
    latitude: '47.5002354',
    longitude: '-111.3008083',
    state: 'MT',
    media_ocean_station: 'GL',
  },
  {
    id: 756,
    name: 'Billings, MT',
    latitude: '45.7832856',
    longitude: '-108.5006904',
    state: 'MT',
    media_ocean_station: 'BI',
  },
  {
    id: 757,
    name: 'Boise ID',
    latitude: '43.613739',
    longitude: '-116.237651',
    state: 'ID',
    media_ocean_station: 'BS',
  },
  {
    id: 758,
    name: 'Idaho Falls-Pocatello ID',
    latitude: '42.8713032',
    longitude: '-112.4455344',
    state: 'ID',
    media_ocean_station: 'IF',
  },
  {
    id: 759,
    name: 'Cheyenne WY-Scottsbluff NE',
    latitude: '41.86714',
    longitude: '-103.660709',
    state: 'NE',
    media_ocean_station: 'CW',
  },
  {
    id: 760,
    name: 'Twin Falls ID',
    latitude: '42.5629668',
    longitude: '-114.4608711',
    state: 'ID',
    media_ocean_station: 'TW',
  },
  {
    id: 762,
    name: 'Missoula MT',
    latitude: '46.872146',
    longitude: '-113.9939982',
    state: 'MT',
    media_ocean_station: 'MT',
  },
  {
    id: 764,
    name: 'Rapid City SD',
    latitude: '44.0805434',
    longitude: '-103.2310149',
    state: 'SD',
    media_ocean_station: 'RC',
  },
  {
    id: 765,
    name: 'El Paso TX',
    latitude: '31.7587198',
    longitude: '-106.4869314',
    state: 'TX',
    media_ocean_station: 'EL',
  },
  {
    id: 766,
    name: 'Helena MT',
    latitude: '46.595805',
    longitude: '-112.027031',
    state: 'MT',
    media_ocean_station: 'HE',
  },
  {
    id: 767,
    name: 'Casper-Riverton WY',
    latitude: '43.0249592',
    longitude: '-108.3801036',
    state: 'WY',
    media_ocean_station: 'CA',
  },
  {
    id: 770,
    name: 'Salt Lake City UT',
    latitude: '40.7607793',
    longitude: '-111.8910474',
    state: 'UT',
    media_ocean_station: 'SC',
  },
  {
    id: 771,
    name: 'Yuma AZ-El Centro CA',
    latitude: '32.792',
    longitude: '-115.5630514',
    state: 'CA',
    media_ocean_station: 'YE',
  },
  {
    id: 773,
    name: 'Grand Junction-Montrose CO',
    latitude: '38.4783198',
    longitude: '-107.8761738',
    state: 'CO',
    media_ocean_station: 'GR',
  },
  {
    id: 789,
    name: 'Tucson (Sierra Vista) AZ',
    latitude: '32.2217429',
    longitude: '-110.926479',
    state: 'AZ',
    media_ocean_station: 'TU',
  },
  {
    id: 790,
    name: 'Albuquerque-Santa Fe NM',
    latitude: '35.6869752',
    longitude: '-105.937799',
    state: 'NM',
    media_ocean_station: 'AQ',
  },
  {
    id: 798,
    name: 'Glendive MT',
    latitude: '47.108491',
    longitude: '-104.710419',
    state: 'MT',
    media_ocean_station: 'GD',
  },
  {
    id: 800,
    name: 'Bakersfield CA',
    latitude: '35.3732921',
    longitude: '-119.0187125',
    state: 'CA',
    media_ocean_station: 'BA',
  },
  {
    id: 801,
    name: 'Eugene OR',
    latitude: '44.0520691',
    longitude: '-123.0867536',
    state: 'OR',
    media_ocean_station: 'EU',
  },
  {
    id: 802,
    name: 'Eureka CA',
    latitude: '40.8020712',
    longitude: '-124.1636729',
    state: 'CA',
    media_ocean_station: 'EK',
  },
  {
    id: 803,
    name: 'Los Angeles CA',
    latitude: '34.0522342',
    longitude: '-118.2436849',
    state: 'CA',
    media_ocean_station: 'LA',
  },
  {
    id: 804,
    name: 'Palm Springs CA',
    latitude: '33.8302961',
    longitude: '-116.5452921',
    state: 'CA',
    media_ocean_station: 'PS',
  },
  {
    id: 807,
    name: 'San Francisco-Oakland-San Jose CA',
    latitude: '37.3393857',
    longitude: '-121.8949555',
    state: 'CA',
    media_ocean_station: 'SF',
  },
  {
    id: 810,
    name: 'Yakima-Pasco-Richland-Kennewick WA',
    latitude: '46.2112458',
    longitude: '-119.1372338',
    state: 'WA',
    media_ocean_station: 'YA',
  },
  {
    id: 811,
    name: 'Reno NV',
    latitude: '39.5296329',
    longitude: '-119.8138027',
    state: 'NV',
    media_ocean_station: 'RE',
  },
  {
    id: 813,
    name: 'Medford-Klamath Falls OR',
    latitude: '42.224867',
    longitude: '-121.7816704',
    state: 'OR',
    media_ocean_station: 'ME',
  },
  {
    id: 819,
    name: 'Seattle-Tacoma WA',
    latitude: '47.2528768',
    longitude: '-122.4442906',
    state: 'WA',
    media_ocean_station: 'SE',
  },
  {
    id: 820,
    name: 'Portland OR',
    latitude: '45.5234515',
    longitude: '-122.6762071',
    state: 'OR',
    media_ocean_station: 'PO',
  },
  {
    id: 821,
    name: 'Bend OR',
    latitude: '44.0581728',
    longitude: '-121.3153096',
    state: 'OR',
    media_ocean_station: 'BD',
  },
  {
    id: 825,
    name: 'San Diego CA',
    latitude: '32.7153292',
    longitude: '-117.1572551',
    state: 'CA',
    media_ocean_station: 'SD',
  },
  {
    id: 828,
    name: 'Monterey-Salinas CA',
    latitude: '36.6777372',
    longitude: '-121.6555013',
    state: 'CA',
    media_ocean_station: 'SS',
  },
  {
    id: 839,
    name: 'Las Vegas NV',
    latitude: '36.114646',
    longitude: '-115.172816',
    state: 'NV',
    media_ocean_station: 'LV',
  },
  {
    id: 855,
    name: 'Santa Barbara-Santa Maria-San Luis Obispo CA',
    latitude: '35.2827524',
    longitude: '-120.6596156',
    state: 'CA',
    media_ocean_station: 'SB',
  },
  {
    id: 862,
    name: 'Sacramento-Stockton-Modesto CA',
    latitude: '37.6390972',
    longitude: '-120.9968782',
    state: 'CA',
    media_ocean_station: 'SA',
  },
  {
    id: 866,
    name: 'Fresno-Visalia CA',
    latitude: '36.3302284',
    longitude: '-119.2920585',
    state: 'CA',
    media_ocean_station: 'FR',
  },
  {
    id: 868,
    name: 'Chico-Redding CA',
    latitude: '40.5865396',
    longitude: '-122.3916754',
    state: 'CA',
    media_ocean_station: 'CR',
  },
  {
    id: 881,
    name: 'Spokane WA',
    latitude: '47.6587802',
    longitude: '-117.4260466',
    state: 'WA',
    media_ocean_station: 'SW',
  },
]

export default dmas
