import { clone as _clone } from 'lodash'
import moment from 'moment'
import Model from './interface/Model'
import MediaPlan from './MediaPlan'
import Reconciliation from './Reconciliation'

export default class InvoiceItem extends Model {
  public id: null | string = null

  public number: number = 1

  public invoice_id: string = ''

  public type: string = 'reconciliation'

  public name: string = ''

  public start_at: string = ''

  public end_at: string = ''

  public product: string = 'SSL'

  public booked: number = 0

  public delivered: number = 0

  public billable: number = 0

  public model: string = 'cpm'

  public gross_rate: number = 0

  public net_rate: number = 0

  public cost_total: number = 0

  public expense_total: number = 0

  public cost_rate: number = 0

  public notes: null | string = null

  public reconciliation_ids: string[] = []

  public reconciliations: Reconciliation[] = []

  public metadata: any = {
    run_code: 'Y',
    run_date: '',
    time_of_day: '1900',
    type: '30',
    copy_id: '',
    copy_duration: 0,
    rate: '',
    class: '',
    piggyback: '',
    makegood_date_1: '',
    makegood_date_2: '',
    makegood_time_1: '',
    makegood_time_2: '',
    make_good_line: '',
    adjustment_dr: '',
    adjustment_cr: '',
    program_name: '',
    billboard_indicator: '',
    order_type: 'cash',
    length: '',
    video_copy_id: '',
    audio_copy_id: '',
    serial_number: '',
    network: '',
    integration_cost: '',
    package: '',
    spots: 1,
  }

  public get gross_total() {
    if (this.model === 'cpm') return this.gross_rate * (this.billable / 1000)
    if (this.model === 'flat') return this.gross_rate

    return this.gross_rate * this.billable
  }

  public get net_total() {
    if (this.metadata.order_type === 'trade') return 0
    if (this.model === 'cpm') return this.net_rate * (this.billable / 1000)
    if (this.model === 'flat') return this.net_rate

    return this.net_rate * this.billable
  }

  public get flight_dates() {
    if (!this.start_at && !this.end_at) return '-'

    if (!this.start_at && this.end_at) {
      return moment(this.end_at).format('MM/DD/YYYY')
    }

    if (this.start_at && !this.end_at) {
      return moment(this.start_at).format('MM/DD/YYYY')
    }

    return `${moment(this.start_at).format('MM/DD/YYYY')} - ${moment(this.end_at).format(
      'MM/DD/YYYY',
    )}`
  }

  public get system_impressions(): number {
    return this.reconciliations.reduce((carry, r) => carry + r.impressions, 0)
  }

  public get reconciliated_impressions(): number {
    return this.reconciliations.reduce((carry, r) => carry + r.reconciliated_impressions, 0)
  }

  // Functions
  public fromReconciliations(reconciliations: Reconciliation[]) {
    // Copy over the reconcilations
    this.reconciliations = _clone(reconciliations)

    // Map the reconciliation ids
    this.reconciliation_ids = this.reconciliations.map(reconciliation => reconciliation.id)

    // consolidate reconciliations into item
    let accounted_media_plan_items: any[] = []
    let base = this.reconciliations.reduce(
      (carry: any, r) => {
        if (!accounted_media_plan_items.includes(r.media_plan_item?.id)) {
          accounted_media_plan_items.push(r.media_plan_item?.id)
          carry.booked += r.media_plan_item?.impressions
          carry.delivered += r.media_plan_item?.metrics.impressions
        }

        carry.billable += r.billable_impressions
        if (r.model == 'flat') {
          carry.gross_total += r.revenue_rate
          carry.net_total += r.revenue_net_rate
        } else if (r.billing_mode !== 'bonus') {
          carry.gross_total += (r.billable_impressions / 1000) * r.revenue_rate
          carry.net_total += (r.billable_impressions / 1000) * r.revenue_net_rate
        }
        carry.cost_total += r.cost_total
        return carry
      },
      {
        booked: 0,
        delivered: 0,
        billable: 0,
        gross_total: 0,
        net_total: 0,
        cost_total: 0,
      },
    )

    let is_all_booked = this.reconciliations.reduce(
      (carry: any, r) => carry && (r.billing_mode === 'booked' || r.billing_mode === 'booked_cap'),
      true,
    )

    this.booked = base.booked
    this.delivered = base.delivered
    this.billable = base.billable

    let flat_rate_items = this.reconciliations.filter(r => r.model == 'flat')
    let cpm_rate_items = this.reconciliations.filter(r => r.model == 'cpm')

    if (flat_rate_items.length > 0 && cpm_rate_items.length == 0) {
      this.gross_rate = flat_rate_items.reduce((carry: number, r: any) => carry + r.revenue_rate, 0)
      this.net_rate = flat_rate_items.reduce(
        (carry: number, r: any) => carry + r.revenue_net_rate,
        0,
      )
    } else if (flat_rate_items.length == 0 && cpm_rate_items.length > 0) {
      this.gross_rate = base.billable == 0 ? 0 : base.gross_total / (base.billable / 1000)
      this.net_rate = base.billable == 0 ? 0 : base.net_total / (base.billable / 1000)
    } else {
      this.gross_rate = base.billable == 0
        ? 0
        : base.gross_total / (base.billable / 1000)
            + flat_rate_items.reduce((carry: number, r: any) => carry + r.revenue_rate, 0)
      this.net_rate = base.billable == 0
        ? 0
        : base.net_total / (base.billable / 1000)
            + flat_rate_items.reduce((carry: number, r: any) => carry + r.revenue_net_rate, 0)
    }

    this.cost_rate = base.billable == 0 ? 0 : base.cost_total / (base.billable / 1000)

    // Enforce cap if billibale is greater than booked (happens if has more than one entry of the same line item)
    if (is_all_booked && base.billable > base.booked) {
      base.billable = base.booked
      this.billable = base.booked
    }

    if (isNaN(this.gross_rate)) this.gross_rate = 0
    if (isNaN(this.net_rate)) this.net_rate = 0

    let target_plan = _clone(reconciliations[0].media_plan)
    let target_item = _clone(reconciliations[0].media_plan_item)
    if (target_plan && target_item) {
      target_item.type = target_plan.type
      target_item.agency_commission = target_plan.agency_commission
    }

    // Get min date from reconciliation items
    this.product = reconciliations[0].product.toUpperCase()
    this.model = reconciliations[0].model
    this.start_at = reconciliations.reduce((carry: any, r) => {
      if (!carry) return r.media_plan_item?.schedule_start_at
      if (
        r.media_plan_item?.schedule_start_at
        && moment(r.media_plan_item?.schedule_start_at).isBefore(moment(carry))
      ) {
        return r.media_plan_item?.schedule_start_at
      }
      return carry
    }, null)

    // Get max date from reconciliation items
    this.end_at = reconciliations.reduce((carry: any, r) => {
      if (!carry) return r.media_plan_item?.schedule_end_at
      if (
        r.media_plan_item?.schedule_end_at
        && moment(r.media_plan_item?.schedule_end_at).isAfter(moment(carry))
      ) {
        return r.media_plan_item?.schedule_end_at
      }
      return carry
    }, null)

    let accounted_items: Array<any> = []
    let spots = reconciliations.reduce((carry: any, r) => {
      if (!accounted_items.includes(r.media_plan_item?.id)) {
        carry += r.media_plan_item?.metadata.spots.reduce((c: number, s: number) => c + s, 0)
        accounted_items.push(r.media_plan_item?.id)
      }

      return carry
    }, 0)

    // If is Media Ocean,
    if (target_plan && target_item && target_plan.isLinear) {
      // Assign Media Ocean metadata from MediaPlan

      let run_date = moment(
        `${target_item.schedule_start_at} ${target_item.metadata.flight_time.start_at}`,
      )
      if (target_item.metadata.order_type.toLowerCase() == 'trade') {
        run_date = run_date.hour(23)
      }

      this.metadata = {
        ...this.metadata,
        ...{
          run_date: run_date.format('YYYY-MM-DD HH:mm:ss'),
          program_name: target_item.metadata.program_name,
          rate: target_item.gross_cost.toFixed(2).replaceAll('.', ''),
          copy_id: target_item.topISCI,
          copy_duration: target_item.topISCIDuration,
          order_type: target_item.metadata.order_type.toLowerCase(),
          type: String(target_item.creative_length) ?? '30',
          video_copy_id: MediaPlan.buildVideoCopyId(target_plan, target_item),
          spots,
        },
      }
    } else if (target_plan && target_item) {
      this.metadata = {
        ...this.metadata,
        ...{
          copy_id: target_item.topISCI,
          copy_duration: target_item.topISCIDuration,
        },
      }
    }
  }

  public get apiData(): any {
    return {
      id: this.id,
      number: this.number,
      name: this.name,
      type: this.type,
      start_at: moment(this.start_at).format('YYYY-MM-DD'),
      end_at: moment(this.end_at).format('YYYY-MM-DD'),
      product: this.product,
      booked: this.booked,
      delivered: this.delivered,
      billable: this.billable,
      model: this.model,
      net_rate: this.net_rate,
      gross_rate: this.gross_rate,
      gross_total: this.gross_total,
      net_total: this.net_total,
      cost_rate: this.cost_rate,
      cost_total: this.cost_total,
      notes: this.notes,
      metadata: this.metadata,
      reconciliation_ids: this.reconciliation_ids,
    }
  }

  public toObject(source: any) {
    let instance = this.clone()

    // Remove dynamic data
    if (source.gross_total !== undefined) delete source.gross_total
    if (source.net_total !== undefined) delete source.net_total

    Object.assign(instance, source)

    if (source.start_at) {
      instance.start_at = source.start_at.includes('T')
        ? source.start_at.split('T')[0]
        : moment(source.start_at).format('YYYY-MM-DD')
    }

    if (source.end_at) {
      instance.end_at = source.end_at.includes('T')
        ? source.end_at.split('T')[0]
        : moment(source.end_at).format('YYYY-MM-DD')
    }

    if (source.reconciliations) {
      instance.reconciliations = Reconciliation.toObjectList(source.reconciliations)
    }

    // Map the reconciliation ids
    instance.reconciliation_ids = instance.reconciliations.map(
      reconciliation => reconciliation.id,
    )

    const base = new InvoiceItem()
    if (source.metadata) {
      instance.metadata = { ...base.metadata, ...source.metadata }
    } else {
      instance.metadata = { ...base.metadata }
    }

    return instance
  }
}
