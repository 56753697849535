
import Vue from 'vue'
import Pdf from 'pdfvuer'
import 'pdfjs-dist/build/pdf.worker.entry'
import Api from '@/models/Api'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import IconAction from '@/components/IconAction/IconAction.vue'
import Preview from './Preview.vue'
import SideNavigator from './SideNavigator.vue'

@Component({
  components: {
    Pdf,
    Preview,
    SideNavigator,
    IconAction,
  },
})
export default class PdfModal extends Vue {
  @Prop()
  public file_path!: any

  @Prop()
  public modal_open!: any

  @Prop({ default: false })
  public custom_path!: any

  @Prop()
  public previewing_type!: any

  @Prop()
  public media_plan!: any

  @Prop({ default: false })
  public custom_download!: any

  @Watch('modal_open')
  public onModalOpenChange(val: any) {
    this.modal.pdf = val
  }

  public scale: number = 1

  public get formattedZoom() {
    return Number.parseInt(String(this.scale * 100))
  }

  public page_navigator: any = true

  public file_content: any = null

  public pdfSettings = {
    page: 1,
    numPages: 0,
  }

  public modal: any = {
    pdf: true,
  }

  public pdf_settings: any = {
    per_size: 0,
    original_pdf_width: 0,
    size: 1,
  }

  @Watch('pdf_settings.size')
  public onPdfSettingsSizeChange(val: any) {
    if (val) this.resizeModal(val)
  }

  public mounted() {
    setTimeout(() => {
      this.resizeModal()
    }, 800)
  }

  public toggleSideNavigator() {
    this.page_navigator = !this.page_navigator
  }

  public closePdfPreview() {
    this.modal.pdf = false
    this.$emit('close')
  }

  public downloadFile(type: any = this.previewing_type) {
    if (this.custom_download) {
      this.$emit('download', this.file_path)
      return
    }

    let name = 'Client Insertion Order'

    if (type === 'trade_io') {
      name = 'Client Insertion Order - Trade'
    } else if (this.media_plan.isLinear) {
      name = 'Client Insertion Order - Cash'
    }

    new Api().download(
      this.file_path,
      `#${this.media_plan.number} - ${this.media_plan.name} - ${name}.pdf`,
    )
  }

  public beforeDestroy() {}

  public resizeModal(size: any = 1) {
    let top_gap = document.getElementsByClassName('modal-pdf-preview')[0].offsetTop
    let screen_width = window.screen.width
    let modal_dialog: any = document.getElementsByClassName('modal-dialog')
    let pdf_body: any = document.getElementsByClassName('pdf-body')
    const preview_container = document.querySelector('.pdf-preview-container')

    // adjust modal width to screen width, with gaps on both sides
    if (modal_dialog && modal_dialog.length) {
      modal_dialog = modal_dialog[0]
      modal_dialog.style.maxWidth = `${screen_width - top_gap * 2}px`
    }

    if (pdf_body && pdf_body.length) {
      pdf_body = pdf_body[0]
      if (this.pdf_settings.per_size === 0) {
        this.pdf_settings.original_pdf_width = pdf_body.clientWidth
        this.pdf_settings.per_size = pdf_body.clientWidth / 8
      }

      if (size === 1) {
        pdf_body.style.width = '100%'
      } else {
        pdf_body.style.width = `${
          this.pdf_settings.original_pdf_width + this.pdf_settings.per_size * size
        }px`
      }

      // always center the horizontal scroll
      if (preview_container) {
        const _width = pdf_body.offsetWidth
        const parentWidth = preview_container.offsetWidth
        const scrollLeft = (_width - parentWidth) / 2
        preview_container.scrollLeft = scrollLeft
      }
    }
  }
}
