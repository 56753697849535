export default [
  {
    key: 'number',
    label: 'Invoice Numebr',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'name',
    label: 'Invoice Name',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'type',
    label: 'Type',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'date',
    label: 'Date',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'client',
    label: 'Client',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'client_invoice_number',
    label: 'Client Invoice Number',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'expanse_name',
    label: 'Expense Name',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'expanse_date',
    label: 'Expense Date',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'expanse_deduct',
    label: 'Expense Deduct',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'expanse_deduct',
    label: 'Expense Deduct',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'expanse_deduct_mode',
    label: 'Expense Deduct Mode',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'expanse_deduct_date',
    label: 'Expense Deduct Date',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'associated_media_plan_count',
    label: 'Associated Media Plan Count',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'amount',
    label: 'Amount',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
]
