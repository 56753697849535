
import { Component, Ref } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import ReportQuery from '@/models/ReportQuery'
import moment from 'moment'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import home_report_fields from './home-report-fields'

@Component({
  components: {
    Widget,
    IconAction,
    SearchInput,
  },
})
export default class ReportHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public report: ReportQuery = new ReportQuery()

  public sort_by: string = 'created_at'

  public sort_desc: boolean = true

  public page_size: number = 25

  public page: number = 1

  public records: number = 0

  public loading: boolean = false

  public ready: boolean = false

  public query: string[] = []

  public modal: any = {
    delete: false,
    send: false,
  }

  public fields: object[] = home_report_fields

  public query_settings: any = {
    company_fields: [
      {
        name: 'company',
        key: 'company_id',
      },
      {
        name: 'station',
        key: 'station_id',
        type: 'station',
      },
    ],
    user_fields: [
      {
        name: 'owner',
        key: 'user_id',
      },
    ],
    custom_fields: [
      {
        name: 'period:none',
        value: 'period:none',
      },
      {
        name: 'period:daily',
        value: 'period:daily',
      },
      {
        name: 'period:weekly',
        value: 'period:weekly',
      },
      {
        name: 'period:monthly',
        value: 'period:monthly',
      },
      {
        name: 'period:yearly',
        value: 'period:yearly',
      },
      {
        name: 'station:n/a',
        value: 'station_id:',
      },
    ],
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public mounted() {
    this.loadFilters()
  }

  public reports(context: any) {
    this.loading = true
    this.syncFilters()
    return ReportQuery.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: context.filter,
    }).then(result => {
      this.records = result.records
      this.loading = false
      return result.data
    })
  }

  public confirmDelete(report: ReportQuery) {
    this.report = report
    this.modal.delete = true
  }

  public confirmSend(report: ReportQuery) {
    this.report = report
    this.modal.send = true
  }

  public createReport() {
    this.$router.push('/app/report')
  }

  public editReport(report: ReportQuery) {
    if (report.system_report && !this.user.isRoot) {
      return report.id ? this.cloneReport(report.id) : this.createReport()
    }
    this.$router.push(`/app/report/${report.id}`)
  }

  public deleteReport() {
    this.report.delete()
  }

  public cloneReport(id: string) {
    this.$router.push(`/app/report?from=${id}`)
  }

  public sendReport() {
    this.report.send()
  }

  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'report-home',
      data: { query: this.query },
    })
  }

  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('report-home').then((filter: any) => {
      if (filter) {
        this.query = filter.query
      }
      this.ready = true
    })
  }

  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'report-home',
      data: null,
    })
  }
}
