export default [
  {
    key: 'past_due_date',
    label: 'Day Past Due',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'month',
    label: 'Month',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    type: 'date',
  },
  {
    key: 'date',
    label: 'Date',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    type: 'date',
  },
  {
    key: 'transaction_type',
    label: 'Transaction Type',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'number',
    label: 'Number',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'due_at',
    label: 'Due Date',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    type: 'date',
  },
  {
    key: 'amount',
    label: 'Amount',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    type: 'currency',
  },
  {
    key: 'open_balance',
    label: 'Open Balance',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    type: 'currency',
  },
  {
    key: 'sales_manager',
    label: 'Sales Management',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'sales_rep',
    label: 'Sales Rep',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'account_manager',
    label: 'Account Manager',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'seconday_sales_reps',
    label: 'Seconday Sales Reps',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'media_plan',
    label: 'Media Plan',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'opportunity_name',
    label: 'Opportunity',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'client',
    label: 'Client',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'email',
    label: 'Email',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'phone_numbers',
    label: 'Phone Numbers',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
]
