export default [
  {
    key: 'flight_date',
    label: 'Flight Month',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'billing_month',
    label: 'Billing Month',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'number',
    label: '#',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'name',
    label: 'Name',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'type',
    label: 'Type',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'client',
    label: 'Client',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'media_plan_number',
    label: 'Media Plan #',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'media_plan_name',
    label: 'Media Plan',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'sales_management',
    label: 'Sales Management',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'sales_rep',
    label: 'Sales Rep',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'created_at',
    label: 'Invoice date',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'paid_at',
    label: 'Paid At',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
    type: 'date',
  },
  {
    key: 'status',
    label: 'Status',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'trade_status',
    label: 'Trade Status',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'revision',
    label: 'Revision',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'invoice_count',
    label: 'Invoice Count',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'gross_total',
    label: 'Total Gross',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'net_total',
    label: 'Total Net',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'total_paid',
    label: 'Total Paid',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'media_cost',
    label: 'Media Cost',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
    type: 'currency',
  },
  {
    key: 'expenses',
    label: 'Expenses',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
    type: 'currency',
  },
  {
    key: 'profit',
    label: 'profit',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
    type: 'currency',
  },
]
