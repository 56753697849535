import BigNumber from 'bignumber.js'
import { startCase } from 'lodash'
import moment from 'moment'
// @ts-ignore
import { Md5 } from 'ts-md5/dist/md5'
import Api from './Api'
import PaginateOptions from './interface/PaginateOptions'
import Invoice from './Invoice'
import MediaPlan from './MediaPlan'
import MediaPlanItem from './MediaPlanItem'
import ReconciliationClient from './ReconciliationClient'
import WebMessage from './WebMessage'

export default class Reconciliation {
  public id: string = ''

  public impressions: number = 0

  public reconciliated_impressions: number = 0

  public past_reconciliated_impressions: number = 0

  public billing_month: string = ''

  public advertiser_id: string = ''

  public media_plan_id: string = ''

  public billing_source: string = ''

  public billing_calendar: string = ''

  public billing_mode: string = ''

  public revenue_rate: number = 0

  public revenue_net_rate: number = 0

  public cost_rate: number = 0

  public status: string = ''

  public line_item_id: string = ''

  public code: string = ''

  public name: string = ''

  public number: number = 0

  public start_at: string = ''

  public end_at: string = ''

  public booked_impressions: number = 0

  public open_ended: boolean = false

  public booked_revenue: number = 0

  public booked_month_impressions: number = 0

  public booked_month_revenue: number = 0

  public lifetime_impressions: number = 0

  public inventory_id: string = ''

  public inventory_name: string = ''

  public dynamic_cost_rate_id: null | string = null

  public order_type: string = 'cash'

  public type: string = ''

  public invoice_id: string = ''

  public invoice_item_id: string = ''

  public metadata: any = {}

  public media_plan: null | MediaPlan = null

  public media_plan_item_id: string = ''

  public media_plan_item: null | MediaPlanItem = null

  public cost_ready: boolean = false

  public model: string = 'cpm'

  public product: string = 'ssl'

  public _selected: boolean = false

  public get invoiceSingleKey() {
    return `${this.model}_${this.product}`
  }

  public get gross_total() {
    let gross_rate = this.revenue_total / (this.impressions / 1000)
    return gross_rate * (this.impressions / 1000)
  }

  public get mp_booked_impressions(): number {
    if (this.media_plan_item) {
      return this.media_plan_item.impressions
    }
    return this.booked_impressions
  }

  public get mp_booked_cap_limit(): number {
    if (this.media_plan_item) {
      return this.media_plan_item.impressions <= this.past_reconciliated_impressions
        ? 0
        : this.media_plan_item.impressions - this.past_reconciliated_impressions
    }
    return this.booked_impressions <= this.past_reconciliated_impressions
      ? 0
      : this.booked_impressions - this.past_reconciliated_impressions
  }

  // Computed properties
  public get billable_impressions(): number {
    if (this.billing_mode === 'booked') {
      return this.mp_booked_cap_limit
    }

    let impressions = 0

    if (this.billing_source === 'first_party') {
      impressions = this.impressions
    } else if (this.billing_source === 'third_party') {
      impressions = this.reconciliated_impressions
    }

    if (this.billing_mode === 'booked_plus') {
      return impressions > this.mp_booked_impressions ? impressions : this.mp_booked_impressions
    }
    if (this.billing_mode === 'booked_cap') {
      return impressions > this.mp_booked_cap_limit ? this.mp_booked_cap_limit : impressions
    }
    if (this.billing_mode === 'discard' || this.billing_mode === 'already_billed') {
      return 0
    }

    return impressions
  }

  public get revenue_total(): number {
    if (this.model === 'flat') return this.revenue_rate
    return new BigNumber(this.revenue_rate)
      .times(new BigNumber(this.billable_impressions).div(1000))
      .toNumber()
  }

  public get revenue_net_total(): number {
    if (this.model === 'flat') return this.revenue_net_rate
    return new BigNumber(this.revenue_net_rate)
      .times(new BigNumber(this.billable_impressions).div(1000))
      .toNumber()
  }

  public get cost_total(): number {
    if (this.type === 'client') {
      return new BigNumber(this.cost_rate)
        .times(new BigNumber(this.impressions).div(1000))
        .toNumber()
    }
    return (
      this.cost_rate
      * ((this.billing_source === 'first_party' ? this.impressions : this.reconciliated_impressions)
        / 1000)
    )
  }

  public get net(): any {
    return this.cost_ready ? this.revenue_net_total - this.cost_total : 'N/A'
  }

  public get margin(): any {
    return this.cost_ready ? this.net / this.revenue_total : 'N/A'
  }

  public get impressions_discrepancy() {
    if (this.billing_source === 'first_party') return '-'
    return (this.reconciliated_impressions - this.impressions) / this.impressions
  }

  public get revenue_discrepancy() {
    return (this.booked_revenue - this.revenue_total) / this.booked_revenue
  }

  public get billing_source_name(): string {
    return startCase(this.billing_source.replaceAll('_', ' '))
  }

  public get billing_mode_name(): string {
    return startCase(this.billing_mode.replaceAll('_', ' '))
  }

  public get status_name(): string {
    return startCase(this.status.replaceAll('_', ' '))
  }

  public get uuid(): string {
    return this.id
  }

  // Check if object has changed
  public get is_dirty(): boolean {
    return this._hash !== this.calculateChecksum()
  }

  private _hash: string | Int32Array = ''

  private calculateChecksum(): string | Int32Array {
    return Md5.hashStr(
      JSON.stringify({
        cost_rate: this.cost_rate,
        reconciliated_impressions: this.reconciliated_impressions,
        billing_calendar: this.billing_calendar,
        billing_mode: this.billing_mode,
        billing_source: this.billing_source,
        dynamic_cost_rate_id: this.dynamic_cost_rate_id ?? null,
        status: this.status,
      }),
    )
  }

  public updateChecksum() {
    this._hash = this.calculateChecksum()
  }

  public static toObject(source: any): Reconciliation {
    let instance = new Reconciliation()

    // Remove computed properties
    delete source.revenue_total
    delete source.cost_total
    delete source.revenue_net_total
    delete source.net
    delete source.margin

    Object.assign(instance, source)

    if (source.media_plan) {
      instance.media_plan = MediaPlan.toObject(source.media_plan)
    }

    if (source.media_plan_item) {
      instance.media_plan_item = MediaPlanItem.toObject(source.media_plan_item)
    }

    // Create item checksum
    instance.updateChecksum()

    return instance
  }

  public toObject(source: any): Reconciliation {
    return Reconciliation.toObject(source)
  }

  public static toObjectList(source: any): Reconciliation[] {
    return source.map((item: any) => Reconciliation.toObject(item))
  }

  public toObjectList(source: any): Reconciliation[] {
    return Reconciliation.toObjectList(source)
  }

  public static save(type: string, rows: Array<Reconciliation>) {
    const api = new Api()

    const dirty_items = rows.filter(i => i.is_dirty)

    if (dirty_items.length === 0) {
      return Promise.resolve(true).then(Reconciliation.onSave)
    }

    return api
      .post(
        `reconciliations/${type}`,
        rows
          .filter(i => i.is_dirty)
          .map(i => ({
            id: i.id,
            status: i.status,
            reconciliated_impressions: i.reconciliated_impressions,
            billing_source: i.billing_source,
            cost_rate: i.cost_rate,
            cost_total: i.cost_total,
            revenue_total: i.revenue_total,
            revenue_net_total: i.revenue_net_total,
            billing_mode: i.billing_mode,
            billing_calendar: i.billing_calendar,
            dynamic_cost_rate_id: i.dynamic_cost_rate_id ?? null,
          })),
      )
      .then((response: any) => {
        rows.forEach((item: Reconciliation) => {
          item.updateChecksum()
        })
        Reconciliation.onSave(response)
      })
      .catch(Reconciliation.onError)
  }

  /**
   * Fetch invoiceble reconciliation items.
   *
   * @param invoice
   * @returns Promise<Reconciliation[]>
   */
  public static getInvoicebleItems(invoice: Invoice): Promise<Reconciliation[]> {
    const api = new Api()

    return api
      .get('reconciliations/invoiceble', {
        invoice_id: invoice.id,
        client_id: invoice.client_id,
        broadcast_month:
          invoice.type !== 'default' ? moment(invoice.broadcast_month).format('YYYY-MM') : null,
      })
      .then((response: any) => Reconciliation.toObjectList(response.data.result.reconciliations))
      .catch(Reconciliation.onError)
  }

  /**
   * Fetch batch invoiceble reconciliation items.
   *
   * @returns Promise<Reconciliation[]>
   */
  public static getBatchInvoiceble(): Promise<ReconciliationClient[]> {
    const api = new Api()

    return api
      .get('reconciliations/invoiceble/batch')
      .then((response: any) =>
        ReconciliationClient.toObjectList(response.data.result.reconciliations))
      .catch(Reconciliation.onError)
  }

  public static async batchPaginate(options: PaginateOptions | any) {
    const api = new Api(false)
    return api
      .get('reconciliations/invoiceble/batch_paginate', options)
      .then(response => {
        // Parse & cache data
        const data = ReconciliationClient.toObjectList(response.data.result.reconciliations).map(
          c => {
            c.due_at = moment(moment().startOf('month'))
              .add(c.items[0].media_plan?.metadata.invoice.payment_terms.split('_')[1], 'days')
              .format('YYYY-MM-DD')
            return c
          },
        )

        return {
          records: response.data.result.records,
          data,
        }
      })
      .catch(e => ({
        records: 0,
        data: [],
      }))
  }

  public static async batchExpensePaginate(options: PaginateOptions) {
    const api = new Api(false)
    return api
      .get('reconciliations/invoiceble/batch_expense', options)
      .then(response => ({
        records: response.data.result.records,
        data: response.data.result.reconciliations,
      }))
      .catch(e => ({
        records: 0,
        data: [],
      }))
  }

  public save(type: string) {
    return Reconciliation.save(type, [this])
  }

  protected static onSave(response: any) {
    WebMessage.success('Reconciliation saved!')

    return response
  }

  private static onError(error: any) {
    return error
  }
}
