
import {
  Component, Prop, Ref, Watch,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import Company from '@/models/Company'
import SelectOption from '@/models/interface/SelectOption'
import CompanyName from '@/components/CompanyPicker/CompanyName.vue'

@Component({
  components: {
    SelectPicker,
    CompanyName,
  },
})
export default class CompanyPicker extends ViewModel {
  @Prop({ default: 'Type to search companies' })
  public placeholder!: any

  @Prop({ default: true })
  public edit!: boolean

  @Prop({ default: false })
  public lazy!: boolean

  @Prop({ default: false })
  public allow_empty!: boolean

  @Prop({ default: false })
  public disabled!: boolean

  @Prop({ default: false })
  public multiple!: boolean

  @Prop({ default: null })
  public owner_id!: string | null

  @Prop({ default: null })
  public value!: string | null | string[]

  @Prop({ default: false })
  public vertical!: boolean

  @Prop({ default: false })
  public hide_description!: boolean

  @Prop({
    default: 'Company',
  })
  public label!: string

  @Prop({ default: false })
  public showType!: boolean

  @Prop({ default: false })
  public create_panel!: boolean

  @Prop({ default: null })
  public type!: string | null

  @Prop({ default: null })
  public not_type!: string | null

  private local_value: string | null | string[] = null

  public uuid: string = ''

  public update: boolean = true

  public busy: boolean = false

  public options: SelectOption[] = []

  public ready: boolean = false

  public mounted() {
    this.uuid = this.randomUUID()
  }

  public created() {
    if (this.edit) {
      if (!this.value || (Array.isArray(this.value) && this.value.length == 0)) {
        this.local_value = this.value
        this.ready = true
      } else {
        this.busy = true
        Company.searchOptions({
          value: this.value,
        }).then(response => {
          this.options = response
          if (this.showType !== false) {
            this.options = this.options.map((o: any) => {
              o.name += ` (${o.type.charAt(0).toUpperCase() + o.type.replace('_', ' ').slice(1)})`
              return o
            })
          }
          this.busy = false
          this.local_value = this.value
          this.ready = true
        })
      }
    } else {
      Company.searchOptions({
        value: this.value,
      }).then(response => {
        this.options = response
        if (this.showType !== false) {
          this.options = this.options.map((o: any) => {
            o.name += ` (${o.type.charAt(0).toUpperCase() + o.type.replace('_', ' ').slice(1)})`
            return o
          })
        }
        this.busy = false
        this.local_value = this.value
        this.ready = true
      })
    }
  }

  public open() {
    this.$emit('open', this.uuid)
    if (this.options.length == 0) this.searchOptions()
  }

  public addCustomOption(name: string, value: any, set_as_value = false) {
    if (this.options.some((o: any) => o.value == value)) return
    this.options.push(new SelectOption(name, value))

    if (set_as_value) {
      this.ready = false
      setTimeout(() => {
        this.local_value = value
        this.ready = true
      }, 100)
    }
  }

  public searchOptions(search = '*') {
    this.busy = true
    if (!search) search = '*'

    Company.searchOptions({
      search: search.includes('*') ? search : `*${search}*`,
      type: this.type,
      not_type: this.not_type,
      owner_id: this.owner_id,
    }).then(response => {
      this.options = response
      if (this.showType !== false) {
        this.options = this.options.map((o: any) => {
          o.name += ` (${o.type.charAt(0).toUpperCase() + o.type.replace('_', ' ').slice(1)})`
          return o
        })
      }
      this.busy = false
      this.ready = true
    })
  }

  public get can_create() {
    return this.create_panel && this.user.can('company', 'edit')
  }

  public get panel_callback(): any {
    return this.layout.side_panel_callback.company
  }

  @Watch('panel_callback')
  public onCallback() {
    if (this.uuid == this.layout.side_panel_id && this.panel_callback) {
      this.local_value = this.panel_callback
      this.layout.clearSidePanelCallback()
    }
  }

  @Watch('local_value')
  public onChange() {
    this.$emit('input', this.local_value)
  }

  @Watch('value')
  public onChangeProp() {
    let has_options = false
    if (Array.isArray(this.value)) {
      has_options = true
      this.value.forEach((v: any) => {
        if (!this.options.some((o: any) => o.value === v)) {
          has_options = false
        }
      })
    } else if (this.options.some((o: any) => o.value === this.value)) {
      has_options = true
    }
    if (
      (Array.isArray(this.value) && this.value.length > 0 && !has_options)
      || (typeof this.value === 'string' && !has_options)
    ) {
      Company.searchOptions({
        value: Array.isArray(this.value) ? this.value : [this.value],
      }).then(response => {
        this.options = response
        if (this.showType !== false) {
          this.options = this.options.map((o: any) => {
            o.name += ` (${o.type.charAt(0).toUpperCase() + o.type.replace('_', ' ').slice(1)})`
            return o
          })
        }
        this.busy = false
        this.local_value = this.value

        this.ready = true
      })
    } else {
      this.local_value = this.value
    }
  }

  @Watch('owner_id')
  public onChangeOwner() {
    this.searchOptions()
  }

  public toggleSideBar() {
    this.layout.showSidePanel({
      name: 'company',
      id: this.uuid,
      data: {
        owner_id: this.owner_id,
        type: this.type,
      },
    })
  }
}
